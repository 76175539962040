import React from 'react'
import {
    ValuesWrapper,
    ValuesHeader,
    ValuesContent,
    ValuesColumnDiv1,
    ValuesColumnDiv2,
    ValuesColumnDiv3,
    ValuesDiv,
    ValuesDiv2,
    ValuesDiv4,
    ValuesTitle,
    ValuesDescription,
    PartnershipImg,
    PartnershipImg2,
    ValuesDiv3,
    StarEffect1,
    StarEffect2,
    StarEffect3,
    StarEffect4,
    CloudEffect1,
    CloudEffect2,
    WhiteGradient,
    RedGradient,
    BlueGradient
} from './styled'
import { commitmentImage } from '../../../constants/images'

const Values = () => {
    return (
        <ValuesWrapper>
            <WhiteGradient/>
            <RedGradient/>
            <BlueGradient/>

            <StarEffect1
                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star.png"
                alt="star-effect"
            />
            <StarEffect2
                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                alt="star-effect"
            />
            <StarEffect3
                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                alt="star-effect"
            />
            <StarEffect4
                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star2.png"
                alt="star-effect"
            />
            <CloudEffect1
                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud.png"
                alt="cloud"
            />
            <CloudEffect2
                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud.png"
                alt="cloud"
            />
            <ValuesHeader>Why Obinex?</ValuesHeader>
            <ValuesContent>
                <ValuesColumnDiv1>
                    <ValuesDiv>
                        <ValuesTitle>Introduction</ValuesTitle>
                        <ValuesDescription>
                            In a rapidly evolving world where digital currencies
                            are reshaping finance, cryptocurrencies emerge as
                            transformative forces, challenging the status quo
                            with their decentralized nature. This shift marks a
                            new era of economic autonomy, where cryptocurrencies
                            are the catalysts of a profound financial
                            revolution. OBINEX™ is at the forefront of this
                            transformation, fusing blockchain technology and
                            artificial intelligence to create a secure, private
                            and decentralized financial ecosystem.
                        </ValuesDescription>
                    </ValuesDiv>
                    <ValuesDiv>
                        <ValuesTitle>Mission & Vision</ValuesTitle>
                        <ValuesDescription>
                            At OBINEXTM, we aim to synergize the strengths of
                            Centralized Finance (CeFi) and Decentralized Finance
                            (DeFi) through an innovative Layer 4 blockchain and
                            advanced predictive AI. This fusion enables us to
                            offer insightful advice and secure transaction
                            options, empowering users in their investment
                            decisions. Our mission is to enhance financial
                            access globally, starting with the launch of our
                            innovative web application, the CEX, in early 2024,
                            and expanding into a comprehensive DeFi platform by
                            year-end.
                        </ValuesDescription>
                    </ValuesDiv>
                </ValuesColumnDiv1>
                <ValuesColumnDiv2>
                    <ValuesDiv2>
                        <ValuesTitle>
                            Founding Team and Partnerships
                        </ValuesTitle>
                        <ValuesDescription>
                            The OBINEX™ founding team is committed to creating
                            an accessible and inclusive ecosystem that delivers
                            disruptive benefits. Supported by an experienced
                            team of blockchain, cryptocurrency, and
                            decentralized finance specialists, and in
                            collaboration with industry leaders like WayOps and
                            Waynance, we are building a platform that
                            encapsulates the best of both centralized and
                            decentralized finance. With a secure and
                            personalized user experience, the OBINEX™ community
                            can make informed decisions and optimize their
                            financial returns in an equitable and secure
                            marketplace.
                        </ValuesDescription>
                    </ValuesDiv2>
                    <PartnershipImg
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/value/TransparentImg.png"
                        alt="rocketImg"
                    />
                </ValuesColumnDiv2>
                <ValuesColumnDiv3>
                    <ValuesDiv3 img={commitmentImage} />
                    <ValuesDiv4>
                        <ValuesTitle>Our Commitment</ValuesTitle>
                        <ValuesDescription>
                            At OBINEX™, our unwavering commitment extends beyond
                            financial innovation. We are deeply committed to
                            adhering to the highest standards of industry
                            regulations across different jurisdictions.
                            Understanding the paramount importance of
                            transparency in all our operations, we strive to
                            cultivate a culture of openness and clarity,
                            ensuring that our clients and stakeholders are
                            well-informed and confident in their decisions.
                            <br />
                            <br /> Moreover, the cornerstone of our ethos is the
                            protection of our users and consumers. Implementing
                            robust risk management strategies and maintaining
                            rigorous data protection protocols, we prioritize
                            the security and integrity of our user's investments
                            and personal information. Our dedication to these
                            principles is not just a standard practice but a
                            reflection of our core values, driving us towards
                            creating a financial ecosystem that is not only
                            innovative but also safe, reliable, and trustworthy.
                        </ValuesDescription>
                    </ValuesDiv4>
                    <PartnershipImg2
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/value/TransparentImg.png"
                        alt="rocketImg"
                    />
                </ValuesColumnDiv3>
            </ValuesContent>
        </ValuesWrapper>
    )
}

export default Values
