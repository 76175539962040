import React from 'react'
import { Container } from '../../components/common'

import {
    Wrapper,
    Header,
    Content,
    ContentSpan,
    LinkSpan,
    ContentUl,
    TitleSpan,
    LinearGradient1,
    LinearGradient2,
    LinearGradient3,
    LinearGradient4,
    LinearGradient5,
    LinearGradient6,
    PolicyWrapper,
} from './styled'
const Privacy = () => {
    return (
        <Container>
            <PolicyWrapper>
                <LinearGradient1 />
                <LinearGradient2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/teamImg/image%2020.png"
                    alt="linearImg"
                />
                <LinearGradient3 />
                <LinearGradient4 />
                <LinearGradient5 />
                <LinearGradient6 />
                <Wrapper>
                    <Header>Privacy Policy</Header>
                    <Content>
                        <ContentSpan>
                            <b>Effective Date:</b> January, 2024
                        </ContentSpan>
                        <ContentSpan>
                            <b>Last Updated:</b> January, 2024
                        </ContentSpan>
                        <TitleSpan>1.Our Pledge on Privacy</TitleSpan>
                        <ContentSpan>
                            At Obinex™ we take your privacy and our data
                            protection responsibilities with the utmost
                            seriousness. Privacy isn't just a feature of our
                            services; it's the foundation. We understand the
                            significance of your personal data and pledge to
                            handle it with the utmost care, ensuring a
                            responsible processing in line with the highest data
                            protection standards.
                        </ContentSpan>
                        <TitleSpan>2.About our privacy policy</TitleSpan>
                        <ContentSpan>
                            This Privacy Policy applies to all Personal
                            Information processing activities carried out by us
                            and describes how your personal data is collected
                            and handled across our network.
                        </ContentSpan>
                        <ContentSpan>
                            Whether you're a customer/user, a prospective
                            customer/user exploring our services, or a visitor
                            browsing our website, our privacy policy applies to
                            you. Additionally, our cookies policy and terms of
                            use also apply.
                        </ContentSpan>
                        <ContentSpan>
                            To the extent that you are a customer/user of our
                            services, any other contractual documents, including
                            any additional contracts or agreements we may have
                            with you, also apply.
                        </ContentSpan>
                        <ContentSpan>
                            If you are an employee of the Company, a contractor
                            to the Company or a third-party provider, your
                            personal information will be used in connection with
                            your employment contract or your contractual
                            relationship, whichever applies.
                        </ContentSpan>
                        <TitleSpan>3.Definitions</TitleSpan>
                        <ContentSpan>
                            As used herein, the following terms are defined as
                            follows: <br /> <br />
                            The team "We” and “Us” refers to Obinex™, which is
                            the commercial name of for EMPERSTUS GLOBAL PVT
                            LIMITED, a private limited company incorporated and
                            registered in the United Kingdom. The company
                            registration number is 15449808. The registered
                            office of the company is situated at 45 Kent House,
                            Maidstone, United Kingdom, ME15 6LA.
                        </ContentSpan>
                        <ContentSpan>
                            Our “Network” refers to platforms, websites, and
                            departments of Obinex™ and Obinex™ Operators.
                        </ContentSpan>
                        <ContentSpan>
                            “Digital Asset” is a digital representation of value
                            (also referred to as “cryptocurrency,” “virtual
                            currency,” “digital currency,” “crypto token,”
                            “crypto asset,” or “digital commodity”), such as
                            bitcoin, or ether, which is based on the
                            cryptographic protocol of a computer network that
                            may be (i) centralized or decentralized, (ii) closed
                            or open-source, and (iii) used as a medium of
                            exchange and/or store of value.
                        </ContentSpan>
                        <ContentSpan>
                            “Obinex™ Account” means a user-accessible account
                            offered via the Obinex™ Exchange Services where
                            Digital Assets are stored by Payward.
                        </ContentSpan>
                        <ContentSpan>
                            “Obinex™ Exchange Services” means Obinex-branded
                            websites, applications, services, or tools operated
                            by Payward group companies.
                        </ContentSpan>
                        <ContentSpan>
                            “Personal Information” or “Personal Data” or “your
                            data” refers to any information relating to you, as
                            an identified or identifiable natural person,
                            including your name, an identification number,
                            location data, or an online identifier or to one or
                            more factors specific to the physical, economic,
                            cultural or social identity of you as a natural
                            person.
                        </ContentSpan>
                        <ContentSpan>
                            “Trading and Direct Sales Services” means exchange
                            between virtual assets and fiat currencies; exchange
                            between one or more forms of virtual assets;
                            transfer of virtual assets, that is to say, conduct
                            a transaction on behalf of another person that moves
                            a virtual assets from one virtual asset address or
                            account to another; and act as a custodian wallet
                            provider.
                        </ContentSpan>
                        <TitleSpan>
                            4.Data Control and Processing Roles
                        </TitleSpan>
                        <ContentSpan>
                            As Your Data Controller: When interacting with our
                            Sites and utilizing our Services, Obinex™ assumes
                            the role of a Data Controller. This means we have
                            the primary responsibility for managing your
                            personal information. We are committed to providing
                            you with the necessary information and addressing
                            any data protection requests you may have in
                            accordance with applicable laws. Within our family
                            of companies, we may share your personal data to
                            streamline our services, ensuring all such sharing
                            is governed by this Privacy Policy.
                        </ContentSpan>
                        <ContentSpan>
                            As Your Data Processor: There are instances where
                            Obinex™ may act as a Data Processor, specifically
                            when we handle data on behalf of our clients who
                            remain the Data Controllers. In such cases,
                            inquiries or requests concerning the treatment of
                            your personal data should be directed to the
                            respective client. Obinex™ will process such data
                            according to the instructions given by the client in
                            compliance with data protection legislation.
                        </ContentSpan>
                        <ContentSpan>
                            Determining the Data Controller: The specific
                            Obinex™ entity that you enter into an agreement
                            with, which determines how your personal data will
                            be processed in relation to the services we provide,
                            is typically Emperstus Global Pvt Limited for users
                            within the EEA and the UK. This entity is registered
                            in the United Kingdom and operates in adherence to
                            local data protection laws.
                        </ContentSpan>
                        <TitleSpan>5.Why We Collect Your Data</TitleSpan>
                        <ContentSpan>
                            We collect data from our customers/users and
                            potential customers/users to provide you with our
                            products and services. This ensures we can meet your
                            specific needs when delivering these products and
                            services and provide you with relevant information.
                        </ContentSpan>
                        <TitleSpan>6.Data Collection Details</TitleSpan>
                        <ContentSpan>
                            In order to open an account with us, you must first
                            complete and submit a “create account” form to us by
                            completing the required information. By completing
                            this form, you are requested to disclose personal
                            information in order to enable the Company to assess
                            your application and comply with the relevant laws
                            (including their regulations).
                        </ContentSpan>
                        <ContentSpan>
                            <span>
                                Data we might collect from you is as follows:
                            </span>
                            <br />
                            <ContentUl>
                                <li>
                                    Full name, residential address and contact
                                    details (e.g. email address, telephone
                                    number, fax etc.);
                                </li>
                                <li>
                                    Date of birth, place of birth, gender,
                                    citizenship;
                                </li>
                                <li>
                                    Bank account information, credit card
                                    details, including details about your source
                                    of funds, assets and liabilities, and OFAC
                                    information;
                                </li>
                                <li>
                                    Trading account balances, trading activity,
                                    your inquiries and our responses;
                                </li>
                                <li>
                                    Information on whether you hold a prominent
                                    public function (PEP);
                                </li>
                                <li>
                                    Verification information, which includes
                                    information necessary to verify your
                                    identity such as a passport, driver’s
                                    licence or Government-issued identity card);
                                </li>
                                <li>
                                    Other Personal Information or commercial
                                    and/or identification information – Whatever
                                    information we, in our sole discretion, deem
                                    necessary to comply with our legal
                                    obligations under various anti-money
                                    laundering (AML) obligations, such as under
                                    the Money Laundering, Terrorist Financing
                                    and Transfer of Funds (Information on the
                                    Payer) UK Regulations, European Union’s AML
                                    Directives, and any other regulation as
                                    required in the jurisdictions we may
                                    operate.
                                </li>
                            </ContentUl>
                        </ContentSpan>
                        <ContentSpan>
                            We may also collect about you automatically:
                            <br />
                            <ContentUl>
                                <li>
                                    Browser Information: Information that is
                                    automatically collected via analytics
                                    systems providers from your browser,
                                    including your IP address and/or domain name
                                    and any external page that referred you to
                                    us, your login information, browser type and
                                    version, time zone setting, browser plug-in
                                    types and versions, operating system, and
                                    platform;
                                </li>
                            </ContentUl>
                        </ContentSpan>
                        <ContentSpan>
                            Log-in: Information that is generated by your use of
                            the Services that is automatically collected and
                            stored in our server logs. This may include, but is
                            not limited to, device-specific information,
                            location information, system activity and any
                            internal and external information related to pages
                            that you visit, including the full Uniform Resource
                            Locators (URL) clickstream to, through and from our
                            Website or App (including date and time; page
                            response times, download errors, length of visits to
                            certain pages, page interaction information (such as
                            scrolling, clicks, and mouse-overs), and methods
                            used to browse away from the page;  Data we may
                            collect from third parties  We obtain information
                            about you in a number of ways through your use of
                            our Services, including through any of our websites,
                            the account opening process, webinar sign-up forms,
                            event subscribing, news and updates subscribing, and
                            from information provided in the course of on-going
                            support service communications. We also receive
                            information about you from third parties such as
                            your payment providers and through publicly
                            available sources.
                        </ContentSpan>
                        <ContentSpan>
                            <span> For example: </span>
                            <br />
                            <ContentUl>
                                <li>
                                    The banks you use to transfer money to us
                                    will provide us with your basic personal
                                    information, such as your name and address,
                                    as well as your financial information such
                                    as your bank account details;
                                </li>
                                <li>
                                    Your business partners may provide us with
                                    your name and address, as well as financial
                                    information;
                                </li>
                                <li>
                                    Advertising networks, analytics providers
                                    and search information providers may provide
                                    us with anonymized or de-identified
                                    information about you, such as confirming
                                    how you found our website;
                                </li>
                                <li>
                                    Credit reference agencies do not provide us
                                    with any personal information about you, but
                                    may be used to corroborate the information
                                    you have provided to us.
                                </li>
                            </ContentUl>
                        </ContentSpan>
                        <TitleSpan>
                            7.Legal Basis for Processing Your Personal Data 
                        </TitleSpan>
                        <ContentSpan>
                            We process your personal data as a necessary step to
                            fulfill our contractual obligations to you, such as
                            managing your account and ensuring the provision of
                            the products and services you have engaged us for.
                            Verifying your identity is a crucial part of our
                            client onboarding process, which enables us to offer
                            you personalized and secure service.
                        </ContentSpan>
                        <ContentSpan>
                            We adhere to various legal requirements that mandate
                            the processing of your personal data. This includes
                            laws and regulations related to anti-money
                            laundering, financial services, corporate
                            governance, privacy, and taxation. To comply with
                            these legal mandates, we perform necessary
                            activities such as identity checks and payment
                            processing.
                        </ContentSpan>
                        <ContentSpan>
                            We may process your personal data when it is
                            necessary for the legitimate interests of our
                            business or a third party, provided that your rights
                            and interests do not override those interests. This
                            may include initiating or defending legal claims,
                            ensuring IT and system security, developing new
                            products and services, and managing business risks. 
                        </ContentSpan>
                        <ContentSpan>
                            <span>
                                In specific situations, we process your personal
                                data based on the consent you provide. This
                                includes, but is not limited to: 
                            </span>
                            <ContentUl>
                                <li>
                                    Conducting client surveys and gathering
                                    feedback, where your participation is
                                    entirely voluntary.
                                </li>
                                <li>
                                    Sending marketing communications to keep you
                                    informed about our latest offerings. You
                                    have the right to withdraw your consent to
                                    these communications at any time by
                                    contacting us.
                                </li>
                                <li>
                                    Collecting and analyzing data to enhance our
                                    website and services, which may involve
                                    using aggregated and anonymized data to
                                    detect usage trends.
                                </li>
                            </ContentUl>
                        </ContentSpan>
                        <ContentSpan>
                            For activities that extend to social media platforms
                            or utilize new technologies, such as chatbots, we
                            will process your personal data based on your
                            explicit consent. You have control over your data
                            and the choice to engage with these platforms and
                            technologies.
                        </ContentSpan>
                        <ContentSpan>
                            You have the right to withdraw your consent at any
                            time. The withdrawal of consent will not affect the
                            lawfulness of processing based on consent before its
                            withdrawal. To withdraw your consent, please contact
                            us using the information provided in this policy. 
                        </ContentSpan>
                        <ContentSpan>
                            For effective business management and administrative
                            purposes, we process your data, which includes
                            maintaining records to ensure contract compliance
                            and responding to legal notifications.
                        </ContentSpan>
                        <TitleSpan>
                            8.Disclosure of your personal information
                        </TitleSpan>
                        <ContentSpan>
                            We are not going to disclose any confidential
                            information to a third party, except: (a) to the
                            extent that it is required to do so pursuant to any
                            applicable laws, rules or regulations; (b) if there
                            is a duty to disclose; (c) if our legitimate
                            business interests require disclosure; (d) in line
                            with our Terms of Service and/or Services Usage
                            Guidelines; (e) at your request or with your consent
                            or to those described in this Privacy Policy. The
                            Company will endeavour to make such disclosures on a
                            “need-to-know” basis, unless otherwise instructed by
                            a regulatory authority. Under such circumstances,
                            the Company will notify the third party regarding
                            the confidential nature of any such information.  As
                            part of using your personal information for the
                            purposes set out above, the Company may disclose
                            your personal information to the following: 
                        </ContentSpan>
                        <ContentUl>
                            <li>
                                Any members of the Company, which means that any
                                of our affiliates and subsidiaries may receive
                                such information;
                            </li>
                            <li>
                                Any of our service providers and business
                                partners, for business purposes, such as
                                specialist advisors who have been contracted to
                                provide us with administrative, financial,
                                legal, tax, compliance, insurance, IT,
                                debt-recovery, analytics, research or other
                                services.
                            </li>
                        </ContentUl>
                        <ContentSpan>
                            If the Company discloses your personal information
                            to service providers and business partners, in order
                            to perform the services requested by clients, such
                            providers and partners may store your personal
                            information within their own systems in order to
                            comply with their legal and other obligations.  We
                            require that service providers and business partners
                            who process personal information to acknowledge the
                            confidentiality of this information, undertake to
                            respect any client’s right to privacy and comply
                            with all relevant privacy and data protection laws
                            and this Privacy Policy.
                        </ContentSpan>
                        <TitleSpan>
                            9.Privacy when using digital assets and blockchains 
                        </TitleSpan>
                        <ContentSpan>
                            Your funding of bitcoin, ether, and other Digital
                            Assets, may be recorded on a public blockchain.
                            Public blockchains are distributed ledgers, intended
                            to immutably record transactions across wide
                            networks of computer systems. Many blockchains are
                            open to forensic analysis which can lead to
                            deanonymization and the unintentional revelation of
                            private financial information, especially when
                            blockchain data is combined with other data. 
                            Because blockchains are decentralized or third-party
                            networks which are not controlled or operated by
                            Payward or its affiliates, we are not able to erase,
                            modify, or alter personal data from such networks.
                        </ContentSpan>
                        <TitleSpan>10. Data Retention</TitleSpan>
                        <ContentSpan>
                            We will not retain your data unless necessary for
                            technical or legal reasons. We are commited with
                            securing data protection by design. Safeguarding the
                            privacy of your personal information is of utmost
                            importance to us, whether you interact with us
                            personally, by phone, by email, over the internet or
                            any other electronic medium. We will hold personal
                            information, for as long as we have a business
                            relationship with you, in secure computer storage
                            facilities, and we take the necessary measures to
                            protect the personal information we hold from
                            misuse, loss, unauthorised access, modification or
                            disclosure.  When we consider that personal
                            information is no longer necessary for the purpose
                            for which it was collected, we will remove any
                            details that will identify you or we will securely
                            destroy the records. However, we may need to
                            maintain records for a significant period of time
                            (after you cease being our client). For example, we
                            are subject to certain anti-money laundering laws
                            which require us to retain the following, for a
                            period of 5 years after our business relationship
                            with you has ended.
                            <ContentUl>
                                <li>
                                    A copy of the records we used in order to
                                    comply with our client due diligence
                                    obligations.
                                </li>
                                <li>
                                    Supporting evidence and records of
                                    transactions with you and your relationship
                                    with us.
                                </li>
                            </ContentUl>
                            Also, the personal information we hold in the form
                            of a recorded information, by telephone,
                            electronically or otherwise, will be held in line
                            with local regulatory requirements (i.e. 5 years
                            after our business relationship with you has ended
                            or longer if you have legitimate interests (such as
                            handling a dispute with you)). If you have opted out
                            of receiving marketing communications we will hold
                            your details on our suppression list so that we know
                            you do not want to receive these communications.  We
                            may keep your data for longer than 5 years if we
                            cannot delete it for legal, regulatory, or technical
                            reasons.
                        </ContentSpan>
                        <TitleSpan>
                            11. Your rights regarding your personal information 
                        </TitleSpan>
                        <ContentSpan>
                            The rights that are available to you in relation to
                            the personal information we hold about you are
                            outlined below.
                        </ContentSpan>
                        <ContentSpan>
                            <b>Information Access</b>
                            <br />
                            If you ask us, we will confirm whether we are
                            processing your personal information and, if so,
                            what information we process and, if requested,
                            provide you with a copy of that information within
                            30 days from the date of your request.  You may
                            access, update, or remove certain information that
                            you have provided to us through your account by
                            visiting your account settings or sending an email
                            to the email address set out in the “Contact
                            Information” section 19 below. We may require
                            additional information from you to allow us to
                            confirm your identity. Please note that we will
                            retain and use information about you as necessary to
                            comply with our legal obligations, resolve disputes,
                            and enforce our agreements. 
                        </ContentSpan>
                        <ContentSpan>
                            <b>Rectification</b>
                            <br />
                            It is important to us that your personal information
                            is up to date. We will take all reasonable steps to
                            make sure that your personal information remains
                            accurate, complete and up-to-date. If the personal
                            information we hold about you is inaccurate or
                            incomplete, you are entitled to have it rectified.
                            If we have disclosed your personal information to
                            others, we will let them know about the
                            rectification where possible. If you ask us, if
                            possible and lawful to do so, we will also inform
                            you with whom we have shared your personal
                            information so that you can contact them directly. 
                            You may inform us at any time that your personal
                            details have changed by emailing us at &nbsp;
                            <LinkSpan
                                href="http://www.support@obinex.com"
                                target="_blank">
                                support@obinex.com.
                            </LinkSpan>
                            &nbsp; The Company will change your personal
                            information in accordance with your instructions. To
                            proceed with such requests, in some cases we may
                            need supporting documents from you as proof i.e.
                            personal information that we are required to keep
                            for regulatory or other legal purposes.
                        </ContentSpan>
                        <ContentSpan>
                            <b>Erasure</b>
                            <br />
                            You can ask us to delete or remove your personal
                            information in certain circumstances such as if we
                            no longer need it, provided that we have no legal
                            obligation to retain that data. Such requests will
                            be subject to the contract that you have with us,
                            and to any retention limits we are required to
                            comply with in accordance with applicable laws and
                            regulations. If we have disclosed your personal
                            information to others, we will let them know about
                            the erasure request where possible. If you ask us,
                            if possible and lawful to do so, we will also inform
                            you with whom we have shared your personal
                            information so that you can contact them direct.
                        </ContentSpan>
                        <ContentSpan>
                            <b>Processing restrictions</b>
                            <br />
                            You can ask us to block or suppress the processing
                            of your personal information in certain
                            circumstances such as if you contest the accuracy of
                            that personal information or object to us processing
                            it. It will not stop us from storing your personal
                            information. We will inform you before we decide not
                            to agree with any requested restriction. If we have
                            disclosed your personal information to others, we
                            will let them know about the restriction of
                            processing if possible. If you ask us, if possible
                            and lawful to do so, we will also inform with whom
                            we have shared your personal information so that you
                            can contact them direct.
                        </ContentSpan>
                        <ContentSpan>
                            <b>Data portability </b>
                            <br />
                            In certain circumstances you might have the right,
                            to obtain personal information you have provided us
                            with (in a structured, commonly used and machine
                            readable format) and to re-use it elsewhere or ask
                            us to transfer this to a third party of your choice.
                        </ContentSpan>
                        <ContentSpan>
                            <b>Objection</b>
                            <br />
                            You can ask us to stop processing your personal
                            information, and we will do so, if we are:
                            <ContentUl>
                                <li>
                                    Relying on our own or someone else’s
                                    legitimate interests to process your
                                    personal information except if we can
                                    demonstrate compelling legal grounds for the
                                    processing; 
                                </li>
                                <li>
                                    Processing your personal information for
                                    direct marketing; or
                                </li>
                                <li>
                                    Processing your personal information for
                                    research unless we reasonably believe such
                                    processing is necessary or prudent for the
                                    performance of a task carried out in the
                                    public interest (such as by a regulatory or
                                    enforcement agency).
                                </li>
                            </ContentUl>
                        </ContentSpan>
                        <ContentSpan>
                            <b>Automated decision-making and profiling</b>
                            <br />
                            If we have made a decision about you based solely on
                            an automated process (e.g. through automatic
                            profiling) that affects your ability to access our
                            products and services or has another significant
                            effect on you, you can request not to be subject to
                            such a decision unless we can demonstrate to you
                            that such decision is necessary for entering into,
                            or the performance of, a contract between you and
                            us. Even if a decision is necessary for entering
                            into or performing a contract, you may contest the
                            decision and require human intervention. We may not
                            be able to offer our products or services to you, if
                            we agree to such a request (i.e. end our
                            relationship with you). 
                        </ContentSpan>
                        <ContentSpan>
                            <b>Tracking Technology Choices</b>
                            <br />
                            <ContentUl>
                                <li>
                                    Cookies and Pixels. Most browsers accept
                                    cookies by default. You can instruct your
                                    browser, by changing its settings, to
                                    decline or delete cookies. If you use
                                    multiple browsers on your device, you will
                                    need to instruct each browser separately.
                                    Your ability to limit cookies is subject to
                                    your browser settings and limitations.
                                </li>
                                <li>
                                    Do Not Track. Your browser settings may
                                    allow you to automatically transmit a “Do
                                    Not Track” signal to online services you
                                    visit. We do not monitor or take action with
                                    respect to “Do Not Track” signals. For more
                                    information on “Do Not Track,” visit{' '}
                                    <LinkSpan
                                        href="http://www.allaboutdnt.com"
                                        target="_blank">
                                        http://www.allaboutdnt.com.
                                    </LinkSpan>
                                </li>
                                <li>
                                    App and Location Technologies. You can stop
                                    all collection of information via an app by
                                    uninstalling the app. You can stop all
                                    collection of precise location data through
                                    an app by uninstalling the app or
                                    withdrawing your consent through your device
                                    settings.
                                </li>
                            </ContentUl>
                            Please be aware that if you disable or remove
                            tracking technologies some parts of the Sites may
                            not function correctly. 
                        </ContentSpan>
                        <TitleSpan>12. Exercise of your Rights</TitleSpan>
                        <ContentSpan>
                            You may exercise your rights by submitting a written
                            request to us at the address set out in the “Contact
                            Information” section 19 below. We will respond to
                            your request within 30 days. We may request specific
                            information from you to help us confirm your
                            identity and process your request. Applicable law
                            may require or permit us to decline your request. If
                            we decline your request, we will tell you why,
                            subject to legal restrictions.   Please note that we
                            retain information as necessary to fulfil the
                            purposes for which it was collected, and may
                            continue to retain and use information even after a
                            data subject request for purposes of our legitimate
                            interests, including as necessary to comply with our
                            legal obligations, resolve disputes, prevent fraud,
                            and enforce our agreements.  If your personal data
                            has been processed by us on behalf of a client and
                            you wish to exercise any rights you have with such
                            personal data, please inquire with our client
                            directly. If you wish to make your request directly
                            to us, please provide the name of our client on
                            whose behalf we processed your personal data. We
                            will refer your request to that client, and will
                            support them to the extent required by applicable
                            law in responding to your request 
                        </ContentSpan>
                        <TitleSpan>
                            13.Analytics and Interest-Based Advertising
                        </TitleSpan>
                        <ContentSpan>
                            Google provides tools to allow you to opt out of the
                            use of certain information collected by Google
                            Analytics at{' '}
                            <LinkSpan
                                href="https://tools.google.com/dlpage/gaoptout"
                                target="_blank">
                                https://tools.google.com/dlpage/gaoptout
                            </LinkSpan>{' '}
                            and by Google Analytics for Display Advertising or
                            the Google Display Network at{' '}
                            <LinkSpan
                                href="https://www.google.com/settings/ads/onweb/"
                                target="_blank">
                                https://www.google.com/settings/ads/onweb/.
                            </LinkSpan>
                        </ContentSpan>
                        <TitleSpan>14. Cookies</TitleSpan>
                        <ContentSpan>
                            When you use our products and services, we may make
                            use of the standard practice of placing tiny data
                            files called cookies, flash cookies, pixel tags, or
                            other tracking tools (herein, “Cookies”) on your
                            computer or other devices used when engaging with
                            us. We use Cookies to (i) help us recognize you as a
                            customer, collect information about your use of our
                            products and services, to (ii) better customize our
                            services and content for you, and (iii) to collect
                            information about your computer or other access
                            devices to ensure our compliance with our AML
                            obligations. To learn more about how we use Cookies,
                            please visit our{' '}
                            <LinkSpan
                                href="https://obinex.com/cookies"
                                target="_blank">
                                Cookies Policy.
                            </LinkSpan>
                        </ContentSpan>
                        <TitleSpan>15. Communications for opt-out</TitleSpan>
                        <ContentSpan>
                            You can opt-out of receiving promotional emails from
                            us at any time by following the instructions as
                            provided in emails to click on the unsubscribe link,
                            or emailing us at the email address set out in the
                            “Contact Information” section 19 below with the word
                            UNSUBSCRIBE in the subject field of the email.
                            Please note that you cannot opt-out of
                            non-promotional emails, such as those about your
                            account, transactions, servicing, or our ongoing
                            business relations.
                        </ContentSpan>
                        <ContentSpan>
                            Push Notifications. If you have opted-in to receive
                            push notification on your device, you can opt-out at
                            any time by adjusting the permissions in your device
                            or uninstalling our app.
                        </ContentSpan>
                        <ContentSpan>
                            You can opt-out of receiving text messages or calls
                            to your phone number at any time by (i) for text
                            messages, texting “STOP” in response to any text
                            message you receive from us or contacting us as set
                            out in the “Contact Information” section 19 below
                            and specifying you want to opt-out of text messages;
                            and (ii) for calls, requesting opt-out during any
                            call you receive from us or contacting us as set out
                            in the “Contact Information” section 19 below and
                            specifying you want to opt-out of calls.  Please
                            note that your opt out is limited to the email
                            address, device, and phone number used and will not
                            affect subsequent subscriptions.
                        </ContentSpan>
                        <TitleSpan>
                            16.Transfers of Personal Information outside of your
                            country
                        </TitleSpan>
                        <ContentSpan>
                            By using our products and services, you consent to
                            your Personal Data being transferred to other
                            countries.
                        </ContentSpan>
                        <TitleSpan>17.Changes to this Privacy Policy</TitleSpan>
                        <ContentSpan>
                            Our Privacy Policy is reviewed regularly to ensure
                            that any new obligations and technologies, as well
                            as any changes to our business operations and
                            practices are taken into consideration. If we decide
                            to change our Privacy Policy, we will post those
                            changes to this Privacy Policy and other places we
                            deem appropriate. We also invite you to check out
                            our website regularly. 
                        </ContentSpan>
                        <TitleSpan>
                            18. Products and services are not available to
                            children 
                        </TitleSpan>
                        <ContentSpan>
                            If we learn that we have inadvertently gathered
                            personal information from a Child, we will take
                            legally permissible measures to remove that
                            information from our records. The Company will
                            require the user to close his or her account and
                            will not allow the use of our products and services.
                            If you are a parent or guardian of a Child, and you
                            become aware that a Child has provided personal
                            information to us, please contact.
                        </ContentSpan>
                        <TitleSpan>19. Contact Information</TitleSpan>
                        <ContentSpan>
                            Any questions, complaints, comments and requests
                            regarding this Privacy Policy are welcome and should
                            be addressed to our Support Team (
                            <LinkSpan
                                href="https://support@obinex.com"
                                target="_blank">
                                support@obinex.com
                            </LinkSpan>
                            ) and/or our Data Protection Officer (
                            <LinkSpan
                                href="https://dpo@obinex.com"
                                target="_blank">
                                dpo@obinex.com
                            </LinkSpan>
                            ), or the relevant data protection regulator in your
                            jurisdiction. We would, however, appreciate the
                            opportunity to address your concerns before you
                            approach a data protection regulator, and would
                            welcome you directing an inquiry first to us.
                        </ContentSpan>
                        <ContentSpan>
                            Data Protection Authorities
                            <br />
                            If you are not satisfied with our response to your
                            complaint, you have the right to submit a complaint
                            with our regulator. You can contact the appropriate
                            regulator direct from the details below:
                        </ContentSpan>
                        <ContentSpan>
                            For residents of the United Kingdom:
                            <br />
                            The Information Commissioner’s Office Wycliffe
                            House, Water Ln Wilmslow SK9 5AF, London, England,
                            UK 
                        </ContentSpan>
                        <ContentSpan>
                            [Continue completing for countries on which Obinex
                            plans to operate]
                            <br />
                            ***
                        </ContentSpan>
                        <ContentSpan>
                            This Privacy Policy is designed to comply with the
                            General Data Protection Regulation (GDPR)
                            [Regulation (EU) 2016/679] and the UK Data
                            Protection Act 2018.
                        </ContentSpan>
                        <br />
                        <TitleSpan>
                            EMPERSTUS GLOBAL PVT. LIMITED (register number
                            15449808),
                            <br /> 45 Kent House, Maidstone, United Kingdom,
                            ME15 6LA.
                        </TitleSpan>
                    </Content>
                </Wrapper>
            </PolicyWrapper>
        </Container>
    )
}

export default Privacy
