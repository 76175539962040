import styled from 'styled-components'

export const TeamWrapper = styled.div`
    position: relative;
    padding-bottom: 75px;
`

export const TeamHeader = styled.h2`
    color: #415162;
    text-align: center;
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 116px;
    margin-bottom: 57px;
    @media (max-width: 430px) {
        font-size: 20px;
    }
`

export const TeamImageWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        gap: 10;
        flex-direction: column;
    }
`
export const ExecutiveTeam = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        gap: 10;
        flex-direction: column;
    }
`
export const RestTeam = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        gap: 10;
        flex-direction: column;
    }
`

export const LinearGradient1 = styled.div`
    position: absolute;
    top: 50px;
    left: -220px;
    border-radius: 318px;
    opacity: 0.59;
    background: linear-gradient(180deg, #fff500 0%, #fff73d 84%);
    width: 240px;
    height: 240px;
    filter: blur(150px);
    z-index: -1;
    @media (max-width: 430px) {
        display: none;
    }
`
export const LinearGradient2 = styled.div`
    position: absolute;
    top: -15px;
    right: 0px;
    border-radius: 318px;
    opacity: 0.5;
    background: linear-gradient(180deg, #0038ff 100%, #3cfdf3 84.16%);
    width: 219px;
    height: 219px;
    filter: blur(150px);
    z-index: -1;
    @media (max-width: 430px) {
        display: none;
    }
`
export const LinearGradient3 = styled.div`
    position: absolute;
    top: 500px;
    right: -170px;
    border-radius: 218px;
    opacity: 0.4;
    background: linear-gradient(180deg, #ff00c7 100%, #ff3da6 84%);
    width: 219px;
    height: 219px;
    filter: blur(140px);
    z-index: -1;
    @media (max-width: 430px) {
        display: none;
    }
`
export const LinearGradient4 = styled.div`
    position: absolute;
    top: 1120px;
    left: -145px;
    border-radius: 300px;
    opacity: 0.4;
    background: linear-gradient(180deg, #00ffc2 50%, #413dff 84%);
    width: 300px;
    height: 300px;
    filter: blur(150px);
    z-index: -1;
    @media (max-width: 430px) {
        display: none;
    }
`
export const LinearGradient5 = styled.div`
    position: absolute;
    top: 1536px;
    right: -280px;
    border-radius: 50%;
    opacity: 0.3;
    background: linear-gradient(180deg, #00ffc2 40%, #413dff 84%);
    width: 580px;
    height: 580px;
    filter: blur(180px);
    z-index: -1;
    @media (max-width: 430px) {
        display: none;
    }
`
export const LinearGradient6 = styled.div`
    position: absolute;
    top: 1893px;
    left: 120px;
    border-radius: 50%;
    opacity: 0.4;
    background: linear-gradient(180deg, #faff00 100%, #c1ff3d 84%);
    width: 220px;
    height: 220px;
    filter: blur(120px);
    z-index: -1;
    @media (max-width: 430px) {
        display: none;
    }
`
export const CloudEffect1 = styled.img`
    position: absolute;
    top: 29px;
    left: -80px;
    width: 317px;
    height: 281px;
    z-index: -1;
    @media (max-width: 430px) {
        width: 256px;
        top: 110px;
        left: -80px;
    }
`
export const CloudEffect2 = styled.img`
    position: absolute;
    top: 290px;
    right: -80px;
    width: 335px;
    height: 296px;
    transform: scaleX(-1);
    z-index: -1;
    @media (max-width: 430px) {
        width: 270px;
        height: 238px;
        top: 670px;
        right: -60px;
    }
`
export const CloudEffect3 = styled.img`
    position: absolute;
    top: 735px;
    left: -80px;
    width: 366px;
    height: 324px;
    z-index: -1;
    @media (max-width: 430px) {
        display: none;
    }
`
export const CloudEffect4 = styled.img`
    position: absolute;
    top: 1105px;
    right: -80px;
    width: 383px;
    height: 340px;
    z-index: -1;
    @media (max-width: 430px) {
        display: none;
    }
`
export const CloudEffect5 = styled.img`
    position: absolute;
    top: 1290px;
    left: -155px;
    width: 512px;
    height: 454px;
    z-index: -1;
    @media (max-width: 430px) {
        width: 365px;
        height: 324px;
        top: 1140px;
        left: -75px;
    }
`
export const StarEffectImg1 = styled.img`
    position: absolute;
    top: -10px;
    left: 157px;
    z-index: -1;
    @media (max-width: 1420px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const StarEffectImg2 = styled.img`
    position: absolute;
    width: 65px;
    height: 53px;
    top: -110px;
    left: 800px;
    z-index: -1;
    @media (max-width: 1420px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`

export const StarEffectImg3 = styled.img`
    position: absolute;
    width: 36px;
    height: 36px;
    top: 700px;
    left: 1280px;
    z-index: -1;
    @media (max-width: 1420px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const StarEffectImg4 = styled.img`
    position: absolute;
    top: 1000px;
    left: 10px;
    z-index: -1;
    @media (max-width: 1420px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const StarEffectImg5 = styled.img`
    position: absolute;
    top: 1200px;
    right: 84px;
    z-index: -1;
    @media (max-width: 1420px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const StarEffectImg6 = styled.img`
    position: absolute;
    top: 1750px;
    left: 167px;
    z-index: -1;
    @media (max-width: 430px) {
        display: none;
    }
`
export const StarEffectImg7 = styled.img`
    position: absolute;
    top: 1800px;
    right: 230px;
    z-index: 0;
    
    @media (max-width: 430px) {
        display: none;
    }
`
