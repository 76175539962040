import React from 'react'
import { linkedin } from '../../constants/images'

import {
    PeopleContent,
    PeopleTitleWrapper,
    PeopleImage,
    PeopleName,
    PeoplePosition,
    LinkedinIconWrapper
} from './styled'

const PeopleCard = ({ image }) => {
    return (
        <div>
            <PeopleImage alt={image.name} src={image.path} />
            <PeopleContent>
                <PeopleTitleWrapper>
                    <PeoplePosition>{image.position}</PeoplePosition>
                    <PeopleName>{image.name}</PeopleName>
                </PeopleTitleWrapper>
                <LinkedinIconWrapper>
                    <a href={image.link} target="_blank" rel="noreferrer">
                        <img src={linkedin} alt="linkedin" />
                    </a>
                </LinkedinIconWrapper>
            </PeopleContent>
        </div>
    )
}

export default PeopleCard
