import React from 'react'
import { CheckboxWrapper } from '../common'

const Checkbox = ({ label }) => {
    return (
        <CheckboxWrapper>
            {label}
            <input type="checkbox" />
            <span></span>
        </CheckboxWrapper>
    )
}

export default Checkbox
