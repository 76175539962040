import React, { useEffect, useState } from 'react'

import {
    ArrowImage,
    ContentCardDiv,
    ContentCardRow,
    ContentCardTitle,
    ImgDiv,
} from './styled'

const ContentCard = ({ content, screenWidth }) => {
    const [resolutions, setResolutions] = useState('1420~9999')
    const [order, setOrder] = useState(0)

    useEffect(() => {
        const updateContent = () => {
            let newOrder = 0
            let newResolution = ''

            if (content.order) {
                const orderKeys = Object.keys(content.order)
                const matchingOrderKey = orderKeys.find((key) => {
                    const [min, max] = key.split('~').map(Number)
                    return screenWidth > min && screenWidth <= max
                })
                newOrder = matchingOrderKey
                    ? content.order[matchingOrderKey]
                    : 0
            }

            for (const resolution in content.arrows) {
                const [min, max] = resolution.split('~').map(Number)
                if (screenWidth > min && screenWidth <= max) {
                    newResolution = resolution
                    break
                }
            }

            setOrder(newOrder)
            setResolutions(newResolution)
        }

        updateContent()
    }, [content.arrows, content.order, screenWidth])

    return (
        <ContentCardDiv style={{ zIndex: content.zIndex, order: order }}>
            {content.arrows[resolutions] &&
                content.arrows[resolutions].map((direction) => (
                    <React.Fragment key={direction}>
                        {direction === 'right' && (
                            <ArrowImage
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/rightArrow.png"
                                alt="left"
                                className="arrow-right"
                            />
                        )}
                        {direction === 'left' && (
                            <ArrowImage
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/leftArrow.png"
                                alt="left"
                                className="arrow-left"
                            />
                        )}
                        {direction === 'down' && (
                            <ArrowImage
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/downArrow.png"
                                alt="down"
                                className="arrow-down"
                            />
                        )}
                        {direction === 'up' && (
                            <ArrowImage
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/downArrow.png"
                                alt="up"
                                className="arrow-up"
                            />
                        )}
                    </React.Fragment>
                ))}

            <ContentCardTitle>{content.date}</ContentCardTitle>
            {content.rows.map((rowData) => (
                <ContentCardRow key={rowData.content + rowData.alt}>
                    <ImgDiv src={rowData.icon} alt={rowData.alt} />
                    <span>{rowData.content}</span>
                </ContentCardRow>
            ))}
        </ContentCardDiv>
    )
}

export default ContentCard
