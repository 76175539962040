import styled from 'styled-components'

export const TokenomicHeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-bottom: 51px;
    @media (max-width: 580px) {
        justify-content: center;
        padding-bottom: 21px;
    }
    @media (max-width: 430px) {
        justify-content: center;
        padding-bottom: 21px;
    }
    @media (max-width: 340px) {
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 21px;
    }
`
export const Title1 = styled.div`
    color: var(--titlesdd, #415162);
    text-align: center;
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 68.8px */
    @media (max-width: 580px) {
        font-size: 29px;
    }
    @media (max-width: 430px) {
        font-size: 29px;
    }
    @media (max-width: 400px) {
        font-size: 25px;
    }
`
export const Title2 = styled.div`
    color: #ff027a;
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    @media (max-width: 580px) {
        font-size: 29px;
    }
    @media (max-width: 430px) {
        font-size: 29px;
    }
    @media (max-width: 420px) {
        font-size: 25px;
    }
`
export const ContentWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 50px;
    padding-bottom: 51px;
    @media (max-width: 1024px) {
        flex-direction: column;
        gap: unset;
    }
    @media (max-width: 430px) {
        flex-direction: column;
        gap: unset;
    }
`
export const ContentDiv1 = styled.div`
    width: 55%;
    color: var(--TITLE, #2f2f30);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 35.2px */
    @media (max-width: 1024px) {
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 21px;
    }
    @media (max-width: 430px) {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 21px;
    }
`
export const ContentUl = styled.ul`
    margin-top: unset;
    margin-bottom: unset;
    @media (max-width: 1240px) {
        padding-left: 20px;
    }
    @media (max-width: 430px) {
        padding-left: 20px;
    }
`

export const ContentDiv2 = styled.div`
    width: 50%;
    color: #2f2f30;

    /* text small */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-right: -10px;
    @media (max-width: 1024px) {
        margin-right: unset;
        width: 100%;
    }
    @media (max-width: 430px) {
        width: 100%;
    }
`
export const Div1 = styled.div`
    padding-bottom: 42px;
`
export const Div2 = styled.div`
    padding-bottom: 42px;
`
export const Div3 = styled.div`
    @media (max-width: 430px) {
        padding-right: 13px;
    }
`
export const TokenomicWrapper = styled.div`
    display: flex;
    width: 100%;
`
export const TokenomiContentWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 53px 21px 0px 0px;
    @media (max-width: 580px) {
        width: 100%;
        padding: unset;
    }
    @media (max-width: 430px) {
        padding: unset;
    }
`
export const TokenomiContentHeader = styled.h2`
    color: var(--TITLE, #2f2f30);
    text-align: justify;

    /* H2 */
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 46.4px */
    margin-top: unset;
    margin-bottom: 32px;
    @media (max-width: 520px) {
        text-align: left;
    }
    @media (max-width: 430px) {
        text-align: left;
    }
    @media (max-width: 420px) {
        font-size: 25px;
        text-align: center;
    }
`
export const TokenomiContent = styled.div`
    position: relative;
    color: #2f2f30;
    /* text small */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    @media (max-width: 420px) {
        width: 100%;
        font-size: 15px;
        text-align: center;
        align-items: center;
    }
`

export const TokenomiChartWrapper = styled.div`
    position: relative;
    display: flex;
    padding-top: 90px;
    padding-bottom: 140px;
    justify-content: center;
    @media (max-width: 1240px) {
        padding-top: 60px;
    }
    @media (max-width: 1024px) {
        padding-bottom: 30px;
    }
    @media (max-width: 1020px) {
        padding-bottom: 300px;
    }
    @media (max-width: 430px) {
        padding-top: 33px;
        padding-bottom: unset;
        gap: 13px;
    }
`
export const TokenomiCircleChart = styled.div`
    position: relative;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    aspect-ratio: 1/1;
    width: 500px;
    height: 500px;
    @media (max-width: 1240px) {
        margin-top: 10px;
        max-width: 480px;
    }
    @media (max-width: 1024px) {
        margin-bottom: 160px;
        max-width: 480px;
    }
    @media (max-width: 1020px) {
        display: flex;
        flex-direction: column;
        background-image: unset;
        gap: 13px;
        margin-top: 0px;
        margin-bottom: 40px;
        aspect-ratio: unset;
        max-width: 920px;
    }
    @media (max-width: 430px) {
        width: 100%;
        height: unset;
    }
    @media (max-width: 420px) {
        width: 100%;
        height: unset;
    }
`
export const TokenomicEffectImg = styled.img`
    position: relative;
    scale: 170%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 595px;
    margin-top: 75px;
    margin-bottom: 270px;
    z-index: -1;
    /* position: absolute;
    left: 10px;
    top: 20px; */
    @media (max-width: 1020px) {
        display: none;
    }
`

export const PrivateDiv = styled.div`
    position: absolute;
    display: flex;
    left: calc(50% + (40% * cos(70deg)));
    top: calc(50% - (70% * sin(60deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 7px 10px;
    @media (max-width: 1240px) {
        top: calc(50% - (50% * sin(90deg)));
    }
    @media (max-width: 1020px) {
        top: unset;
        left: unset;
        position: unset;
        /* width: 380px; */
        display: flex;
        align-items: center;
        margin: auto;
    }
`
export const PrivateValue = styled.div`
    display: flex;
    width: 77px;
    height: 77px;
    flex-shrink: 0;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    border: 2px solid #fff;
    backdrop-filter: blur(9px);
    color: #fc60f7;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 23.356px; /* 137.5% */
    letter-spacing: -0.34px;
    @media (max-width: 1240px) {
        width: 60px;
        height: 60px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 77px;
        height: 77px;
        font-size: 16px;
    }
`
export const PrivateTitle = styled.h3`
    white-space: nowrap;
    color: #ca49c7;
    font-family: Inter;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 75px;
    padding-left: 11px;
    padding-right: 44px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-left: 10px;
    border-left: 1px solid #ffffff;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 20px;
    }
    @media (max-width: 392px) {
        padding-right: 15px;
        padding-left: 2px;
    }
`
export const PrivateTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    @media (max-width: 392px) {
        margin-right: 5px;
    }
`
export const PrivateTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const PrivateTokensValue = styled.span`
    text-align: end;
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`

export const Public1Div = styled.div`
    position: absolute;
    display: flex;
    left: calc(50% + (50% * cos(35deg)));
    top: calc(50% - (50% * sin(45deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 7px 12px;
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        left: unset;
        display: flex;
        /* width: 300px; */
        align-items: center;
        margin: auto;
    }
`
export const Public1Value = styled.div`
    display: flex;
    width: 74px;
    height: 76px;
    flex-shrink: 0;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    border: 2px solid #fff;
    backdrop-filter: blur(9px);
    color: #a760af;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 48px;
        height: 48px;
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
    }
    @media (max-width: 1020px) {
        width: 60px;
        height: 60px;
        font-size: 13px;
    }
`
export const Public1Title = styled.h3`
    white-space: nowrap;
    color: #aa6db0;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px; /* 137.5% */
    letter-spacing: -0.32px;
    padding-left: 9px;
    padding-right: 28px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-left: 10px;
    border-left: 1px solid #ffffff;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 16px;
    }
    @media (max-width: 392px) {
        padding-right: 20px;
        padding-left: 2px;
    }
`
export const Public1Tokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    @media (max-width: 392px) {
        margin-right: 5px;
    }
`
export const Public1TokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 183.333% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const Public1TokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 183.333% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 10px;
    }
`

export const Public2Div = styled.div`
    position: absolute;
    display: flex;
    left: calc(50% + (50% * cos(-30deg)));
    top: calc(50% - (50% * sin(-15deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 6px 10px;
    transform: translate(-20px, 35%);
    @media (max-width: 1240px) {
        transform: translate(-10px, 20%);
    }
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        left: unset;
        display: flex;
        /* width: 330px; */
        align-items: center;
        margin: auto;
        transform: unset;
    }
`
export const Public2Value = styled.div`
    display: flex;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    border: 2px solid #fff;
    backdrop-filter: blur(9px);
    color: #49b88f;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 48px;
        height: 48px;
        font-size: 10px;
        letter-spacing: -0.2px;
    }
    @media (max-width: 1020px) {
        width: 60px;
        height: 60px;
        font-size: 13px;
        letter-spacing: -0.2px;
    }
`
export const Public2Title = styled.h3`
    white-space: nowrap;
    color: #49b88f;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px; /* 146.667% */
    letter-spacing: -0.3px;
    padding-left: 10px;
    padding-right: 54px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-left: 10px;
    border-left: 1px solid #ffffff;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 392px) {
        padding-right: 20px;
        padding-left: 2px;
    }
`
export const Public2Tokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    @media (max-width: 392px) {
        margin-right: 5px;
    }
`
export const Public2TokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const Public2TokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`

export const AdvisorsDiv = styled.div`
    position: absolute;
    display: flex;
    left: calc(50% + (50% * cos(-70deg)));
    top: calc(50% - (50% * sin(-70deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 10px 12px;
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        left: unset;
        display: flex;
        /* width: 330px; */
        align-items: center;
        margin: auto;
    }
`

export const AdvisorsValue = styled.div`
    display: flex;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    backdrop-filter: blur(9px);
    border: 2px solid #fff;
    color: #23b256;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 60px;
        height: 60px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 76px;
        height: 76px;
        font-size: 16px;
    }
`

export const AdvisorsTitle = styled.h3`
    color: #23b256;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 137.5% */
    letter-spacing: -0.32px;
    padding-left: 10px;
    padding-right: 28px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-left: 10px;
    white-space: nowrap;
    border-left: 1px solid #ffffff;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
        margin-left: 24px;
    }
    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 430px) {
        margin-right: 24px;
        padding-right: 0px;
        padding-left: 0px;
    }
`
export const AdvisorsTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    @media (max-width: 392px) {
        margin-right: 5px;
    }
`
export const AdvisorsTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const AdvisorsTokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`

export const TeamDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    right: calc(50% + (50% * cos(70deg)));
    top: calc(50% - (50% * sin(-70deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 3px 10px;
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        right: unset;
        display: flex;
        align-items: center;
        /* width: 330px; */
        margin: auto;
    }
`

export const TeamValue = styled.div`
    display: flex;
    width: 73px;
    height: 73px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    backdrop-filter: blur(9px);
    border: 2px solid #fff;
    color: #27c2a5;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 60px;
        height: 60px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 76px;
        height: 76px;
        font-size: 16px;
    }
`
export const TeamTitle = styled.h3`
    color: #27c2a5;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 75px; /* 110% */
    letter-spacing: -0.4px;
    padding-right: 14px;
    padding-left: 74px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-right: 10px;
    border-right: 1px solid #fff;
    white-space: nowrap;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 392px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`

export const TeamTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    @media (max-width: 392px) {
        margin-left: 5px;
    }
`
export const TeamTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`
export const TeamTokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`

export const AirdropsDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    right: calc(50% + (50% * cos(-30deg)));
    top: calc(50% - (50% * sin(-15deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 4px 7px;
    transform: translate(20px, 50%);
    @media (max-width: 1240px) {
        transform: translate(15px, 30%);
    }
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        right: unset;
        display: flex;
        /* width: 300px; */
        align-items: center;
        margin: auto;
        transform: unset;
    }
`

export const AirdropsValue = styled.div`
    display: flex;
    width: 77px;
    height: 77px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    backdrop-filter: blur(9px);
    border: 2px solid #fff;
    color: #22afe7;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 48px;
        height: 48px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 60px;
        height: 60px;
        font-size: 16px;
    }
`
export const AirdropsTitle = styled.h3`
    white-space: nowrap;
    color: #22afe7;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px; /* 166.952% */
    letter-spacing: -0.32px;
    padding-right: 11px;
    padding-left: 49px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-right: 10px;
    border-right: 1px solid #ffffff;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 392px) {
        padding-right: 2px;
        padding-left: 20px;
    }
`
export const AirdropsTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    @media (max-width: 392px) {
        margin-left: 5px;
    }
`
export const AirdropsTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const AirdropsTokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`
export const MarketingDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    right: calc(50% + (50% * cos(-38deg)));
    top: calc(50% - (50% * sin(50deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 7px 13px;
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        right: unset;
        display: flex;
        /* width: 300px; */
        align-items: center;
        margin: auto;
    }
`
export const MarketingValue = styled.div`
    display: flex;
    width: 91px;
    height: 91px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    backdrop-filter: blur(9px);
    border: 2px solid #fff;
    color: #0ac08f;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 48px;
        height: 48px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 60px;
        height: 60px;
        font-size: 16px;
    }
`
export const MarketingTitle = styled.h3`
    color: #0ac08f;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 95px; /* 137.5% */
    letter-spacing: -0.32px;
    padding-right: 11px;
    padding-left: 52px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-right: 10px;
    border-right: 1px solid #ffffff;
    white-space: nowrap;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 392px) {
        padding-right: 2px;
        padding-left: 20px;
    }
`

export const MarketingTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    @media (max-width: 392px) {
        margin-left: 5px;
    }
`
export const MarketingTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const MarketingTokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`

export const LiquidityDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    right: calc(50% + (40% * cos(-70deg)));
    top: calc(50% - (70% * sin(60deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 6px 12px;
    @media (max-width: 1240px) {
        top: calc(50% - (50% * sin(90deg)));
    }
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        right: unset;
        /* width: 330px; */
        display: flex;
        align-items: center;
        margin: auto;
    }
`

export const LiquidityValue = styled.div`
    display: flex;
    width: 77px;
    height: 77px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    backdrop-filter: blur(9px);
    border: 2px solid #fff;
    color: #f1aa1d;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24.533px; /* 137.5% */
    letter-spacing: -0.357px;
    @media (max-width: 1240px) {
        width: 60px;
        height: 60px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 77px;
        height: 77px;
        font-size: 16px;
    }
`

export const LiquidityTitle = styled.h3`
    color: #f1ab1d;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;

    font-weight: 700;
    line-height: 70px; /* 137.5% */
    letter-spacing: -0.32px;
    padding-right: 11px;
    padding-left: 41px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-right: 10px;
    border-right: 1px solid #ffffff;
    white-space: nowrap;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.32px;
    }
    @media (max-width: 392px) {
        padding-right: 2px;
        padding-left: 20px;
    }
`
export const LiquidityTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    @media (max-width: 392px) {
        margin-left: 5px;
    }
`
export const LiquidityTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const LiquidityTokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`

export const MiddleCircle1 = styled.div`
    position: absolute;
    left: -8%;
    top: 40%;
    width: 101px;
    height: 105px;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    @media (max-width: 1240px) {
        max-width: 88px;
    }
    @media (max-width: 1020px) {
        max-width: 0;
    }
`
export const MiddleCircle2 = styled.div`
    position: absolute;
    right: -9%;
    top: 40%;
    width: 101px;
    height: 105px;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    @media (max-width: 1240px) {
        max-width: 88px;
    }
    @media (max-width: 1020px) {
        max-width: 0;
    }
`

export const TokenomicTotalWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    margin-top: unset;
    margin-bottom: 62px;
    @media (max-width: 1320px) {
        display: none;
    }
`
export const TokenomicTotal = styled.h2`
    //styleName: H2;
    font-family: Inter;
    font-size: 29px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: unset;
    margin-bottom: unset;
`

export const TokenomicTotalValue = styled.h2`
    font-family: Inter;
    font-size: 29px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: center;
    color: #ff027a;
    margin-top: unset;
    margin-bottom: unset;
`

export const ElectronEffect1 = styled.img`
    position: absolute;
    width: 466px;
    top: -200px;
    left: -390px;

    @media (min-width: 1960px) {
        left: calc(-390px - (100vw - 1920px) / 2);
    }
    @media (max-width: 1024px) {
        display: none;
    }
`
export const ElectronEffect2 = styled.img`
    position: absolute;
    top: -10px;
    right: -390px;

    @media (min-width: 1960px) {
        right: calc(-390px - (100vw - 1920px) / 2);
    }
    @media (max-width: 1024px) {
        display: none;
    }
`
export const StarEffect2 = styled.img`
    display: none;
    @media (max-width: 430px) {
        position: absolute;
        display: block;
        width: 40px;
        height: auto;
        top: 630px;
        right: 0px;
    }
`
