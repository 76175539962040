import styled from 'styled-components'

export const LitepaperHeader = styled.div`
    display: flex;
    align-items: flex-end;
    column-gap: 7px;
    margin-top: 112px;
    width: 565px;
    flex-shrink: 0;
    border-radius: 40px;
    border: 0.7px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);
    padding: 14px 65px 14px 16px;
    @media (max-width: 1320px) {
        align-items: end;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: 680px) {
        column-gap: 10px;
        align-items: flex-end;
        margin-top: 13px;
        width: 450px;
        height: 54px;
        padding: 6px 14px 11px 8px;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: 480px) {
        column-gap: 3px;
        align-items: flex-end;
        margin-top: 13px;
        width: 350px;
        height: 44px;
        padding: 6px 14px 11px 8px;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: 430px) {
        column-gap: 3px;
        align-items: flex-end;
        margin-top: 13px;
        width: 286px;
        height: 44px;
        padding: 6px 14px 11px 8px;
        margin-left: auto;
        margin-right: auto;
    }
`

export const Title1 = styled.div`
    color: var(--titlesdd, #2f2f30);
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 110%; /* 44px */
    @media (max-width: 680px) {
        color: var(--titlesdd, #2f2f30);
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 110%; /* 22px */
    }
    @media (max-width: 480px) {
        color: var(--titlesdd, #2f2f30);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 110%; /* 22px */
    }
    @media (max-width: 430px) {
        color: var(--titlesdd, #2f2f30);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 110%; /* 22px */
    }
`
export const Title2 = styled.div`
    color: var(--titlesdd, #ff027a);
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    @media (max-width: 680px) {
        color: var(--titlesdd, #ff027a);
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
    }
    @media (max-width: 480px) {
        color: var(--titlesdd, #ff027a);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
    }
    @media (max-width: 430px) {
        color: var(--titlesdd, #ff027a);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 110%;
    }
`
export const Title3 = styled.div`
    color: var(--titlesdd, #415162);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    @media (max-width: 680px) {
        color: var(--titlesdd, #415162);
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
    }
    @media (max-width: 480px) {
        color: var(--titlesdd, #415162);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
    }
    @media (max-width: 430px) {
        color: var(--titlesdd, #415162);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
    }
`
