import styled from 'styled-components'

export const TokenomicWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    @media (max-width: 1024px) {
        padding-bottom: 0px;
    }
    @media (max-width: 430px) {
        padding-bottom: 49px;
    }
`

export const TokenomicEffectImg = styled.img`
    position: relative;
    scale: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 595px;
    margin-top: 75px;
    margin-bottom: 270px;
    z-index: -1;
    /* position: absolute;
    left: 10px;
    top: 20px; */
    @media (max-width: 1020px) {
        display: none;
    }
`
export const TokenomicHeader = styled.h3`
    color: var(--titlesdd, #415162);
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    line-height: 115.6%; /* 49.708px */

    @media (max-width: 1240px) {
        font-size: 32px;
    }

    @media (max-width: 430px) {
        font-size: 20px;
    }
`
export const TokenomicDescription = styled.div`
    margin: auto;
    width: 75%;
    /* height: 96px; */
    display: flex;
    color: #5c5f67;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 1024px) {
        width: 53%;
    }
    @media (max-width: 1020px) {
        width: 80%;
        padding-bottom: 20px;
    }
    @media (max-width: 567px) {
        font-size: 13px;
        padding-bottom: 20px;
    }
    @media (max-width: 430px) {
        font-size: 13px;
        padding: 10px 0px 28px 22px;
    }
`
export const TokenomiContent = styled.div`
    padding: 29px auto 87px auto;
    position: relative;
    /* @media (max-width:1024px){
        padding: 30px auto 10px auto;
    } */
`
export const TokenomiCircleChart = styled.div`
    position: relative;
    margin-right: auto;
    margin-left: auto;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 617px;
    margin-top: 130px;
    margin-bottom: 220px;

    @media (max-width: 1240px) {
        margin-top: 87px;
        max-width: 480px;
    }
    @media (max-width: 1024px) {
        margin-top: 87px;
        margin-bottom: 160px;
        max-width: 480px;
    }
    @media (max-width: 1020px) {
        display: flex;
        flex-direction: column;
        background-image: unset;
        gap: 13px;
        margin-top: 0px;
        margin-bottom: 40px;
        aspect-ratio: unset;
        /* max-width: 920px; */
    }
`

export const PrivateDiv = styled.div`
    position: absolute;
    display: flex;
    left: calc(50% + (50% * cos(60deg)));
    top: calc(50% - (50% * sin(75deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 7px 10px;
    @media (max-width: 1240px) {
        top: calc(50% - (50% * sin(90deg)));
    }
    @media (max-width: 1020px) {
        top: unset;
        left: unset;
        position: unset;
        /* width: 380px; */
        display: flex;
        align-items: center;
        margin: auto;
    }
`
export const PrivateValue = styled.div`
    display: flex;
    width: 77px;
    height: 77px;
    flex-shrink: 0;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    border: 2px solid #fff;
    backdrop-filter: blur(9px);
    color: #fc60f7;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 60px;
        height: 60px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 77px;
        height: 77px;
        font-size: 16px;
    }
`
export const PrivateTitle = styled.h3`
    white-space: nowrap;
    color: #ca49c7;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.4px;
    line-height: 75px;
    padding-left: 11px;
    padding-right: 44px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-left: 10px;
    border-left: 1px solid #ffffff;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 20px;
    }
    @media (max-width: 392px) {
        padding-right: 15px;
        padding-left: 2px;
    }
`
export const PrivateTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    @media (max-width: 392px) {
        margin-right: 5px;
    }
`
export const PrivateTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const PrivateTokensValue = styled.span`
    text-align: end;
    color: rgba(0, 0, 0, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`

export const Public1Div = styled.div`
    position: absolute;
    display: flex;
    left: calc(50% + (50% * cos(35deg)));
    top: calc(50% - (50% * sin(35deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 6px 12px;
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        left: unset;
        display: flex;
        /* width: 300px; */
        align-items: center;
        margin: auto;
    }
`
export const Public1Value = styled.div`
    display: flex;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    border: 2px solid #fff;
    backdrop-filter: blur(9px);
    color: #a760af;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 48px;
        height: 48px;
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
    }
    @media (max-width: 1020px) {
        width: 60px;
        height: 60px;
        font-size: 13px;
    }
`
export const Public1Title = styled.h3`
    white-space: nowrap;
    color: #aa6db0;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 137.5% */
    letter-spacing: -0.32px;
    padding-left: 9px;
    padding-right: 28px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-left: 10px;
    border-left: 1px solid #ffffff;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 16px;
    }
    @media (max-width: 392px) {
        padding-right: 20px;
        padding-left: 2px;
    }
`
export const Public1Tokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    @media (max-width: 392px) {
        margin-right: 5px;
    }
`
export const Public1TokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 183.333% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const Public1TokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 183.333% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 10px;
    }
`

export const Public2Div = styled.div`
    position: absolute;
    display: flex;
    left: calc(50% + (50% * cos(-35deg)));
    top: calc(50% - (50% * sin(-20deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 11px 14px;
    transform: translate(-20px, 35%);
    @media (max-width: 1240px) {
        transform: translate(-10px, 20%);
    }
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        left: unset;
        display: flex;
        /* width: 330px; */
        align-items: center;
        margin: auto;
        transform: unset;
    }
`
export const Public2Value = styled.div`
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    border: 2px solid #fff;
    backdrop-filter: blur(9px);
    color: #49b88f;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 48px;
        height: 48px;
        font-size: 10px;
        letter-spacing: -0.2px;
    }
    @media (max-width: 1020px) {
        width: 60px;
        height: 60px;
        font-size: 13px;
        letter-spacing: -0.2px;
    }
`
export const Public2Title = styled.h3`
    white-space: nowrap;
    color: #49b88f;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 146.667% */
    letter-spacing: -0.3px;
    padding-left: 10px;
    padding-right: 54px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-left: 10px;
    border-left: 1px solid #ffffff;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 392px) {
        padding-right: 20px;
        padding-left: 2px;
    }
`
export const Public2Tokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    @media (max-width: 392px) {
        margin-right: 5px;
    }
`
export const Public2TokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const Public2TokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`

export const AdvisorsDiv = styled.div`
    position: absolute;
    display: flex;
    left: calc(50% + (50% * cos(-70deg)));
    top: calc(50% - (50% * sin(-55deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 5px 10px;
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        left: unset;
        display: flex;
        /* width: 330px; */
        align-items: center;
        margin: auto;
    }
`

export const AdvisorsValue = styled.div`
    display: flex;
    width: 76px;
    height: 76px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    backdrop-filter: blur(9px);
    border: 2px solid #fff;
    color: #23b256;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 60px;
        height: 60px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 76px;
        height: 76px;
        font-size: 16px;
    }
`

export const AdvisorsTitle = styled.h3`
    color: #23b256;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 137.5% */
    letter-spacing: -0.32px;
    padding-left: 10px;
    padding-right: 28px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-left: 10px;
    white-space: nowrap;
    border-left: 1px solid #ffffff;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 430px) {
        padding-right: 0px;
        padding-left: 0px;
    }
`
export const AdvisorsTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    @media (max-width: 392px) {
        margin-right: 5px;
    }
`
export const AdvisorsTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const AdvisorsTokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: end;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`

export const TeamDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    right: calc(50% + (50% * cos(70deg)));
    top: calc(50% - (50% * sin(-55deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 6px 7px;
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        right: unset;
        display: flex;
        align-items: center;
        /* width: 330px; */
        margin: auto;
    }
`

export const TeamValue = styled.div`
    display: flex;
    width: 76px;
    height: 76px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    backdrop-filter: blur(9px);
    border: 2px solid #fff;
    color: #27c2a5;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 60px;
        height: 60px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 76px;
        height: 76px;
        font-size: 16px;
    }
`
export const TeamTitle = styled.h3`
    color: #27c2a5;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 110% */
    letter-spacing: -0.4px;
    padding-right: 14px;
    padding-left: 74px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-right: 10px;
    border-right: 1px solid #fff;
    white-space: nowrap;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 392px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`

export const TeamTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    @media (max-width: 392px) {
        margin-left: 5px;
    }
`
export const TeamTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`
export const TeamTokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`

export const AirdropsDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    right: calc(50% + (50% * cos(-35deg)));
    top: calc(50% - (50% * sin(-20deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 4px 7px;
    transform: translate(20px, 50%);
    @media (max-width: 1240px) {
        transform: translate(15px, 30%);
    }
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        right: unset;
        display: flex;
        /* width: 300px; */
        align-items: center;
        margin: auto;
        transform: unset;
    }
`

export const AirdropsValue = styled.div`
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    backdrop-filter: blur(9px);
    border: 2px solid #fff;
    color: #22afe7;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 48px;
        height: 48px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 60px;
        height: 60px;
        font-size: 16px;
    }
`
export const AirdropsTitle = styled.h3`
    white-space: nowrap;
    color: #22afe7;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 166.952% */
    letter-spacing: -0.32px;
    padding-right: 11px;
    padding-left: 49px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-right: 10px;
    border-right: 1px solid #ffffff;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 392px) {
        padding-right: 2px;
        padding-left: 20px;
    }
`
export const AirdropsTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    @media (max-width: 392px) {
        margin-left: 5px;
    }
`
export const AirdropsTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const AirdropsTokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`
export const MarketingDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    right: calc(50% + (50% * cos(-40deg)));
    top: calc(50% - (50% * sin(35deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 6px 6px;
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        right: unset;
        display: flex;
        /* width: 300px; */
        align-items: center;
        margin: auto;
    }
`
export const MarketingValue = styled.div`
    display: flex;
    width: 58px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    backdrop-filter: blur(9px);
    border: 2px solid #fff;
    color: #0ac08f;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 48px;
        height: 48px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 60px;
        height: 60px;
        font-size: 16px;
    }
`
export const MarketingTitle = styled.h3`
    color: #0ac08f;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 137.5% */
    letter-spacing: -0.32px;
    padding-right: 11px;
    padding-left: 52px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-right: 10px;
    border-right: 1px solid #ffffff;
    white-space: nowrap;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 392px) {
        padding-right: 2px;
        padding-left: 20px;
    }
`

export const MarketingTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    @media (max-width: 392px) {
        margin-left: 5px;
    }
`
export const MarketingTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const MarketingTokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`

export const LiquidityDiv = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    right: calc(50% + (50% * cos(-60deg)));
    top: calc(50% - (50% * sin(70deg)));
    border-radius: 61.5px;
    border: 2px solid var(--Linear, #fff);
    background: var(
        --Linear,
        linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 5.65%,
            rgba(255, 255, 255, 0.49) 94.35%
        )
    );
    backdrop-filter: blur(9px);
    padding: 6px 12px;
    @media (max-width: 1240px) {
        top: calc(50% - (50% * sin(90deg)));
    }
    @media (max-width: 1020px) {
        position: relative;
        top: unset;
        right: unset;
        /* width: 330px; */
        display: flex;
        align-items: center;
        margin: auto;
    }
`

export const LiquidityValue = styled.div`
    display: flex;
    width: 77px;
    height: 77px;
    border-radius: 50%;
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.89) 5.65%,
        rgba(255, 255, 255, 0.49) 94.35%
    );
    backdrop-filter: blur(9px);
    border: 2px solid #fff;
    color: #f1aa1d;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 1240px) {
        width: 60px;
        height: 60px;
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        width: 77px;
        height: 77px;
        font-size: 16px;
    }
`

export const LiquidityTitle = styled.h3`
    color: #f1ab1d;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;

    font-weight: 700;
    line-height: 70px; /* 137.5% */
    letter-spacing: -0.32px;
    padding-right: 11px;
    padding-left: 41px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-right: 10px;
    border-right: 1px solid #ffffff;
    white-space: nowrap;
    @media (max-width: 1240px) {
        font-size: 10px;
        line-height: 22px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
    }
    @media (max-width: 1020px) {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.32px;
    }
    @media (max-width: 392px) {
        padding-right: 2px;
        padding-left: 20px;
    }
`
export const LiquidityTokens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    @media (max-width: 392px) {
        margin-left: 5px;
    }
`
export const LiquidityTokensTitle = styled.span`
    color: rgba(0, 0, 0, 0.7);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1020px) {
        font-size: 14px;
    }
`
export const LiquidityTokensValue = styled.span`
    color: rgba(0, 0, 0, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    text-align: start;
    @media (max-width: 1240px) {
        font-size: 10px;
    }
    @media (max-width: 1240px) {
        font-size: 14px;
    }
`

export const MiddleCircle1 = styled.img`
    position: absolute;
    left: -3%;
    top: 41%;
    @media (max-width: 1240px) {
        max-width: 88px;
    }
    @media (max-width: 1020px) {
        max-width: 0;
    }
`
export const MiddleCircle2 = styled.img`
    position: absolute;
    right: -3%;
    top: 41%;
    @media (max-width: 1240px) {
        max-width: 88px;
    }
    @media (max-width: 1020px) {
        max-width: 0;
    }
`
export const StarEffect1 = styled.img`
    display: none;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        width: 30px;
        top: 20px;
        left: 40px;
    }
`
export const StarEffect2 = styled.img`
    display: none;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        width: 30px;
        top: -60px;
        right: 30px;
    }
`
export const StarEffect3 = styled.img`
    display: none;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        width: 30px;
        bottom: -825px;
        left: 55px;
    }
`
