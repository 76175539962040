import styled from 'styled-components'

export const RoadmapWrapper = styled.div`
    position: relative;
    padding-top: 285px;
    padding-bottom: 105px;
    @media (max-width: 1024px) {
        padding-top: 138px;
        padding-bottom: 60px;
    }
    @media (max-width: 768px) {
        padding: 80px 0px;
    }
    @media (max-width: 430px) {
        padding: 140px 0px 85px 0px;
    }
`
export const ImgDiv = styled.img`
    width: 20px;
    height: 20px;
`
export const RoadmapTitle = styled.h3`
    text-align: center;
    font-family: Inter;
    font-size: 43px;
    font-weight: 700;
    line-height: 110%;
    color: #415162;
    margin-bottom: 30px;

    @media (max-width: 1024px) {
        font-weight: 500;
        font-size: 32px;
        margin-bottom: 24px;
    }

    @media (max-width: 576px) {
        font-size: 20px;
        margin-bottom: 24px;
    }
`

export const Cards = styled.div`
    margin-left: -39px;
    margin-right: -39px;
    border-radius: 70px;
    border: 1px solid #fff;
    background: #1c1725;
    padding: 70px 31px 70px 47px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 1420px) {
        border-radius: 50px;
        width: 100%;
        max-width: 882px;
        padding: 35px 26px;
        gap: 47px;
        margin: auto;
    }

    @media (max-width: 940px) {
        max-width: 400px;
    }

    @media (max-width: 430px) {
        max-width: 336px;
    }

    @media (max-width: 376px) {
        width: 100%;
        padding: 24px 20px;
    }
`

export const CardGrid1 = styled.div`
    display: grid;
    grid-template-columns: 65.22fr 235.78fr 199fr 260.87fr 238.29fr;
    gap: 16px;
    position: relative;
    z-index: 1;
    @media (max-width: 1420px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;

        > div:not(:nth-child(1)) {
            min-width: 281px;
        }
    }

    @media (max-width: 940px) {
        flex-direction: column;
    }
`
export const CardGrid2 = styled.div`
    display: grid;
    grid-template-columns: 260.87fr 234.11fr 224.08fr 216.55fr 63.53fr;
    gap: 16px;
    position: relative;
    z-index: 1;

    @media (max-width: 1420px) {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
        justify-content: center;
        gap: 50px;

        > div:not(:nth-child(5)) {
            min-width: 272px;
            min-height: 171px;
        }
    }

    @media (max-width: 940px) {
        flex-direction: column-reverse;
    }
`

export const YearCardDiv = styled.div`
    position: relative;
    border: 0.5px solid rgba(255, 255, 255, 0.26);
    background: #238def;
    display: flex;
    width: 78px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 1420px) {
        width: 148px;
        height: 166px;
        font-size: 40px;
    }

    @media (max-width: 1024px) {
        width: 148px;
        height: 166px;
    }

    @media (max-width: 940px) {
        align-self: center;
        width: 57%;
    }

    @media (max-width: 430px) {
        width: 197px;
    }
`
export const RoadmapDate = styled.div`
    display: flex;
    width: 366px;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    border: 2px solid #fff;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 11px 14px 43.8px 4px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(36.150001525878906px);
`

export const ContentCardDiv = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 8px;
    border-radius: 24px;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    background: #3a3347;
    backdrop-filter: blur(36.150001525878906px);
    position: relative;

    @media (max-width: 768px) {
        backdrop-filter: none;
        width: 100%;
    }
`

export const ContentCardTitle = styled.div`
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`
export const ContentCardRow = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    color: #fff;
    font-family: inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    white-space: nowrap;
    & div {
        display: flex;
        align-items: end;
        width: 20px;
        height: 20px;
    }

    @media (max-width: 768px) {
        & span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
`
export const ArrowImage = styled.img`
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    display: block;
    z-index: 10;

    &.arrow-right {
        left: -28px;

        @media (max-width: 1420px) {
            left: -46px;
        }
    }

    &.arrow-left {
        right: -28px;

        @media (max-width: 1420px) {
            right: -46px;
        }
    }

    &.arrow-down {
        top: 88%;
        left: 42%;

        @media (max-width: 1420px) {
            top: 103%;
        }
    }

    &.arrow-up {
        top: -28px;
        left: 42%;

        @media (max-width: 940px) {
            top: -46px;
            left: 50%;
            transform: translate(-50%, 0px);
        }
    }

    &.year {
        top: -33px;
        left: 50%;
        transform: translate(-50%, 0px);

        @media (max-width: 1420px) {
            top: -46px;
            right: 0px;
            left: unset;
        }

        @media (max-width: 940px) {
            left: 50%;
            transform: translate(-50%, 0px);
        }
    }
`

export const CloudEffect1 = styled.img`
    position: absolute;
    top: 82px;
    left: -235px;
    z-index: -1;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const CloudEffect2 = styled.img`
    position: absolute;
    width: 473px;
    top: 140px;
    right: -265px;
    z-index: -1;
    @media (max-width: 1024px) {
        display: none;
    }
`

export const CloudEffect3 = styled.img`
    width: 431px;
    position: absolute;
    bottom: -180px;
    left: -255px;
    z-index: 1;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const CloudEffect4 = styled.img`
    width: 379px;
    position: absolute;
    bottom: -420px;
    right: -300px;
    z-index: -1;
    @media (max-width: 1024px) {
        display: none;
    }
`
