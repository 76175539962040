import styled from 'styled-components'
export const PolicyWrapper = styled.div`
    position: relative;
`

export const Wrapper = styled.div`
    padding: 100px;
    width: 80%;
    margin: 25px auto 90px auto;
    border-radius: 50px;
    opacity: 0.9;
    background: #f9f9f9;
    z-index: 1;
    @media (max-width: 830px) {
        width: calc(100% - 80px);
        padding: 50px;
        margin: 30px 40px 80px 40px;
        /* margin: 25px 10px 90px 10px; */
    }
    @media (max-width: 670px) {
        width: calc(100% - 46px);
        padding: 20px;
        margin: 30px 23px 80px 23px;
    }
`
export const Header = styled.h3`
    color: #415162;
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
`
export const Content = styled.div``
export const ContentSpan = styled.p`
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
export const TitleSpan = styled.span`
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`
export const ContentUl = styled.ul`
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`

export const LinkSpan = styled.a`
    color: #1501ff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
`
export const PrivacyContent = styled.div`
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
export const TableContent = styled.div`
    margin: 32px 0px;
    border-collapse: collapse;
    & thead tr th {
        border-right: 1px solid #eaecf0;
        border-bottom: 1px solid #eaecf0;
        background: #e4e4e7;
        padding: 12px 20px;
        color: #344054;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }

    & tbody tr td {
        border-right: 1px solid #eaecf0;
        border-bottom: 1px solid #eaecf0;
        background: #fcfcfd;
        padding: 12px 20px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    & tbody tr td:first-child {
        border-bottom: 1px solid #eaecf0;
    }
`

export const LinearGradient1 = styled.div`
    position: absolute;
    top: 285px;
    left: -150px;
    border-radius: 50%;
    opacity: 0.3;
    background: linear-gradient(180deg, #fff500 100%, #fff73d 84%);
    width: 318px;
    height: 318px;
    filter: blur(100px);
    z-index: -1;
`
export const LinearGradient2 = styled.img`
    position: absolute;
    top: 27px;
    right: -300px;
    width: 513px;
    height: 513px;
    opacity: 0.6;
    border: 5px solid #007bff;
    filter: blur(70px);
`
export const LinearGradient3 = styled.div`
    position: absolute;
    top: 924px;
    left: -50px;
    border-radius: 50%;
    opacity: 0.2;
    background: linear-gradient(180deg, #faff00 80%, #c1ff3d 84%);
    width: 440px;
    height: 440px;
    filter: blur(50px);
    z-index: -1;
`
export const LinearGradient4 = styled.div`
    position: absolute;
    top: 1600px;
    left: -50px;
    border-radius: 50%;
    opacity: 0.3;
    background: linear-gradient(180deg, #00ffc2 100%, #413dff 84%);
    width: 440px;
    height: 440px;
    filter: blur(50px);
    z-index: -1;
`
export const LinearGradient5 = styled.div`
    position: absolute;
    top: 1300px;
    right: -200px;
    border-radius: 50%;
    opacity: 0.3;
    background: linear-gradient(180deg, #0038ff 80%, #3dfff3 84%);
    width: 219px;
    height: 219px;
    filter: blur(80px);
    z-index: -1;
`
export const LinearGradient6 = styled.div`
    position: absolute;
    top: 1699px;
    right: -302px;
    border-radius: 50%;
    opacity: 0.3;
    background: linear-gradient(180deg, #ff00c7 100%, #ff3da6 84%);
    width: 219px;
    height: 219px;
    filter: blur(50px);
    z-index: -1;
`

