import React from 'react'
import { SocialItem, SocialWrapper } from '../common'
import { socialIcons } from '../../constants/variables'

const SocialLinks = () => {
    return (
        <SocialWrapper>
            {socialIcons.map((socialIcon) => (
                <SocialItem
                    key={socialIcon.content}
                    href={socialIcon.link}
                    target="_blank">
                    <img src={socialIcon.icon} alt={socialIcon.content} />
                </SocialItem>
            ))}
        </SocialWrapper>
    )
}

export default SocialLinks
