import styled from 'styled-components'

export const FoundingTeamHeader = styled.h1`
    color: var(--titlesdd, #415162);
    text-align: center;
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin-top: 51px;
    margin-bottom: 51px;

    @media (max-width: 430px) {
        color: var(--titles, #0f172a);
        font-family: Gotham;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 22px */
        margin-bottom: 40px;
    }
`
export const FoundingTeamItemWarpper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    padding-top: 20.5px;
    padding-bottom: 20.5px;
`
export const FoundingTeamItem = styled.div`
    display: flex;
    column-gap: 10px;
    padding: 35px ${(props) => props.paddingLeft} 30.306px
        ${(props) => props.paddingRight};
    border-radius: 40px;
    border: 0.7px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);
    margin-right: -5px;

    @media (max-width: 950px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 10px;
    }

    @media (max-width: 430px) {
        margin-left: 19px;
        margin-right: 19px;
        padding: 35.5px 31.5px 35.5px;
    }
`
export const FoundingTeamPersonalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    @media (max-width: 950px) {
        width: unset;
    }
`
export const FoundingTeamPhoto = styled.img`
    width: 119px;
    height: 119px;
`
export const FoundingTeamPersonalName = styled.div`
    color: var(--TITLE, #2f2f30);
    font-family: Inter;
    font-size: 21.51px;
    font-style: normal;
    font-weight: 700;
    line-height: ${(props) => props.lineHeight};
    margin-top: 16px;
    margin-bottom: 0px;
    max-width: ${(props) => props.width};

    @media (max-width: 950px) {
        max-width: unset;
        text-align: center;
    }
`
export const FoundingTeamPersonalPosition = styled.div`
    color: #929292;
    font-family: Inter;
    font-size: 13.238px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin-top: 7px;
    margin-bottom: 0px;
    width: ${(props) => props.width};

    @media (max-width: 950px) {
        max-width: ${(props) => props.mobileWidth};
        text-align: center;
    }
`
export const FoundingTeamContent = styled.p`
    color: var(--TITLE, #2f2f30);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-top: 0px;
    margin-bottom: 0px;
    white-space: pre-line;
`
