import React from 'react'

import {
    MainBlocksWrapper,
    MainBlocksHeader,
    MainBlocksTitle,
    MainBlocksTitle1,
    MainBlocksContent,
    MainBlocksContent1,
    MainBlocksContent4,
    MainBlocksUl,
    MainBlocksColumnDiv,
    MainBlocksDiv1,
    MainBlocksDiv2,
    MainBlocksDiv3,
    MainBlocksDiv4,
    MainBlocksDiv5,
    MainBlocksDiv7,
    TileDiv1,
    TileDiv2,
    TileDiv3,
    TileDiv4,
    TileWrapper,
    Tile1,
    Tile2,
    Tile3,
    Tile4,
    CloudEffect1,
    CloudEffect2,
    CloudEffect3,
    CloudEffect4
} from './styled.jsx'
const MainBlocks = () => {
    return (
        <MainBlocksWrapper>
            <MainBlocksColumnDiv>
                <CloudEffect1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud.png"
                    alt="cloud"
                />
                <CloudEffect2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud.png"
                    alt="cloud"
                />
                <MainBlocksDiv1>
                    <CloudEffect4
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/mainblocks.png"
                        alt="cloud"
                    />
                    <MainBlocksHeader>The Four Main Blocks</MainBlocksHeader>
                    <MainBlocksContent>
                        The expanding world of cryptocurrency brings exciting
                        financial opportunities and new challenges, especially
                        in emerging markets. With diverse crypto assets and
                        decentralized finance on the rise, vigilant monitoring
                        and improved frameworks are essential. While current
                        risks are not systemic, operational issues, investor
                        protection concerns, and insufficient stablecoin
                        reserves pose challenges. In emerging markets, the surge
                        in crypto assets poses cryptoization risks and potential
                        instability2. In this dynamic landscape, OBINEX™, an
                        innovative financial ecosystem, aims to reshape the
                        handling of money and digital assets. To achieve this,
                        we will build four main blocks to provide a diverse
                        range of services:
                    </MainBlocksContent>
                </MainBlocksDiv1>
                <MainBlocksDiv2>
                    <TileWrapper>
                        <TileDiv1>
                            <Tile1>CeFi</Tile1>
                        </TileDiv1>
                        <TileDiv2>
                            <Tile2>DeFi</Tile2>
                        </TileDiv2>
                        <TileDiv3>
                            <Tile3>AiFi</Tile3>
                        </TileDiv3>
                        <TileDiv4>
                            <Tile4>BlockFi</Tile4>
                        </TileDiv4>
                    </TileWrapper>
                </MainBlocksDiv2>
            </MainBlocksColumnDiv>
            <MainBlocksColumnDiv>
                <CloudEffect3
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud.png"
                    alt="cloud"
                />
                <MainBlocksDiv3>
                    <MainBlocksTitle>
                        1.Centralized Finance (CeFi)
                    </MainBlocksTitle>
                    <MainBlocksContent>
                        In early 2024, our main web application, the CEX
                        (Centralized Exchange), will be launched to expand our
                        global user base and enhance their financial
                        empowerment. Our roadmap includes the introduction of a
                        fully integrated DeFi platform later in 2024, providing
                        a suite of innovative financial products to our users.
                    </MainBlocksContent>
                    <MainBlocksTitle1>Services</MainBlocksTitle1>
                    <MainBlocksContent>
                        <MainBlocksUl>
                            <li>Staking</li>
                            <li>FIAT entry and exit gateways</li>
                            <li>Asset or liquidity custody</li>
                            <li>Loans</li>
                            <li>Algorithmic trading</li>
                            <li>Full KYC verification</li>
                        </MainBlocksUl>
                    </MainBlocksContent>
                </MainBlocksDiv3>
                <MainBlocksDiv7>
                    <MainBlocksTitle>
                        2.Decentralized Finance (DeFi)
                    </MainBlocksTitle>
                    <MainBlocksContent1>
                        OBINEX™ is committed to advancing financial inclusion
                        and decentralization. Our decentralized wallet empowers
                        users with direct control over assets, enabling
                        independent financial decision-making without
                        intermediaries. Leveraging the capabilities of smart
                        contracts and public blockchains, OBINEXTM unlocks a
                        diverse array of services and opportunities in digital
                        finance.
                    </MainBlocksContent1>
                    <MainBlocksTitle1>Services</MainBlocksTitle1>
                    <MainBlocksContent>
                        <MainBlocksUl>
                            <li>Lending and borrowing</li>
                            <li>Web 3.0 Search Engine</li>
                            <li>Decentralized exchanges</li>
                            <li>Multi-platform asset support</li>
                            <li>Participation in liquidity markets</li>
                            <li>Purchases with gift cards</li>
                        </MainBlocksUl>
                    </MainBlocksContent>
                </MainBlocksDiv7>
            </MainBlocksColumnDiv>
            <MainBlocksColumnDiv>
                <MainBlocksDiv4>
                    <MainBlocksTitle>
                        3. AI-based Financial Services (AiFi)
                    </MainBlocksTitle>
                    <MainBlocksContent>
                        The OBINEX™ CEX platform integrates predictive
                        artificial intelligence (AiFi) with financial services
                        to offer algorithm-driven insights and advanced
                        analytics. This creates a highly personalized,
                        data-centric user experience, that tailors custom
                        financial solutions for every individual. OBINEX™ AiFi
                        is the new generation of robo-advising, offering an
                        intelligent and efficient financial experience,
                        empowering users for informed decision making and profit
                        maximization.
                    </MainBlocksContent>
                    <MainBlocksTitle1>Features</MainBlocksTitle1>
                    <MainBlocksContent>
                        <MainBlocksUl>
                            <li>Automated advice</li>
                            <li>Automated arbitrage</li>
                            <li>AIntegrated P2P system</li>
                            <li>Big Data and Blockchain analysis </li>
                        </MainBlocksUl>
                    </MainBlocksContent>
                </MainBlocksDiv4>
                <MainBlocksDiv5>
                    <MainBlocksTitle>
                        4. Layer 4 Blockchain (BlockFi)
                    </MainBlocksTitle>
                    <MainBlocksContent4>
                        Embracing a Layer 4 blockchain approach, OBINEX™ has
                        departed from the conventional 3-layer blockchain
                        structure, positioning itself at the forefront of
                        blockchain innovation. Each layer is meticulously
                        enhanced for specific functionalities, enabling advanced
                        development through the integration of cutting-edge
                        predictive AI technology. This heightens user
                        experience, strengthens security measures, and improves
                        scalability. With these advancements, OBINEX™ can
                        diversify its product range, addressing a wide spectrum
                        of user needs and preferences.
                    </MainBlocksContent4>
                    <MainBlocksTitle1>Advancements</MainBlocksTitle1>
                    <MainBlocksContent>
                        <MainBlocksUl>
                            <li>Staking</li>
                            <li>Fiat enrty ab</li>
                            <li>Asset or liquidity custody</li>
                            <li>Loans</li>
                            <li>Algorithmic trading</li>
                            <li>Full KYC verification</li>
                        </MainBlocksUl>
                    </MainBlocksContent>
                </MainBlocksDiv5>
            </MainBlocksColumnDiv>
        </MainBlocksWrapper>
    )
}

export default MainBlocks
