import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './routes'
import Layout from './pages/Layout/layout'
import ScrollToTop from './components/Common/ScrollToTop'

function App() {
    return (
        <div>
            <BrowserRouter>
                <Layout>
                    <ScrollToTop />
                    <AppRoutes />
                </Layout>
            </BrowserRouter>
        </div>
    )
}

export default App
