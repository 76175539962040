import styled from 'styled-components'

export const HeaderContainer = styled.div`
    z-index: 10;
    width: 100%;
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    left: ${(props) => (props.position === 'top' ? 'unset' : '50%')};
    position: ${(props) => (props.position === 'top' ? 'unset' : 'fixed')};
    transform: ${(props) =>
        props.position === 'top' ? 'unset' : 'translate(-50%, 0px)'};
`

export const HeaderWrapper = styled.div`
    padding: 22px 0px;
    z-index: 10;
    position: relative;
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
`

export const Navbar = styled.div`
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    justify-content: space-between;
    padding: 13px 22px 13px 20px;
    backdrop-filter: blur(6.199999809265137px);
    @media (max-width: 1260px) {
        padding: 6px 23px 6px 27px;
        margin-left: 17px;
        margin-right: 17px;
    }

    @media (max-width: 1024px) {
        margin-left: 78px;
        margin-right: 78px;
    }

    @media (max-width: 822px) {
        margin-left: 20px;
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        margin-left: 17px;
        margin-right: 17px;
    }
`
export const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 90px;
    gap: 5px;
    @media (max-width: 1024px) {
        gap: 1px;
    }
    @media (max-width: 892px) {
        gap: 1px;
        margin-left: unset;
    }
`
export const NavItem = styled.span`
    font-family: Gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 50px;
    background-color: ${(props) => props.background};
    &:hover {
        background-color: #ffffff;
    }
    @media (max-width: 1260px) {
        font-size: 11px;
        padding: 6px 11px;
    }
    @media (max-width: 822px) {
        display: none;
    }
`
export const LogoWrapper = styled.div`
    align-items: left;
`
export const PurchaseButton = styled.button`
    border-radius: 50px;
    padding: 7px 12px;
    color: #fff;
    font-family: Gotham;
    font-size: 16px;
    font-style: normal;
    border: unset;
    font-weight: 500;
    background: #00adee;
    box-shadow: 0px 4px 49.5px -20px rgba(0, 0, 0, 0.2);

    @media (max-width: 1260px) {
        font-size: 12px;
        padding: 5px 15px;
    }

    @media (max-width: 822px) {
        pading: 6px 3px 5px 7px;
        font-size: 13px;
    }
`
export const MobileNavbar = styled.div`
    position: relative;
    cursor: pointer;
    display: none;
    @media (max-width: 822px) {
        display: flex;
        padding: 0px;
        align-items: center;
        & img {
            display: flex;
            align-items: center;
        }
    }
`

export const MobileNavItem = styled.div`
    display: none;

    @media (max-width: 822px) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 20px;
        border-radius: 25px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        background: rgba(255, 255, 255, 0.85);
        backdrop-filter: blur(2px);
        font-size: 13px;
        border-radius: 50px;
    }
`

export const MobileMenu = styled.div`
    display: none;

    @media (max-width: 822px) {
        border-radius: 15px;
        opacity: 0.98;
        background: rgba(168, 172, 201, 0.8);
        gap: 16px;
        padding: 16px 4px;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-top: 51px;
        position: fixed;
        width: 100%;
        top: 0;
        right: 0;
        /* z-index: 10; */
    }
`

export const CloseButton = styled.button`
    position: absolute;
    border: none;
    top: 5px;
    right: 5px;
    background: transparent;
    font-size: 24px;
    padding: 10px;
`
