import { useState, useEffect, Fragment } from 'react'
import {
    RoadmapHeader,
    RoadmapWrapper,
    RoadmapYear,
    RoadmapItem,
    RoadmapItemTitle,
    RoadmapItemRow,
    RoadmapItemIcon,
    ArrowImage,
} from './styled'
import { roadmapData } from '../../../constants/variables'

const Roadmap = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const getArrowDirection = (arrows) => {
        for (const resolution in arrows) {
            const [min, max] = resolution.split('~').map(Number)
            if (screenWidth >= min && screenWidth < max) {
                return arrows[resolution]
            }
        }
        return arrows['1260~9999']
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <>
            <RoadmapHeader>Roadmap</RoadmapHeader>
            <RoadmapWrapper>
                {roadmapData.map((data, index) =>
                    data.rows ? (
                        <RoadmapItem key={index}>
                            <RoadmapItemTitle>{data.date}</RoadmapItemTitle>
                            {data.rows.map((rowData) => (
                                <RoadmapItemRow
                                    key={rowData.content + rowData.alt}>
                                    <RoadmapItemIcon
                                        src={rowData.icon}
                                        alt={rowData.alt}
                                    />
                                    <span>{rowData.content}</span>
                                </RoadmapItemRow>
                            ))}
                            {data.arrows && (
                                <ArrowImage
                                    alt="arrow"
                                    className={getArrowDirection(data.arrows)}
                                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/rightArrow.png"
                                />
                            )}
                        </RoadmapItem>
                    ) : (
                        <RoadmapYear key={index}>
                            {data.date}
                            {data.arrows && (
                                <ArrowImage
                                    alt="arrow"
                                    className={getArrowDirection(data.arrows)}
                                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/rightArrow.png"
                                />
                            )}
                        </RoadmapYear>
                    )
                )}
            </RoadmapWrapper>
        </>
    )
}

export default Roadmap
