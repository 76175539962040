import { useRef, useEffect } from 'react'
import { Container } from '../../common'
import {
    TokenomicWrapper,
    TokenomicHeader,
    TokenomicDescription,
    TokenomiContent,
    TokenomiCircleChart,
    PrivateDiv,
    Public1Div,
    Public2Div,
    AdvisorsDiv,
    TeamDiv,
    AirdropsDiv,
    MarketingDiv,
    LiquidityDiv,
    MiddleCircle1,
    MiddleCircle2,
    PrivateValue,
    Public1Value,
    Public2Value,
    AdvisorsValue,
    TeamValue,
    AirdropsValue,
    MarketingValue,
    LiquidityValue,
    PrivateTitle,
    Public1Title,
    Public2Title,
    AdvisorsTitle,
    TeamTitle,
    AirdropsTitle,
    MarketingTitle,
    LiquidityTitle,
    PrivateTokens,
    PrivateTokensTitle,
    PrivateTokensValue,
    Public1Tokens,
    Public1TokensTitle,
    Public1TokensValue,
    Public2Tokens,
    Public2TokensTitle,
    Public2TokensValue,
    AdvisorsTokens,
    AdvisorsTokensValue,
    AdvisorsTokensTitle,
    TeamTokens,
    TeamTokensTitle,
    TeamTokensValue,
    AirdropsTokens,
    AirdropsTokensTitle,
    AirdropsTokensValue,
    MarketingTokens,
    MarketingTokensTitle,
    MarketingTokensValue,
    LiquidityTokens,
    LiquidityTokensTitle,
    LiquidityTokensValue,
    TokenomicEffectImg,
    StarEffect1,
    StarEffect2,
    StarEffect3,
} from './styled'
import { TokenomicImg } from '../../../constants/images'

const Tokenomic = ({ selectedRoute }) => {
    const containerRef = useRef(null)

    useEffect(() => {
        if (selectedRoute === 'tokenomic') {
            window.scrollTo({
                top:
                    containerRef.current.getBoundingClientRect().top +
                    window.scrollY -
                    100,
                behavior: 'smooth',
            })
        }
    }, [selectedRoute])

    return (
        <Container>
            <TokenomicWrapper ref={containerRef}>
                <TokenomiContent>
                    <StarEffect1
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                        alt="starEffect"
                    />
                    <StarEffect2
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                        alt="starEffect"
                    />
                    <StarEffect3
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                        alt="starEffect"
                    />

                    <TokenomicHeader>$OBIX Token</TokenomicHeader>
                    <TokenomicDescription>
                        Our tokenomics relies on a hybrid balancing technology
                        that allows the token offer to be regulated, ensuring
                        that the token supply adapts to the project's
                        development stage and specific market conditions,
                        causing the token price to be remunerative for investors
                        while remaining attractive to platform users: the supply
                        increment is balanced by token burns on each
                        transaction.
                    </TokenomicDescription>
                </TokenomiContent>
                <TokenomiCircleChart img={TokenomicImg}>
                    <TokenomicEffectImg
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/tokenomix/tokenomicEffect.png"
                        alt="tokenomicEffectImg"
                    />
                    <LiquidityDiv>
                        <LiquidityValue>15.14%</LiquidityValue>
                        <LiquidityTitle>Liquidity</LiquidityTitle>
                        <LiquidityTokens>
                            <LiquidityTokensTitle>tokens</LiquidityTokensTitle>
                            <LiquidityTokensValue>
                                11.894,127
                            </LiquidityTokensValue>
                        </LiquidityTokens>
                    </LiquidityDiv>
                    <PrivateDiv>
                        <PrivateValue>14.63%</PrivateValue>
                        <PrivateTitle>Private Sale</PrivateTitle>
                        <PrivateTokens>
                            <PrivateTokensTitle>tokens</PrivateTokensTitle>
                            <PrivateTokensValue>11.487,965</PrivateTokensValue>
                        </PrivateTokens>
                    </PrivateDiv>
                    <MarketingDiv>
                        <MarketingValue>9.18%</MarketingValue>
                        <MarketingTitle>Marketing</MarketingTitle>
                        <MarketingTokens>
                            <MarketingTokensTitle>tokens</MarketingTokensTitle>
                            <MarketingTokensValue>
                                7.213,246
                            </MarketingTokensValue>
                        </MarketingTokens>
                    </MarketingDiv>
                    <Public1Div>
                        <Public1Value>21.24%</Public1Value>
                        <Public1Title>Public Sale 1</Public1Title>
                        <Public1Tokens>
                            <Public1TokensTitle>tokens</Public1TokensTitle>
                            <Public1TokensValue>16.684,902</Public1TokensValue>
                        </Public1Tokens>
                    </Public1Div>
                    <AirdropsDiv>
                        <AirdropsValue>1.25%</AirdropsValue>
                        <AirdropsTitle>Airdrops</AirdropsTitle>
                        <AirdropsTokens>
                            <AirdropsTokensTitle>tokens</AirdropsTokensTitle>
                            <AirdropsTokensValue>980,187</AirdropsTokensValue>
                        </AirdropsTokens>
                    </AirdropsDiv>
                    <Public2Div>
                        <Public2Value>33.08%</Public2Value>
                        <Public2Title>Public Sale 2</Public2Title>
                        <Public2Tokens>
                            <Public2TokensTitle>tokens</Public2TokensTitle>
                            <Public2TokensValue>25.984,683</Public2TokensValue>
                        </Public2Tokens>
                    </Public2Div>
                    <TeamDiv>
                        <TeamValue>3.83%</TeamValue>
                        <TeamTitle>Team</TeamTitle>
                        <TeamTokens>
                            <TeamTokensTitle>tokens</TeamTokensTitle>
                            <TeamTokensValue>3.010,659</TeamTokensValue>
                        </TeamTokens>
                    </TeamDiv>
                    <AdvisorsDiv>
                        <AdvisorsValue>1.63%</AdvisorsValue>
                        <AdvisorsTitle>Advisors & Partners</AdvisorsTitle>
                        <AdvisorsTokens>
                            <AdvisorsTokensTitle>tokens</AdvisorsTokensTitle>
                            <AdvisorsTokensValue>1.283,443</AdvisorsTokensValue>
                        </AdvisorsTokens>
                    </AdvisorsDiv>

                    <MiddleCircle1
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/tokenomix/VectorLeft.png"
                        alt="Circleleft"
                    />
                    <MiddleCircle2
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/tokenomix/VectorRight.png"
                        alt="Circleright"
                    />
                </TokenomiCircleChart>
            </TokenomicWrapper>
        </Container>
    )
}

export default Tokenomic
