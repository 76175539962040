import Values from '../../components/Litepaper/Values'
import MainBlocks from '../../components/Litepaper/MainBlocks'
import Tokenomic from '../../components/Litepaper/Tokenomic'
import Roadmap from '../../components/Litepaper/Roadmap'
import FoundingTeam from '../../components/Litepaper/FoundingTeam'
import Partners from '../../components/Litepaper/Partners'
import { LitepaperContainer } from '../../components/common'
import { LitepaperHeader, Title1, Title2, Title3 } from './styled'

const Litepaper = () => {
    return (
        <LitepaperContainer>
            <LitepaperHeader>
                <Title1>Obinex </Title1>
                <Title2>Litepaper</Title2>
                <Title3>v2.0 | 2024</Title3>
            </LitepaperHeader>
            <Values />
            <MainBlocks />
            <Tokenomic />
            <Roadmap />
            <FoundingTeam />
            <Partners />
        </LitepaperContainer>
    )
}

export default Litepaper
