import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from '../../common'
import Checkbox from '../../Common/Checkbox'
import {
    NewsletterWrapper,
    SubscribeWrapper,
    Newsletters,
    NewsletterHeader,
    StyledInput,
    SubscribeButton,
    PrivacyPolicyText,
    EmailImg,
    SubscribeCheckbox,
    StarEffect1,
    StarEffect2,
} from './styled'

const Newsletter = () => {
    const navigate = useNavigate()

    return (
        <Container>
            <NewsletterWrapper>
                <Newsletters>
                    <NewsletterHeader>Newsletter</NewsletterHeader>
                    <SubscribeWrapper>
                        <StyledInput placeholder="First Name" />
                        <StyledInput placeholder="Last Name" />
                        <StyledInput placeholder="Email" />
                        <SubscribeButton>Subscribe</SubscribeButton>
                        <SubscribeCheckbox>
                            <Checkbox
                                label={
                                    <>
                                        I agree with the storage and handling of
                                        any data by the website.{' '}
                                        <PrivacyPolicyText
                                            onClick={() =>
                                                navigate('/privacy')
                                            }>
                                            Privacy Policy
                                        </PrivacyPolicyText>
                                    </>
                                }
                            />
                        </SubscribeCheckbox>
                    </SubscribeWrapper>
                </Newsletters>
                <EmailImg>
                    <img
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/emailImg.png"
                        alt="emailImg"
                    />
                </EmailImg>
                <StarEffect1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="starEffect"
                />
                <StarEffect2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="starEffect"
                />
            </NewsletterWrapper>
        </Container>
    )
}

export default Newsletter
