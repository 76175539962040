import styled from 'styled-components'

export const PossiblitesWrapper = styled.div`
    margin-left: -6px;
    margin-right: -6px;
    padding-top: 130px;
    padding-bottom: 107px;
    position: relative;

    @media (max-width: 1320px) {
        margin-left: auto;
        margin-right: auto;
        max-width: 676px;
    }
    @media (max-width: 1024px) {
        padding-top: 0px;
        padding-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        max-width: 676px;
    }

    @media (max-width: 768px) {
        width: 60%;
        min-width: 274px;
        padding-top: 30px;
        padding-bottom: 31px;
    }

    @media (max-width: 576px) {
        padding-top: calc(230px - (576px - 100vw) * 0.5);
    }

    @media (max-width: 430px) {
        padding-bottom: 43px;
    }
`
export const PossiblitesHeader = styled.h3`
    color: #415162;
    width: 100%;
    text-align: center;
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    margin-top: 70px;
    margin-bottom: 45px;

    @media (max-width: 1320px) {
        line-height: 35px;
        font-size: 32px;
    }

    @media (max-width: 576px) {
        line-height: 25px;
        font-size: 20px;
        margin-bottom: 25px;
        max-width: 168px;
        margin-left: auto;
        margin-right: auto;
    }
`
export const PossiblitesDiv = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 248.56fr 282.3fr 260.73fr 207.41fr;
    column-gap: 19px;

    @media (max-width: 1320px) {
        grid-template-columns: 333fr 343fr;
        grid-template-rows: 414fr 420fr;
        column-gap: 0px;
        row-gap: 30px;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
    }
`
export const FinanceCard = styled.div`
    position: relative;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);
    padding: 29px 20px 35px 30px;

    @media (max-width: 1320px) {
        margin-right: 31px;
        padding: 37px 11px 10px 19px;
    }

    @media (max-width: 768px) {
        margin-right: unset;
        text-align: center;
        padding: 27px 20px 27px;
    }

    h3 {
        margin-bottom: 18px;
    }
`
export const FinanceImg = styled.img`
    width: 158px;

    @media (max-width: 768px) {
        width: 143px;
    }
`
export const FinanceEffectImg = styled.img`
    position: absolute;
    left: 60px;
    top: 170px;
    z-index: -1;

    @media (max-width: 768px) {
        left: 50%;
        top: unset;
        bottom: -60px;
        transform: translate(-50%, 0px);
    }
`
export const SeparatedColumn = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    @media (max-width: 1320px) {
        row-gap: 30px;
    }

    @media (max-width: 768px) {
        row-gap: 18px;
    }
`
export const EcommerceCard = styled.div`
    display: flex;
    position: relative;
    padding: 21px 24px 17px 17px;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);

    h3 {
        margin-bottom: 5px;
    }

    @media (max-width: 768px) {
        display: block;
        text-align: center;
        padding: 27px 20px 27px;

        h3 {
            margin-bottom: 18px;
        }
    }
    @media (max-width: 430px) {
        display: block;
        text-align: center;
        padding: 27px 20px 27px 34px;

        h3 {
            margin-bottom: 18px;
        }
    }
`
export const EcommerceImg = styled.img`
    width: 85px;

    @media (max-width: 768px) {
        margin-top: 32px;
        width: 108px;
    }
`
export const EcoEffectImg = styled.img`
    position: absolute;
    right: -70px;
    top: -25px;
    z-index: -1;

    @media (max-width: 768px) {
        left: 50%;
        top: unset;
        bottom: -60px;
        transform: translate(-50%, 0px);
    }
`
export const GamingCard = styled.div`
    display: flex;
    padding: 23px 17px;
    position: relative;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);

    @media (max-width: 1320px) {
        padding: 18px 17px;
    }

    h3 {
        margin-bottom: 9px;
    }

    @media (max-width: 768px) {
        display: block;
        text-align: center;
        padding: 27px 20px 27px;

        h3 {
            margin-bottom: 18px;
        }
    }
    @media (max-width: 768px) {
        display: block;
        text-align: center;
        padding: 27px 28px 27px;

        h3 {
            margin-bottom: 18px;
        }
    }
`
export const GamingImg = styled.img`
    width: 108px;
    height: 74px;
    flex-shrink: 0;

    @media (max-width: 768px) {
        margin-top: 42px;
        width: 137px;
        height: auto;
    }
`
export const GamingEffectImg = styled.img`
    position: absolute;
    right: -60px;
    top: -10px;
    z-index: -1;

    @media (max-width: 768px) {
        left: 50%;
        top: unset;
        bottom: -60px;
        transform: translate(-50%, 0px);
    }
`
export const LegalCard = styled.div`
    display: flex;
    padding: 21px 15px 17px 29px;
    position: relative;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);

    h3 {
        margin-bottom: 5px;
    }

    @media (max-width: 768px) {
        display: block;
        text-align: center;
        padding: 27px 20px 27px;

        h3 {
            margin-bottom: 18px;
        }
    }
`
export const LegalImg = styled.img`
    width: 94px;
    height: 86px;
    flex-shrink: 0;

    @media (max-width: 768px) {
        margin-top: 32px;
        width: 125px;
        height: auto;
    }
`
export const LegalEffectImg = styled.img`
    position: absolute;
    left: 155px;
    top: -20px;

    @media (max-width: 768px) {
        left: 50%;
        top: unset;
        bottom: -60px;
        transform: translate(-50%, 0px);
    }
`
export const RealEstateCard = styled.div`
    margin-top: 3px;
    display: flex;
    padding: 23px 15px 20px 29px;
    position: relative;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);

    @media (max-width: 1320px) {
        margin-top: 0px;
    }

    h3 {
        margin-bottom: 9px;
    }

    @media (max-width: 768px) {
        display: block;
        text-align: center;
        padding: 27px 20px 27px;

        h3 {
            margin-bottom: 18px;
        }
    }
`
export const RealEstateImg = styled.img`
    width: 101px;
    flex-shrink: 0;

    @media (max-width: 768px) {
        margin-top: 32px;
        width: 129px;
        height: auto;
    }
`
export const RealEstateEffectImg = styled.img`
    position: absolute;
    top: 20px;
    left: 105px;
    z-index: -1;

    @media (max-width: 1320px) {
        left: 135px;
    }

    @media (max-width: 768px) {
        left: 50%;
        top: unset;
        bottom: -60px;
        transform: translate(-50%, 0px);
    }
`
export const TokenCard = styled.div`
    display: flex;
    padding: 30px 26px 20px 21px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);

    @media (max-width: 1320px) {
        margin-left: 31px;
    }

    h3 {
        margin-bottom: 17px;
    }

    @media (max-width: 768px) {
        margin-left: unset;
        text-align: center;
        padding: 27px 20px 27px;

        h3 {
            margin-bottom: 18px;
        }
    }
`
export const TokenImg = styled.img`
    width: 151px;
`
export const TokenEffectImg = styled.img`
    position: absolute;
    top: 125px;
    left: -30px;
    z-index: -1;

    @media (max-width: 1320px) {
        top: 100px;
        left: 5px;
    }

    @media (max-width: 768px) {
        left: 50%;
        top: unset;
        bottom: -60px;
        transform: translate(-50%, 0px);
    }
`
export const PossibilitiesCardTitle = styled.h3`
    color: #415162;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    margin-top: 0px;

    @media (max-width: 1320px) {
        font-weight: 700;
    }

    @media (max-width: 576px) {
        font-size: 16px;
    }
`
export const PossibilitiesCardDescription = styled.div`
    color: #415162;
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;

    @media (max-width: 1320px) {
        font-size: 16px;
        font-weight: 500;
    }

    @media (max-width: 576px) {
        font-size: 14px;
    }
`

export const FinanceGradient = styled.div`
    position: absolute;
    top: 230px;
    left: -100px;
    border-radius: 50px;
    opacity: 0.56;
    background: linear-gradient(180deg, #fff500 20%, #f6005a 100%);
    width: 150px;
    height: 130px;
    filter: blur(150px);
    z-index: -1;
`
export const EcommerceGradient = styled.div`
    position: absolute;
`
export const GamingGradient = styled.div`
    position: absolute;
`
export const LegalGradient = styled.div`
    position: absolute;
`
export const RealEstateGradient = styled.div`
    position: absolute;
`
export const TokenGradient = styled.div`
    position: absolute;
`
export const StarEffectImg1 = styled.img`
    position: absolute;
    width: 79px;
    bottom: -30px;
    right: 200px;

    @media (max-width: 1320px) {
        width: 53px;
        bottom: 60px;
        right: -60px;
    }

    @media (max-width: 1024px) {
        right: -70px;
        bottom: 40px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`
export const StarEffectImg2 = styled.img`
    position: absolute;
    width: 36px;
    height: 36px;
    top: 200px;
    left: 180px;
    z-index: -1;

    @media (max-width: 1320px) {
        display: none;
    }
`
