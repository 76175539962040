import styled from 'styled-components'

export const ValuesWrapper = styled.div`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @media (max-width: 430px) {
        margin-top: unset;
        /* padding-top: 0px; */
    }
`

export const ValuesHeader = styled.h1`
    color: var(--titlesdd, #415162);
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 47.3px */
    margin-bottom: 51px;
    margin-top: 63px;
    @media (max-width: 1320px) {
        display: flex;
        justify-content: center;
    }
    @media (max-width: 430px) {
        display: flex;
        justify-content: center;
    }
`
export const ValuesContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 51px;
`
export const ValuesColumnDiv1 = styled.div`
    display: flex;
    width: 100%;
    column-gap: 50px;
    padding-right: 23px;
    @media (max-width: 1024px) {
        flex-direction: column;
        row-gap: 50px;
        align-items: center;
        padding-right: unset;
    }
    @media (max-width: 430px) {
        flex-direction: column;
        row-gap: 50px;
        padding-right: unset;
    }
`
export const ValuesColumnDiv2 = styled.div`
    display: flex;
    width: 100%;
    column-gap: 50px;
    @media (max-width: 1024px) {
        flex-direction: column;
        row-gap: 50px;
    }
    @media (max-width: 430px) {
        flex-direction: column;
        row-gap: 50px;
        padding-bottom: 30px;
    }
`
export const ValuesColumnDiv3 = styled.div`
    display: flex;
    width: 100%;
    column-gap: 50px;
    padding-right: 23px;
    @media (max-width: 1024px) {
        flex-direction: column;
        padding-right: unset;
    }
    @media (max-width: 430px) {
        flex-direction: column;
        padding-right: unset;
    }
`

export const ValuesDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: 1024px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media (max-width: 430px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const ValuesDiv2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 48%;
    padding-top: 70px;
    padding-right: 10px;

    @media (max-width: 1024px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: unset;
    }
    @media (max-width: 430px) {
        width: 100%;
        padding-top: unset;
    }
`
export const ValuesDiv4 = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: 1024px) {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
    @media (max-width: 430px) {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
`
export const ValuesDiv3 = styled.div`
    display: flex;
    width: 50%;
    height: auto;
    margin: -15px 0px 0px 65px;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-position-x: center;
    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const ValuesTitle = styled.h2`
    color: var(--TITLE, #2f2f30);
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 46.4px */
    margin-top: unset;
    @media (max-width: 430px) {
        text-align: center;
    }
`
export const ValuesDescription = styled.p`
    color: var(--TITLE, #2f2f30);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin: unset;
    @media (max-width: 430px) {
        text-align: center;
    }
`
export const PartnershipImg = styled.img`
    display: flex;
    width: 420px;
    height: 419px;
    padding: 21px 95px 30px 51px;

    @media (max-width: 1024px) {
        margin-left: auto;
        margin-right: auto;
        padding-top: unset;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 560px) {
        display: flex;
        width: 315px;
        height: 315px;
        padding: unset;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const PartnershipImg2 = styled.img`
    display: none;
    @media (max-width: 430px) {
        display: flex;
        width: 315px;
        height: 315px;
        padding: 36px 63px 38px 21px;
    }
    @media (max-width: 400px) {
        display: flex;
        width: 315px;
        height: 315px;
        padding: unset;
        /* padding: 36px 63px 38px 21px; */
    }
`
export const CommitmentImg = styled.img`
    display: flex;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
`
//effect img

export const StarEffect1 = styled.img`
    position: absolute;
    width: 70px;
    height: auto;
    right: -120px;
    top: -200px;
    @media (max-width: 430px) {
        width: 40px;
        height: auto;
        right: 25px;
        top: 155px;
        z-index: -1;
    }
    @media (max-width: 420px) {
        display: none;
    }
`

export const StarEffect2 = styled.img`
    position: absolute;
    width: 60px;
    height: auto;
    right: -20px;
    top: 670px;
    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const StarEffect3 = styled.img`
    position: absolute;
    width: 50px;
    height: auto;
    left: 220px;
    top: 1410px;
    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const StarEffect4 = styled.img`
    position: absolute;
    width: 79px;
    height: auto;
    left: -180px;
    top: 530px;
    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const CloudEffect1 = styled.img`
    position: absolute;
    width: 400px;
    height: 354px;
    right: -410px;
    top: -170px;
    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const CloudEffect2 = styled.img`
    position: absolute;
    width: 400px;
    height: 354px;
    left: -550px;
    top: 550px;
    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const WhiteGradient = styled.div`
    display: none;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        left: 26px;
        top: -75px;
        width: 90%;
        height: 310px;
        border-radius: 364px;
        opacity: 0.5;
        background: #fff;
        filter: blur(103.25px);
        z-index: -1;
    }
`
export const RedGradient = styled.div`
    display: none;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        top: 220px;
        right: 10px;
        background: linear-gradient(
            180deg,
            #ff00c7 0%,
            rgba(255, 61, 166, 0.84) 100%
        );
        opacity: 0.39;
        width: 96px;
        border-radius: 50%;
        height: 96px;
        filter: blur(60px);
        z-index: 1;
    }
`

export const BlueGradient = styled.div`
    display: none;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        top: 280px;
        left: -20px;
        background: linear-gradient(
            180deg,
            #00ffc2 0%,
            rgba(65, 61, 255, 0.84) 100%
        );
        opacity: 0.5;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        filter: blur(50px);
        z-index: -10;
    }
`
