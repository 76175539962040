import React from 'react'

import { Container } from '../../common'
import { logoImages } from '../../../constants/variables'
import { LogoWrapper, LogoImage, Logoline } from './styled'

const Logos = () => {
    return (
        <Container>
            <Logoline />
            <LogoWrapper>
                {logoImages.map((logoImage) => (
                    <a
                        key={logoImage.content}
                        href={logoImage.link}
                        target="_blank"
                        rel="noreferrer">
                        <LogoImage
                            src={logoImage.path}
                            alt={logoImage.content}
                        />
                    </a>
                ))}
            </LogoWrapper>
        </Container>
    )
}

export default Logos
