import React from 'react'
import {
    PolicyWrapper,
    Wrapper,
    Content,
    ContentSpan,
    Header,
    TitleSpan,
    ContentUl,
    TableContent,
    LinearGradient1,
    LinearGradient2,
    LinearGradient3,
    LinearGradient4,
    LinearGradient5,
    LinearGradient6,
} from './styled'
import { Container } from '../../components/common'
const Cookies = () => {
    return (
        <Container>
            <PolicyWrapper>
                <LinearGradient1 />
                <LinearGradient2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/teamImg/image%2020.png"
                    alt="linearImg"
                />
                <LinearGradient3 />
                <LinearGradient4 />
                <LinearGradient5 />
                <LinearGradient6 />
                <Wrapper>
                    <Header>Cookies Policy</Header>
                    <Content>
                        <ContentSpan>
                            <b>Effective Date:</b> January, 2024
                        </ContentSpan>
                        <ContentSpan>
                            <b>Last Updated:</b> January, 2024
                        </ContentSpan>
                        <ContentSpan>
                            This Cookies Policy describes how Obinex™
                            (“Obinex™”, “we”, “us”, “our”) uses cookies and
                            other similar technology when you access and/or use
                            the Obinex website and any other online locations
                            where this Cookies Policy is posted or linked
                            (collectively, the “Sites”). As regards data
                            protection (LINK TO DATA PROTECTION HERE) Obinex™
                            has safeguards in place for protecting data,
                            adhering to GPDR (LINK TO GPDR HERE) standards
                            applicable in the European Union, since we believe
                            that these are the most protectives ones of user’s
                            interests even outside the EU, complying also with
                            other relevant data protection regulations.
                        </ContentSpan>
                        <ContentSpan>
                            Please note that our Cookies Policy will be updated
                            from time to time.
                        </ContentSpan>
                        <TitleSpan>
                            1.How Obinex uses Cookies and other similar
                            technology
                        </TitleSpan>
                        <ContentSpan>
                            We can place cookies and other similar technology on
                            your device, including mobile device, in accordance
                            with your preferences set on our cookie setting
                            center. Depending on your settings in our cookie
                            consent manager on your mobile device, the following
                            information may be collected through cookies or
                            similar technology: your unique device identifier,
                            mobile device IP address, information about your
                            device’s operating system, mobile carrier and your
                            location information (to the extent permissible
                            under applicable law). 
                        </ContentSpan>
                        <TitleSpan>2.Consent for Use of Cookies</TitleSpan>
                        <ContentSpan>
                            In most cases cookies cannot be deployed without
                            consent. When you first enter our Sites, you will be
                            shown a pop-up cookie notification banner. The
                            banner informs the user that our site uses cookies
                            and similar technologies. You will have the option
                            to “Accept all Cookies” or “Reject Cookies” within
                            the cookie notification banner. When you click
                            “Accept all Cookies”, we deem you to have consented
                            to non-essential cookies unless you have disabled
                            them using your browser settings. An explanation of
                            what we mean by the terms essential and
                            non-essential cookies is set out in section 5 below.
                        </ContentSpan>
                        <ContentSpan>
                            Note: if you have already consented to cookies on
                            our Sites, you can change your preferences at any
                            time by clearing cookies from your browser and
                            re-entering our site. This will cause the pop-up
                            banner to re-display, and you can choose to use the
                            Site(s) without consenting to non-essential cookies.
                        </ContentSpan>
                        <TitleSpan>3.What are cookies?</TitleSpan>
                        <ContentSpan>
                            Cookies are small data files that are placed into
                            user’s device when you visit a website, downloaded
                            to your computer or mobile device when you visit a
                            site and acting as a unique tag to identify your
                            browser, that is, allowing such site to recognize
                            your device. Cookies stores information about the
                            user’s visit, which may include content viewed,
                            language preference, time and duration of each visit
                            and advertisement accessed. Cookies managed by
                            Obinex™ only are called “first party cookies”
                            whereas cookies from third parties are called “third
                            party cookies”.
                        </ContentSpan>
                        <TitleSpan>
                            4.Why do we use cookies and similar technologies? 
                        </TitleSpan>
                        <ContentSpan>
                            Cookies are a useful mechanism permitting you and
                            the website itself to do a lot of different jobs,
                            such as letting you navigate between pages
                            efficiently, remembering your preferences and
                            generally improving the user experience. They can
                            help to ensure that the advertisements you see
                            online are more relevant to you and your interests
                            and enable us to identify your preferences. There
                            are different sets of cookies:
                        </ContentSpan>
                        <TitleSpan>5.Essential/non-essential cookies</TitleSpan>

                        <ContentSpan>
                            <b>Essential cookies:</b> are those used by Obinex™
                            to deliver and maintain on-line services, including
                            providing basic website functionality, remembering
                            your basket content, facilitating transactions and
                            payments, fraud prevention, and maintaining and
                            securing our websites.
                        </ContentSpan>
                        <ContentSpan>
                            Essential cookies used for these purposes are
                            deployed automatically for all users as soon as they
                            enter our websites. If you wish to use our sites you
                            cannot opt out of these cookies as they are an
                            essential and integral feature.
                        </ContentSpan>
                        <ContentSpan>
                            With your consent, we’ll also use what we call
                            ‘non-essential’ cookies to: 
                        </ContentSpan>
                        <ContentUl>
                            <li>
                                Improve the quality of our services and develop
                                new ones.
                            </li>
                            <li>
                                Enable special website features such as widgets
                                or embedded media files. These special features
                                are not essential to the operation of the
                                website but are intended to enhance user
                                experience. They also allow us to develop and
                                test new website features.
                            </li>
                            <li>
                                Enable Obinex to meet its contractual
                                obligations to make payments to third parties
                                when a product is purchased by someone who has
                                visited our website from a site operated by
                                those parties.
                            </li>
                        </ContentUl>
                        <ContentSpan>
                            If you do not want non-essential cookies to be used,
                            you will be able to continue using our Sites and
                            complete transactions without consenting to such
                            cookies, but please be aware that if you do so some
                            website functionalities may be impaired.
                        </ContentSpan>
                        <TitleSpan>6.Session/Persistent cookies</TitleSpan>
                        <ContentSpan>
                            Session cookies make it easier for you to navigate
                            our Sites and expire when you close your browser.
                            Persistent cookies help with personalizing your
                            experience, remembering your preferences, and
                            supporting security features. Additionally,
                            persistent cookies may allow us to bring you
                            advertising both on and off the Sites. Persistent
                            cookies may remain on your device for extended
                            periods of time, and generally may be controlled
                            through your browser settings. In general,
                            persistent cookies will not be retained for more
                            than 18 months. In exceptional cases, persistent
                            cookies may be retained for longer but only if
                            required and following appropriate internal sign
                            off.
                        </ContentSpan>
                        <TitleSpan>
                            7.Does Obinex™ use cookies for marketing and
                            analytics?
                        </TitleSpan>
                        <ContentSpan>
                            Yes, we may use information collected from our
                            cookies to identify user behaviour and to serve
                            content and offers based on your profile, and for
                            the other purposes described below, to the extent
                            legally permissible in certain jurisdictions.
                        </ContentSpan>
                        <ContentSpan>
                            In other cases, we can associate cookie information
                            (including information from cookies placed via our
                            advertisements on third party sites) with an
                            identifiable individual.
                        </ContentSpan>
                        <TitleSpan>
                            8.Do you use any cookies from third party companies?
                        </TitleSpan>
                        <ContentSpan>
                            Some cookies, web beacons and other tracking and
                            storage technologies that we use are from third
                            party companies (third party cookies) to provide us
                            with web analytics and intelligence about our sites
                            which may also be used to provide measurement
                            services and target ads.
                        </ContentSpan>
                        <TitleSpan>
                            9.What types of cookies does Obinex use?
                        </TitleSpan>
                        <ContentSpan>
                            The cookies used on Obinex Sites have been
                            categorized as per the table below. However, it is
                            important to note that not all cookies may be used
                            in all jurisdictions or websites. A list of the
                            categories of cookies used on this website is set
                            out below.
                        </ContentSpan>
                        <TableContent>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Strictly Necessary cookies</td>
                                    <td>
                                        These cookies are necessary for the
                                        website to function and cannot be
                                        switched off in our systems. They are
                                        usually only set in response to actions
                                        made by which amount to a request for
                                        services, such as setting your privacy
                                        preferences, logging in or filling in
                                        forms. You can set your browser to block
                                        or alert you about these cookies, but
                                        some parts of the site will not when
                                        work. These cookies do not store any
                                        personally identifiable data.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Performance cookies</td>
                                    <td>
                                        These cookies allow us to count visits
                                        and traffic sources so we can measure
                                        and improve the performance of our site.
                                        They help us to know which pages are the
                                        most and least popular and see how
                                        visitors move around the site. All
                                        information these cookies collect is
                                        aggregated and therefore anonymous. If
                                        you do not allow these cookies we will
                                        not know when you have visited our site
                                        and will not be able to monitor its
                                        performance.
                                    </td>
                                </tr>
                                <tr>
                                    <td>Functionality cookies</td>
                                    <td>
                                        These cookies enable the website to
                                        provide enhanced functionality and
                                        personalization. They may be set up by
                                        us or by a third-party provider whose
                                        services we have added to our pages. If
                                        you don’t allow these cookies some
                                        services may not function properly.{' '}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Targeting cookies</td>
                                    <td>
                                        Targeting cookies may be set through our
                                        site by our advertising partners. They
                                        can be used by these third parties to
                                        build a profile of your interests based
                                        on the browsing information they collect
                                        from you, which includes uniquely
                                        identifying your browser and terminal
                                        equipment. If you do not allow these
                                        cookies, you will still see basic
                                        advertising on your browser that is
                                        generic and not based on your interests.{' '}
                                    </td>
                                </tr>
                            </tbody>
                        </TableContent>
                        <ContentSpan>
                            You can adjust your cookie's settings at any time
                            from our cookie setting center.
                        </ContentSpan>
                        <br />
                        <TitleSpan>
                            EMPERSTUS GLOBAL PVT. LIMITED (register number
                            15449808),
                            <br /> 45 Kent House, Maidstone, United Kingdom,
                            ME15 6LA.
                        </TitleSpan>
                    </Content>
                </Wrapper>
            </PolicyWrapper>
        </Container>
    )
}

export default Cookies
