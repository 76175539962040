import React from 'react'
import {
    TokenomicHeaderWrapper,
    Title1,
    Title2,
    ContentWrapper,
    ContentDiv1,
    ContentDiv2,
    TokenomicWrapper,
    TokenomiContentWrapper,
    TokenomiContentHeader,
    TokenomiContent,
    TokenomiChartWrapper,
    TokenomiCircleChart,
    TokenomicEffectImg,
    LiquidityDiv,
    LiquidityValue,
    LiquidityTitle,
    LiquidityTokens,
    LiquidityTokensTitle,
    LiquidityTokensValue,
    PrivateDiv,
    PrivateValue,
    PrivateTitle,
    PrivateTokens,
    PrivateTokensTitle,
    PrivateTokensValue,
    MarketingDiv,
    MarketingValue,
    MarketingTitle,
    MarketingTokens,
    MarketingTokensTitle,
    MarketingTokensValue,
    Public1Div,
    Public1Value,
    Public1Title,
    Public1Tokens,
    Public1TokensTitle,
    Public1TokensValue,
    AirdropsDiv,
    AirdropsValue,
    AirdropsTitle,
    AirdropsTokens,
    AirdropsTokensTitle,
    AirdropsTokensValue,
    Public2Div,
    Public2Value,
    Public2Title,
    Public2Tokens,
    Public2TokensTitle,
    Public2TokensValue,
    TeamDiv,
    TeamValue,
    TeamTitle,
    TeamTokens,
    TeamTokensTitle,
    TeamTokensValue,
    AdvisorsDiv,
    AdvisorsValue,
    AdvisorsTitle,
    AdvisorsTokens,
    AdvisorsTokensTitle,
    AdvisorsTokensValue,
    MiddleCircle1,
    MiddleCircle2,
    TokenomicTotalWrapper,
    TokenomicTotal,
    TokenomicTotalValue,
    ElectronEffect1,
    ElectronEffect2,
    StarEffect2,
    ContentUl,
    Div1,
    Div2,
    Div3
} from './styled'

import { TokenomicImg, leftImg, rightImg } from '../../../constants/images'

const Tokenomic = () => {
    return (
        <>
            <TokenomicHeaderWrapper>
                <Title1>Introducing</Title1>
                <Title2>$OBIX</Title2>
                <Title1>Tokens</Title1>
            </TokenomicHeaderWrapper>
            <ContentWrapper>
                <ContentDiv1>
                    <ContentUl>
                        <li>
                            $OBIX token is the native currency of the OBINEX™
                            ecosystem.
                        </li>
                        <li>
                            $OBIX facilitates transactions, product and service
                            usage, and interest generation.
                        </li>
                        <li>
                            As an inflationary token, $OBIX is designed for
                            long-term economic growth.
                        </li>
                        <li>
                            $OBIX operates on the Binance Smart Chain (BSC)
                            blockchain, ensuring Ethereum Virtual Machine (EVM)
                            compatibility.
                        </li>
                    </ContentUl>
                </ContentDiv1>
                <ContentDiv2>
                    <Div1>
                        Our vision is to create a unique financial ecosystem
                        powered by the $OBIX token. Designed to be inflationary
                        yet resilient to crypto market fluctuations, the token
                        ensures users enjoy consistent, long-term returns even
                        in challenging market conditions.
                    </Div1>
                    <Div2>
                        While fundamental features are accessible at no cost,
                        $OBIX unlocks advanced functionalities, providing
                        benefits like reduced transaction fees, exclusive NFT
                        purchases, and active participation in key decisions
                        such as new token listings.
                    </Div2>
                    <Div3>
                        As OBINEX™ grows through new services and partnerships,
                        the $OBIX token will evolve into a more versatile asset
                        with diverse uses and an enhanced value proposition
                        within our ecosystem.
                    </Div3>
                </ContentDiv2>
            </ContentWrapper>
            <TokenomicWrapper>
                <TokenomiContentWrapper>
                    <TokenomiContentHeader>
                        Tokenomics of $OBIX
                    </TokenomiContentHeader>
                    <TokenomiContent>
                        OBINEX's™ tokenomics strategy creates a controlled and
                        balanced inflationary environment, ensuring fair token
                        distribution and fostering widespread user
                        participation. The platform's inflation model ensures
                        stable and sustainable long-term value growth, providing
                        reliable investment opportunities. What sets OBINEX™
                        apart is a unique token burn mechanism activated with
                        each user transaction, reducing the overall token
                        supply. This unlocks a proportional share of the
                        collateral to support and benefit the project. This
                        innovative approach ensures sustained success and stable
                        ecosystem growth.
                    </TokenomiContent>
                    <TokenomiChartWrapper>
                        <ElectronEffect1
                            src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/tokenomix/tokenomixEffectRightImg.png"
                            alt="tokenomicEffectImg"
                        />
                        <ElectronEffect2
                            src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/tokenomix/tokenomixEffectLeftImg.png"
                            alt="tokenomicEffectImg"
                        />
                        <StarEffect2
                            src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                            alt="star-effect"
                        />
                        <TokenomiCircleChart img={TokenomicImg}>
                            <TokenomicEffectImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/tokenomix/tokenomicEffect.png"
                                alt="tokenomicEffectImg"
                            />

                            <LiquidityDiv>
                                <LiquidityValue>15.14%</LiquidityValue>
                                <LiquidityTitle>Liquidity</LiquidityTitle>
                                <LiquidityTokens>
                                    <LiquidityTokensTitle>
                                        tokens
                                    </LiquidityTokensTitle>
                                    <LiquidityTokensValue>
                                        11.894,127
                                    </LiquidityTokensValue>
                                </LiquidityTokens>
                            </LiquidityDiv>
                            <PrivateDiv>
                                <PrivateValue>14.63%</PrivateValue>
                                <PrivateTitle>Private Sale</PrivateTitle>
                                <PrivateTokens>
                                    <PrivateTokensTitle>
                                        tokens
                                    </PrivateTokensTitle>
                                    <PrivateTokensValue>
                                        11.487,965
                                    </PrivateTokensValue>
                                </PrivateTokens>
                            </PrivateDiv>
                            <MarketingDiv>
                                <MarketingValue>9.18%</MarketingValue>
                                <MarketingTitle>Marketing</MarketingTitle>
                                <MarketingTokens>
                                    <MarketingTokensTitle>
                                        tokens
                                    </MarketingTokensTitle>
                                    <MarketingTokensValue>
                                        7.213,246
                                    </MarketingTokensValue>
                                </MarketingTokens>
                            </MarketingDiv>
                            <Public1Div>
                                <Public1Value>21.24%</Public1Value>
                                <Public1Title>Public Sale 1</Public1Title>
                                <Public1Tokens>
                                    <Public1TokensTitle>
                                        tokens
                                    </Public1TokensTitle>
                                    <Public1TokensValue>
                                        16.684,902
                                    </Public1TokensValue>
                                </Public1Tokens>
                            </Public1Div>
                            <AirdropsDiv>
                                <AirdropsValue>1.25%</AirdropsValue>
                                <AirdropsTitle>Airdrops</AirdropsTitle>
                                <AirdropsTokens>
                                    <AirdropsTokensTitle>
                                        tokens
                                    </AirdropsTokensTitle>
                                    <AirdropsTokensValue>
                                        980,187
                                    </AirdropsTokensValue>
                                </AirdropsTokens>
                            </AirdropsDiv>
                            <Public2Div>
                                <Public2Value>33.08%</Public2Value>
                                <Public2Title>Public Sale 2</Public2Title>
                                <Public2Tokens>
                                    <Public2TokensTitle>
                                        tokens
                                    </Public2TokensTitle>
                                    <Public2TokensValue>
                                        25.984,683
                                    </Public2TokensValue>
                                </Public2Tokens>
                            </Public2Div>
                            <TeamDiv>
                                <TeamValue>3.83%</TeamValue>
                                <TeamTitle>Team</TeamTitle>
                                <TeamTokens>
                                    <TeamTokensTitle>tokens</TeamTokensTitle>
                                    <TeamTokensValue>3.010,659</TeamTokensValue>
                                </TeamTokens>
                            </TeamDiv>
                            <AdvisorsDiv>
                                <AdvisorsValue>1.63%</AdvisorsValue>
                                <AdvisorsTitle>
                                    Advisors & Partners
                                </AdvisorsTitle>
                                <AdvisorsTokens>
                                    <AdvisorsTokensTitle>
                                        tokens
                                    </AdvisorsTokensTitle>
                                    <AdvisorsTokensValue>
                                        1.283,443
                                    </AdvisorsTokensValue>
                                </AdvisorsTokens>
                            </AdvisorsDiv>

                            <MiddleCircle1 img={rightImg} alt="Circleleft" />
                            <MiddleCircle2 img={leftImg} alt="Circleright" />
                        </TokenomiCircleChart>
                    </TokenomiChartWrapper>

                    <TokenomicTotalWrapper>
                        <TokenomicTotal>Total Supply:</TokenomicTotal>
                        <TokenomicTotalValue>
                            78,539,211 $OBIX Token
                        </TokenomicTotalValue>
                    </TokenomicTotalWrapper>
                </TokenomiContentWrapper>
            </TokenomicWrapper>
        </>
    )
}

export default Tokenomic
