import styled from 'styled-components'

export const FooterDiv = styled.div`
    display: flex;
    justify-content: center;
    height: 350px;
    width: 100%;
    backdrop-filter: blur(17px);
    gap: 200px;
    @media (max-width: 1430px) {
        padding-left: 78px;
        padding-right: 78px;
    }
    @media (max-width: 1100px) {
        justify-content: space-between;
        padding-left: 0px;
        padding-right: 81px;
    }
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        gap: 100px;
    }
    @media (max-width: 430px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        gap: 60px;
    }
`
export const LogoWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10%;
    padding-left: 78px;
    @media (max-width: 900px) {
        width: 100%;
        padding-bottom: 0%;
    }
    @media (max-width: 480px) {
        padding-left: 15px;
    }
`
export const LogoSpan = styled.div`
    display: flex;

    align-items: center;
    gap: 2px;
    margin-bottom: 35px;
    cursor: pointer;
`
export const SocialSpan = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    & span {
        color: #4a4a4e;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 254.545% */
    }
`

export const FooterLinkWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    display: flex;
    gap: 112px;
    flex-wrap: wrap;
    @media (max-width: 1124px) {
        padding-right: 81px;
    }
    @media (max-width: 900px) {
        width: 100%;
        padding-left: 78px;
        flex-direction: row;
        align-items: flex-start;
        padding-bottom: 100px;
        flex-wrap: nowrap;
    }
    @media (max-width: 480px) {
        gap: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }
    @media (max-width: 370px) {
        width: 100%;
        padding-left: 15px;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 100px;
        flex-wrap: nowrap;
    }
`
export const LinksWrapper = styled.div`
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    gap: 13px;
    color: #0a142f;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    @media (max-width: 900px) {
        font-size: 14px;
    }
`
export const FooterLink = styled.span`
    color: #4a4a4e;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    width: 188px;
`
export const FooterLogoLetter = styled.img`
    width: 133px;
    height: 53.38px;
    flex-shrink: 0;
`
export const FooterLinksWrapper = styled.div`
    margin-top: 0px;
`
export const FooterLinksHeader = styled.span`
    color: #1c1725;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    @media (max-width: 900px) {
        font-size: 16px;
    }
`

export const SocialWrapper = styled.div`
    display: flex;
    gap: 22px;
    flex-wrap: wrap;
    @media (max-width: 900px) {
        flex-wrap: nowrap;
    }
`
export const SocialItem = styled.a`
    width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    aspect-ratio: 1/1;
`
export const FooterLine = styled.div`
    background: ${(props) => props.background};
    width: 100%;
    max-width: 937px;
    height: 1px;
    margin: auto auto 70px auto;
    @media (max-width: 1024px) {
        width: 65%;
    }
    @media (max-width: 1024px) {
        margin: 40px auto 70px auto;
        width: 85%;
    }
`
