import styled from 'styled-components'

export const RoadmapHeader = styled.h1`
    color: var(--titlesdd, #415162);
    text-align: center;
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin-top: 51px;
    margin-bottom: 51px;

    @media (max-width: 430px) {
        color: var(--titles, #0f172a);
        font-family: Gotham;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 22px */
        margin-bottom: 40px;
    }
`
export const RoadmapWrapper = styled.div`
    border-radius: 40px;
    background: #ffffff66;
    padding: 43px 11px 47px 24px;
    margin-left: -6px;
    margin-right: -6px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;

    @media (max-width: 1620px) {
        padding: 35px 26px;
        max-width: 882px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    @media (max-width: 950px) {
        max-width: 400px;

        & > div {
            width: 100%;
            min-width: unset;
            min-height: 171px;
        }
    }

    @media (max-width: 430px) {
        margin-left: unset;
        margin-right: unset;
        padding: 27px calc(59px - (430px - 100vw) / 2);
    }

    & > div:nth-child(1) {
        order: 1;
        margin-left: 6px;

        @media (max-width: 1620px) {
            margin-left: unset;
        }

        @media (max-width: 950px) {
            width: 65%;
        }
    }
    & > div:nth-child(2) {
        order: 2;
        min-width: 281px;

        @media (max-width: 950px) {
            min-width: unset;
        }
    }
    & > div:nth-child(3) {
        order: 3;
        min-width: 285px;

        @media (max-width: 950px) {
            min-width: unset;
        }
    }
    & > div:nth-child(4) {
        order: 4;
        min-width: 283px;

        @media (max-width: 1620px) {
            order: 5;
            margin-right: 100px;
        }

        @media (max-width: 950px) {
            order: 4;
            margin-right: unset;
            min-width: unset;
        }
    }
    & > div:nth-child(5) {
        order: 8;
        min-width: 285px;

        @media (max-width: 1620px) {
            order: 4;
            margin-left: 100px;
        }

        @media (max-width: 950px) {
            order: 5;
            margin-left: unset;
            min-width: unset;
        }
    }
    & > div:nth-child(6) {
        order: 7;

        @media (max-width: 1620px) {
            order: 6;
        }

        @media (max-width: 950px) {
            width: 65%;
        }
    }
    & > div:nth-child(7) {
        order: 6;
        min-width: 272px;

        @media (max-width: 1620px) {
            order: 7;
        }

        @media (max-width: 950px) {
            min-width: unset;
        }
    }
    & > div:nth-child(8) {
        order: 5;
        min-width: 300px;

        @media (max-width: 1620px) {
            order: 8;
        }

        @media (max-width: 950px) {
            min-width: unset;
        }
    }
    & > div:nth-child(9) {
        order: 9;
        min-width: 280px;

        @media (max-width: 1620px) {
            order: 10;
        }

        @media (max-width: 950px) {
            order: 9;
            min-width: unset;
        }
    }
    & > div:nth-child(10) {
        order: 10;
        min-width: 325px;

        @media (max-width: 1620px) {
            order: 9;
        }

        @media (max-width: 950px) {
            order: 10;
            min-width: unset;
        }
    }
`
export const RoadmapYear = styled.div`
    position: relative;
    padding: 21px;
    border-radius: 16px;
    background-color: #238def;
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const RoadmapItem = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 8px;
    border-radius: 24px;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    background: #3a3347;
    backdrop-filter: blur(36px);
    position: relative;
`
export const RoadmapItemTitle = styled.div`
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`
export const RoadmapItemRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-family: inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    white-space: nowrap;
`
export const RoadmapItemIcon = styled.img`
    width: 20px;
    height: 20px;
`

export const ArrowImage = styled.img`
    position: absolute;
    width: 40px;
    height: 40px;
    display: block;

    &.up {
        left: -28px;
    }

    &.right {
        top: 0px;
        right: -45px;
    }

    &.down {
        bottom: -45px;
        left: 0px;
        transform: rotate(90deg);
    }

    &.down-right {
        left: 50%;
        bottom: -45px;
        transform: rotate(90deg);
    }

    &.down-left {
        right: 50%;
        bottom: -45px;
        transform: rotate(90deg);
    }

    &.down-center {
        left: 50%;
        bottom: -45px;
        transform: rotate(90deg) translateY(50%);
    }

    &.left {
        top: 0px;
        left: -45px;
        transform: rotate(180deg);
    }
`
