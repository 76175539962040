import React from 'react'
import { useLocation } from 'react-router-dom'
import Intro from '../../components/Home/Intro'
import Possiblites from '../../components/Home/Possibilites'
import Roadmap from '../../components/Home/Roadmap'
import People from '../../components/Home/People'
import Logos from '../../components/Home/Logos'
import Values from '../../components/Home/Values'
import Newsletter from '../../components/Home/Newsletter'
import Tokenomic from '../../components/Home/Tokenomic'

const Home = () => {
    const location = useLocation()

    return (
        <>
            <Intro />
            <Possiblites selectedRoute={location.state?.id} />
            <Values selectedRoute={location.state?.id} />
            <Roadmap selectedRoute={location.state?.id} />
            <Tokenomic selectedRoute={location.state?.id} />
            <Logos />
            <Newsletter />
            <People
                showMore
                title="The Team Behind Obinex"
                selectedRoute={location.state?.id}
            />
        </>
    )
}

export default Home
