import React from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Page, Background1, Background2 } from '../../components/common'

const Layout = ({ children }) => {
    const location = useLocation()

    return (
        <Page>
            <Background1 />
            {!['/privacy', '/terms', '/cookies'].includes(
                location.pathname
            ) && <Background2 />}
            <Header />
            {children}
            <Footer />
        </Page>
    )
}

export default Layout
