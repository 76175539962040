import { useRef, useEffect } from 'react'
import { Container, CenteredRow } from '../../common'

import {
    PossiblitesWrapper,
    PossiblitesHeader,
    PossiblitesDiv,
    FinanceCard,
    FinanceImg,
    FinanceEffectImg,
    PossibilitiesCardTitle,
    PossibilitiesCardDescription,
    SeparatedColumn,
    EcommerceCard,
    EcommerceImg,
    GamingCard,
    GamingImg,
    GamingEffectImg,
    LegalCard,
    LegalImg,
    LegalEffectImg,
    RealEstateCard,
    RealEstateImg,
    RealEstateEffectImg,
    TokenCard,
    TokenImg,
    TokenEffectImg,
    FinanceGradient,
    EcommerceGradient,
    GamingGradient,
    LegalGradient,
    RealEstateGradient,
    TokenGradient,
    EcoEffectImg,
    StarEffectImg1,
    StarEffectImg2,
} from './styled'

const Possiblites = ({ selectedRoute }) => {
    const containerRef = useRef(null)

    useEffect(() => {
        if (selectedRoute === 'ecosystem') {
            window.scrollTo({
                top:
                    containerRef.current.getBoundingClientRect().top +
                    window.scrollY -
                    100,
                behavior: 'smooth',
            })
        }
    }, [selectedRoute])

    return (
        <Container>
            <PossiblitesWrapper>
                <StarEffectImg1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star2.png"
                    alt="starEffect"
                />
                <StarEffectImg2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star4.png"
                    alt="starEffect"
                />
                <PossiblitesHeader ref={containerRef}>
                    Convenience and Profitability
                </PossiblitesHeader>
                <PossiblitesDiv>
                    <FinanceCard>
                        <PossibilitiesCardTitle>Finance</PossibilitiesCardTitle>
                        <PossibilitiesCardDescription>
                            Experience the dynamism, variety, and profitability
                            of a DEX platform with Obinex. Discover crypto
                            investment opportunities and convenient solutions
                            for daily activities like bill payments and service
                            hiring.
                        </PossibilitiesCardDescription>
                        <CenteredRow>
                            <FinanceImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/Finance.png"
                                alt="traders"
                            />
                            <FinanceEffectImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/financeEffect.png"
                                alt="finaceEffectImg"
                            />
                            <FinanceGradient />
                        </CenteredRow>
                    </FinanceCard>
                    <SeparatedColumn>
                        <EcommerceCard>
                            <div>
                                <PossibilitiesCardTitle>
                                    e-Commerce
                                </PossibilitiesCardTitle>
                                <PossibilitiesCardDescription>
                                    Enlarge the reach of your business by
                                    offering flexibility, safety, and
                                    convenience to your customers with crypto as
                                    a payment method with the coolest UX.
                                </PossibilitiesCardDescription>
                            </div>
                            <EcommerceImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/ecommerce2.png"
                                alt="e-commerce"
                            />
                            <EcoEffectImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/ecoEffect.png"
                                alt="ecoEffect"
                            />
                            <EcommerceGradient />
                        </EcommerceCard>
                        <GamingCard>
                            <div>
                                <PossibilitiesCardTitle>
                                    Gaming
                                </PossibilitiesCardTitle>
                                <PossibilitiesCardDescription>
                                    Enjoy a revolutionary gaming experience that
                                    empowers users and content creators with
                                    unique rewards and features.
                                </PossibilitiesCardDescription>
                            </div>
                            <GamingImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/gaming.png"
                                alt="game"
                            />
                            <GamingEffectImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/gamingEffect.png"
                                alt="gamingEffectimg"
                            />
                            <GamingGradient />
                        </GamingCard>
                    </SeparatedColumn>
                    <SeparatedColumn>
                        <LegalCard>
                            <div>
                                <PossibilitiesCardTitle>
                                    Legal
                                </PossibilitiesCardTitle>
                                <PossibilitiesCardDescription>
                                    Be at the forefront of shaping the
                                    regulatory landscape for cryptocurrencies
                                    and DeFi with the best governance and
                                    compliance practices.
                                </PossibilitiesCardDescription>
                            </div>
                            <LegalImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/legal2.png"
                                alt="legal"
                            />
                            <LegalEffectImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/legalEffect.png"
                                alt="legalEffectImg"
                            />
                            <LegalGradient />
                        </LegalCard>
                        <RealEstateCard>
                            <div>
                                <PossibilitiesCardTitle>
                                    Real Estate
                                </PossibilitiesCardTitle>
                                <PossibilitiesCardDescription>
                                    Buy, sell, rent, or secure a loan or
                                    mortgage, conveniently and easily in the
                                    Obinex platform.
                                </PossibilitiesCardDescription>
                            </div>
                            <RealEstateImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/RealEstate.png"
                                alt="estate"
                            />
                            <RealEstateEffectImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/realestateEffect.png"
                                alt="realEstateEffectImg"
                            />
                            <RealEstateGradient />
                        </RealEstateCard>
                    </SeparatedColumn>
                    <TokenCard>
                        <div>
                            <PossibilitiesCardTitle>
                                $OBIX Token
                            </PossibilitiesCardTitle>
                            <PossibilitiesCardDescription>
                                Access Obinex ecosystem's benefits and
                                functionalities seamlessly with Unity Platform,
                                powered by a single ERC-20 token for stability
                                and fluid operations.
                            </PossibilitiesCardDescription>
                        </div>
                        <CenteredRow>
                            <TokenImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/Token.png"
                                alt="token logo"
                            />
                            <TokenEffectImg
                                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/possiblites/tokenEffect.png"
                                alt="tokenEffectImg"
                            />
                            <TokenGradient />
                        </CenteredRow>
                    </TokenCard>
                </PossiblitesDiv>
            </PossiblitesWrapper>
        </Container>
    )
}

export default Possiblites
