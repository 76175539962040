import styled from 'styled-components'

export const VaulesHeader = styled.h3`
    color: #415162;
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 52.8px */
    text-align: center;

    @media (max-width: 1024px) {
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 49px;
    }
    @media (max-width: 430px) {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 33px;
    }
`
export const ValuesWrapper = styled.div`
    position: relative;
`
export const StarEffect1 = styled.img`
    position: absolute;
    width: 57px;
    top: 25px;
    left: 274px;

    @media (max-width: 1024px) {
        position: absolute;
        width: 42px;
        top: -70px;
        left: calc(127px - (1024px - 100vw) * 0.5);
    }

    @media (max-width: 768px) {
        position: absolute;
        width: 29px;
        top: -30px;
        left: calc(127px - (768px - 100vw) * 0.5);
    }

    @media (max-width: 576px) {
        left: calc(127px - (576px - 100vw) * 0.5);
    }
`
export const StarEffect2 = styled.img`
    display: none;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        width: 29px;
        height: 29px;
        top: 200px;
        right: 50px;
    }
`
export const ValuesContent = styled.div`
    display: flex;
    width: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
    @media (max-width: 800px) {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(4, 1fr);
        padding-left: 20px;
        padding-right: 20px;
        column-gap: 30px;
        row-gap: 30px;
        & > div:nth-child(1) {
            grid-area: div1;
        }
        & > div:nth-child(2) {
            grid-area: div2;
        }
        & > div:nth-child(3) {
            grid-area: div3;
        }
        grid-template-areas:
            '. div2 div2 .'
            'div1 div1 div3 div3';
    }
    @media (max-width: 576px) {
        padding-left: 13px;
        padding-right: 13px;
        row-gap: 8px;
        column-gap: 22px;
    }
    @media (max-width: 420px) {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: 1fr;
        grid-template-areas: 'div2' 'div1' 'div3';
        row-gap: 40px;
    }
`
export const SecureDiv = styled.div`
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(14, 135, 248, 0.21);
    box-shadow: -10px 30px 71.5px 10px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);
    height: 324px;
    width: 399px;
    @media (max-width: 1024px) {
        width: 306px;
        height: 315px;
    }
    @media (max-width: 800px) {
        width: 100%;
        height: 100%;
    }
`
export const SecureImg = styled.img`
    position: absolute;
    bottom: 25px;
    left: 45%;
    transform: translate(-50%, 60%);

    @media (max-width: 1024px) {
        width: 98px;
        height: 100px;
        transform: translate(-50%, 50%);
    }

    @media (max-width: 800px) {
        width: 36%;
        height: auto;
    }
    @media (max-width: 465px) {
        width: 64px;
        height: 70px;
        transform: translate(-50%, 75%);
    }
    @media (max-width: 430px) {
        width: 64px;
        height: 70px;
        transform: translate(-50%, 70%);
    }
    @media (max-width: 420px) {
        width: 26%;
        height: auto;
        transform: translate(-40%, 50%);
    }
    @media (max-width: 340px) {
        width: 26%;
        height: auto;
        transform: translate(-35%, 65%);
    }
`
export const SecureWrapper = styled.div`
    position: relative;
    text-align: center;
    padding: 28px 88px 0px 35px;
    @media (max-width: 1024px) {
        padding-top: 24px;
        padding-left: 22px;
        padding-right: 35px;
    }
    @media (max-width: 800px) {
        padding: 28px 35px 35px;
    }
    @media (max-width: 576px) {
        padding: 25px 10px 0px 10px;
    }
    @media (max-width: 480px) {
        padding: 14px 10px 0px 10px;
    }
    @media (max-width: 430px) {
        padding: 14px 10px 0px 10px;
    }
`
export const TransparentWrapper = styled.div`
    text-align: center;
    padding: 47px;
    @media (max-width: 1024px) {
        padding-top: 37px;
        padding-left: 37px;
        padding-right: 35px;
        gap: 7px;
    }
    @media (max-width: 800px) {
        padding: 54px 35px 0px 35px;
    }

    @media (max-width: 576px) {
        padding: 25px 30px 0px;
    }

    @media (max-width: 480px) {
        padding: 24px 10px 0px 10px;
    }
`
export const TailoredWrapper = styled.div`
    text-align: center;
    padding: 28px 33px 0px 55px;
    @media (max-width: 1024px) {
        padding-top: 24px;
        padding-left: 31.21px;
        padding-right: 20.57px;
    }
    @media (max-width: 800px) {
        padding: 28px 35px 35px;
    }
    @media (max-width: 576px) {
        padding: 25px 10px 0px 10px;
    }
    @media (max-width: 480px) {
        padding: 14px 10px 0px 10px;
    }
    @media (max-width: 430px) {
        padding: 14px 10px 0px 10px;
    }
`
export const TransparentDiv = styled.div`
    position: relative;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -10px 30px 71.5px 10px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);
    height: 428px;
    width: 399px;
    margin-left: -38px;
    margin-right: -38px;
    z-index: 2;
    @media (max-width: 1024px) {
        height: 373px;
        width: 307px;
        margin-left: -37px;
        margin-right: -24px;
    }
    @media (max-width: 800px) {
        height: 303px;
        margin: 0%;
        width: 100%;
    }
    @media (max-width: 576px) {
        height: 47vw;
    }
    @media (max-width: 420px) {
        height: 57vw;
    }
`
export const TransparentImg = styled.img`
    position: absolute;
    width: 385px;
    height: 385px;
    bottom: 50px;
    left: 45%;
    transform: translate(-50%, 55%);
    @media (max-width: 1024px) {
        width: 279px;
        height: 279px;
        bottom: 10px;
        left: 45%;
        transform: translate(-50%, 55%);
    }
    @media (max-width: 800px) {
        width: 64%;
        height: auto;
    }
    @media (max-width: 430px) {
        bottom: 0px;
        height: auto;
    }
    @media (max-width: 420px) {
        width: 40%;
        height: auto;
        transform: translate(-40%, 45%);
    }
`
export const TailoredDiv = styled.div`
    position: relative;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 50, 247, 0.22);
    box-shadow: -10px 30px 71.5px 10px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);
    height: 324px;
    width: 399px;
    @media (max-width: 1024px) {
        height: 315px;
        width: 306px;
    }
    @media (max-width: 800px) {
        width: 100%;
        height: 100%;
    }
`
export const TailoredImg = styled.img`
    position: absolute;
    bottom: 15px;
    left: 55%;
    transform: translate(-50%, 50%);

    @media (max-width: 1024px) {
        width: 141px;
        height: 115px;
        transform: translate(-50%, 50%);
        bottom: 20px;
        left: 50%;
    }

    @media (max-width: 800px) {
        width: 50%;
        height: auto;
    }
    @media (max-width: 465px) {
        width: 106px;
        height: 87px;
        transform: translate(-50%, 75%);
    }
    @media (max-width: 430px) {
        width: 106px;
        height: 87px;
        transform: translate(-50%, 75%);
    }
    @media (max-width: 420px) {
        width: 40%;
        height: auto;
    }
`
export const ValuesTitle = styled.h2`
    color: #415162;
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 40px */
    left: 0;
    letter-spacing: 0;
    white-space: nowrap;
    @media (max-width: 1024px) {
        font-size: 24px;
        font-weight: 500;
        line-height: 0%;
        padding: 11px;
    }
    @media (max-width: 492px) {
        font-size: 16px;
        font-weight: 500;
        line-height: 0%;
    }
`
export const ValuesDescription = styled.span`
    color: #415162;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0;
    @media (max-width: 1024px) {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
    }
    @media (max-width: 530px) {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }
`
export const CloudEffect1 = styled.img`
    position: absolute;
    top: -80px;
    left: -280px;

    @media (max-width: 1024px) {
        display: none;
    }

    @media (max-width: 768px) {
        display: block;
        width: 108px;
        height: 96px;
        top: -110px;
        left: calc(102px - (768px - 100vw) * 0.08);
    }

    @media (max-width: 576px) {
        left: calc(96px - (576px - 100vw) * 0.5);
    }

    @media (max-width: 430px) {
        top: -120px;
    }
`
export const CloudEffect2 = styled.img`
    position: absolute;
    width: 574px;
    height: 509px;
    top: -210px;
    right: -550px;
    @media (max-width: 430px) {
        width: 176px;
        height: 157px;
        top: 500px;
        right: 0px;
        transform: rotateY(180deg);
    }
    @media (max-width: 420px) {
        display: none;
    }
`
