import styled from 'styled-components'

export const Logo = styled.img`
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    cursor: pointer;
`
export const ItemsCenteredColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const Container = styled.div`
    width: 100%;
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
`
export const LitepaperContainer = styled.div`
    width: 100%;
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 69px;
    padding-right: 14px;

    @media (max-width: 1280px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (max-width: 430px) {
        padding-left: 14px;
        padding-right: 14px;
    }
`
export const FooterContainer = styled.div`
    width: 100%;
    padding: 0;
`
export const FlexRightedDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

export const ItemCenteredColum = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const FooterLogo = styled.img`
    width: 44px;
    height: 43px;
    flex-shrink: 0;
`
export const SocialWrapper = styled.div`
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        gap: 15px;
    }
    @media (max-width: 768px) {
        justify-content: center;
        gap: 20px;
    }
    @media (max-width: 430px) {
        gap: 9px;
    }
`
export const SocialItem = styled.a`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 1/1;
    stroke-width: 2px;
    stroke: var(--Dark, #0a142f);

    @media (max-width: 1024px) {
        width: 28px;
        height: 28px;
    }

    @media (max-width: 450px) {
        width: 18px;
        height: 18px;
    }
    @media (max-width: 430px) {
        & img {
            width: 5px;
            height: 5px;
            text-align: center;
        }
    }

    & img {
        width: 40%;
        height: 40%;
        text-align: center;
    }
`
export const Page = styled.div`
    min-height: 100vh;
    overflow: hidden;
    position: relative;
`

export const Background1 = styled.div`
    position: absolute;
    width: 100%;
    height: 60%;
    min-height: 2300px;
    background: linear-gradient(
        190deg,
        rgba(183, 191, 252, 0.7) -19.32%,
        rgba(245, 245, 247, 0) 93.29%
    );
    z-index: -2;

    @media (max-width: 430px) {
        height: 70%;
        background: linear-gradient(
            190deg,
            rgba(183, 191, 252, 0.7) 36.32%,
            rgba(245, 245, 247, 0) 93.29%
        );
    }
`

export const Background2 = styled.div`
    position: absolute;
    width: 100%;
    height: 60%;
    bottom: 0px;
    background: linear-gradient(
        7deg,
        rgba(197, 202, 252, 0.8) 8.95%,
        rgba(255, 255, 255, 0) 91.31%,
        rgba(255, 255, 255, 0) 91.31%
    );
    z-index: -2;
`

export const CenteredRow = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
`

export const CheckboxWrapper = styled.label`
    display: flex;
    column-gap: 2px;
    flex-wrap: wrap;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    color: #252525;
    font-family: Inter;
    font-size: 9px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
    white-space: nowrap;
    @media (max-width: 576px) {
        display: block;
        text-wrap: wrap;
    }
    @media (max-width: 431px) {
        font-size: 11px;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    span {
        position: absolute;
        top: 2px;
        left: 0;
        height: 14px;
        width: 14px;
        border-radius: 3px;
        background-color: #e8e9fb;
        border: #d2d3e3;
    }

    input:checked ~ span {
    }

    span:after {
        content: '';
        position: absolute;
        display: none;
    }

    input:checked ~ span:after {
        display: block;
    }

    span:after {
        left: 4px;
        top: 2px;
        width: 3px;
        height: 6px;
        border: solid #b6b7c4;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }

    p {
        margin-top: 0px;
        margin-bottom: 0px;
    }
`
