import styled from 'styled-components'

export const PeopleImage = styled.img`
    width: 215px;
    height: 215px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 16px 31px 0px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    background: var(--frame, #f1f1f1);
`

export const PeopleContent = styled.div`
    padding: 24px 8px;
    display: flex;
    column-gap: 5px;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 768px) {
        width: 216px;
    }
`
export const LinkedinIconWrapper = styled.div`
    margin-top: 6px;
`

export const PeopleTitleWrapper = styled.div`
    flex-shrink: 1;
`

export const PeoplePosition = styled.div`
    color: #929292;
    white-space: nowrap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
`

export const PeopleName = styled.div`
    color: var(--titles, #0f172a);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 160% */
`
