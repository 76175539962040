import React from 'react'
import {
    PartnerHeader,
    PartnerItemWarpper,
    PartnerItem,
    PartnerLogo,
    PartnerContent,
} from './styled'
import { partnersData } from '../../../constants/variables'

const Partners = () => {
    return (
        <>
            <PartnerHeader>Our Partners</PartnerHeader>
            <PartnerItemWarpper>
                {partnersData.map((partner, index) => (
                    <PartnerItem key={index}>
                        <PartnerLogo src={partner.img} />
                        <PartnerContent>{partner.content}</PartnerContent>
                    </PartnerItem>
                ))}
            </PartnerItemWarpper>
        </>
    )
}

export default Partners
