import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FooterLink, LinksWrapper } from './styled'

const FooterLinks = ({ links }) => {
    const navigate = useNavigate()
    return (
        <LinksWrapper>
            {links.map((link) => (
                <FooterLink
                    key={link.content}
                    onClick={() => navigate(link.path)}>
                    {link.content}
                </FooterLink>
            ))}
        </LinksWrapper>
    )
}

export default FooterLinks
