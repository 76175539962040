import React from 'react'

import { YearCardDiv, ArrowImage } from './styled'

const YearCard = ({ year }) => {
    return (
        <>
            <YearCardDiv>
                {year === 2025 && (
                    <ArrowImage
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/downArrow.png"
                        alt="down"
                        className='arrow-down year'
                    />
                )}
                {year}
            </YearCardDiv>
        </>
    )
}

export default YearCard
