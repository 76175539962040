import styled from 'styled-components'

export const IntroContent = styled.div`
    display: flex;
    position: relative;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`
export const IntroGradient1 = styled.div`
    position: absolute;
    top: 30px;
    left: -150px;
    border-radius: 318px;
    opacity: 0.59;
    background: linear-gradient(
        180deg,
        #fff500 0%,
        rgba(255, 247, 61, 0.84) 100%
    );
    width: 318px;
    height: 318px;
    filter: blur(150px);
    z-index: -1;
    @media (max-width: 430px) {
        width: 100px;
        height: 100px;
        filter: blur(80px);
        top: 30px;
        left: 10px;
    }
`
export const IntroGradient2 = styled.div`
    position: absolute;
    left: -200px;
    bottom: -250px;
    border-radius: 440px;
    opacity: 0.4;
    background: linear-gradient(
        180deg,
        #00ffc2 0%,
        rgba(65, 61, 255, 0.84) 100%
    );
    width: 440px;
    height: 440px;
    filter: blur(150px);
    z-index: -1;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const IntroGradient3 = styled.div`
    position: absolute;
    right: -60px;
    bottom: 0px;
    background: linear-gradient(
        180deg,
        #ff00c7 0%,
        rgba(255, 61, 166, 0.84) 100%
    );
    opacity: 0.59;
    width: 219px;
    height: 219px;
    filter: blur(150px);
    z-index: -1;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const IntroGradient4 = styled.div`
    position: absolute;
    top: 90px;
    right: -300px;
    background: linear-gradient(
        180deg,
        #0038ff 0%,
        rgba(61, 255, 243, 0.84) 100%
    );
    opacity: 0.59;
    width: 219px;
    height: 219px;
    filter: blur(150px);
    z-index: -1;
    @media (max-width: 1024px) {
        display: none;
    }
`

export const IntroGradient5 = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: block;
        position: absolute;
        right: 150px;
        bottom: 100px;
        background: linear-gradient(
            180deg,
            #ff00c7 0%,
            rgba(255, 61, 166, 0.84) 100%
        );
        opacity: 0.9;
        width: 100px;
        height: 100px;
        filter: blur(50px);
        z-index: -10;
    }
    @media (max-width: 768px) {
        display: none;
    }
`
export const IntroGradient6 = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: block;
        position: absolute;
        right: -10px;
        bottom: 520px;
        border-radius: 440px;
        opacity: 0.4;
        background: linear-gradient(
            180deg,
            #00ffc2 0%,
            rgba(65, 61, 255, 0.84) 100%
        );
        width: 240px;
        height: 240px;
        filter: blur(40px);
        z-index: -10;
    }
`

export const IntroTitleWrapper = styled.div`
    position: relative;
    width: 50%;
    padding-top: 120px;
    padding-left: 75px;
    z-index: 1;
    @media (max-width: 1024px) {
        width: 60%;
        padding-left: 106px;
        z-index: 2;
    }
    @media (max-width: 986px) {
        padding-top: 100px;
    }

    @media (max-width: 890px) {
        padding-top: 60px;
    }

    @media (max-width: 822px) {
        padding-left: 70px;
    }

    @media (max-width: 768px) {
        width: 100%;
        padding-top: 20px;
        padding-left: 0px;
        text-align: center;
    }
    @media (max-width: 430px) {
        padding-top: 28px;
    }
`
export const IntroTitleGradient = styled.div`
    position: absolute;
    border-radius: 907px;
    opacity: 0.5;
    background: #fff;
    filter: blur(103.25px);
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0px;
    left: 0px;
`
export const IntroTitle1 = styled.h3`
    color: var(--Blue-Gray-900, #0f172a);
    font-family: Inter;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: 70px;
    margin: unset;

    @media (max-width: 1024px) {
        font-size: 51px;
        line-height: 50px;
    }

    @media (max-width: 986px) {
        font-size: 45px;
        line-height: 50px;
    }
    @media (max-width: 430px) {
        font-size: 32px;
        line-height: 32px;
    }
`
export const IntroTitle2 = styled.h3`
    color: var(--Blue-Gray-900, #0f172a);
    font-family: Inter;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: 70px;
    margin: unset;

    @media (max-width: 1024px) {
        font-size: 51px;
        line-height: 50px;
    }

    @media (max-width: 986px) {
        font-size: 45px;
        line-height: 50px;
    }
    @media (max-width: 430px) {
        font-size: 43px;
        line-height: 32px;
    }
`
export const IntroMainTitle = styled.h3`
    background: linear-gradient(90deg, #ff61f9 -0.55%, #ff00f5 57.66%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Inter;
    font-size: 128px;
    font-style: normal;
    font-weight: 900;
    line-height: 105px;
    margin-top: 5px;
    margin-bottom: 5px;

    @media (max-width: 1024px) {
        font-size: 120px;
        line-height: 95px;
    }

    @media (max-width: 986px) {
        font-size: 80px;
        line-height: 70px;
    }
    @media (max-width: 430px) {
        font-size: 66px;
        line-height: 45px;
    }
`
export const IntroParagraph = styled.p`
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    max-width: 80%;
    margin-top: 15px;
    margin-bottom: 14px;

    @media (max-width: 1024px) {
        font-size: 16px;
    }

    @media (max-width: 768px) {
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    @media (max-width: 430px) {
        max-width: 45%;
        font-size: 12px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
`
export const IntroImageWrapper = styled.div`
    position: relative;
    width: 50%;
    aspect-ratio: 942/1350;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position-x: center;
    z-index: 1;

    @media (max-width: 1024px) {
        background-size: 76% auto;
        background-position-x: left;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 100vw;
        background-size: 50% auto;
        background-position-x: center;
    }

    @media (max-width: 576px) {
        background-size: 60% auto;
    }
`
export const IntroStarEffect1 = styled.img`
    position: absolute;
    width: 73px;
    height: auto;
    left: -80px;
    top: 60px;

    @media (max-width: 1260px) {
        width: calc(70 / 630 * 50vw);
        left: calc(-110 / 630 * 50vw);
    }
    @media (max-width: 1024px) {
        width: 100px;
        top: 30px;
        left: calc(-150 / 630 * 50vw);
    }

    @media (max-width: 768px) {
        right: 15%;
        width: 40px;
        height: 40px;
        left: unset;
    }
`
export const IntroStarEffect2 = styled.img`
    position: absolute;
    width: 140px;
    height: auto;
    left: -50px;
    top: 35%;

    @media (max-width: 1260px) {
        width: calc(100 / 630 * 50vw);
        left: calc(-50 / 630 * 50vw);
    }
    @media (max-width: 1024px) {
        width: 130px;
        top: 200px;
        left: calc(-190 / 630 * 50vw);
        z-index: -1;
    }

    @media (max-width: 768px) {
        left: 10%;
        width: 70px;
        top: 35%;
    }
`
export const IntroStarEffect3 = styled.img`
    display: none;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        width: 30px;
        transform: rotateY(180deg);
        top: -242px;
        right: 82px;
    }
`
export const IntroStarEffect4 = styled.img`
    display: none;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        width: 34px;
        top: -180px;
        left: 30px;
    }
`
export const TimerCloud1 = styled.img`
    position: absolute;
    bottom: -175px;
    left: -185px;

    @media (max-width: 1260px) {
        width: calc(469 / 630 * 50vw);
        bottom: calc(-175 / 630 * 50vw);
        left: calc(-185 / 630 * 50vw);
    }

    @media (max-width: 1024px) {
        width: calc(303 / 512 * 50vw);
        bottom: calc(-55 / 512 * 50vw);
        left: calc(-110 / 512 * 50vw);
    }

    @media (max-width: 768px) {
        width: calc(340 / 768 * 100vw);
        bottom: calc(-40 / 768 * 100vw);
        left: calc(90 / 768 * 100vw);
    }

    @media (max-width: 576px) {
        bottom: calc(-130 / 768 * 100vw);
        left: calc(10 / 768 * 100vw);
    }
`
export const TimerCloud2 = styled.img`
    position: absolute;
    width: 456px;
    height: auto;
    transform: rotate(6.418deg);
    bottom: -155px;
    right: -70px;

    @media (max-width: 1260px) {
        transform: scale(1) rotate(6.418deg);
        width: calc(456 / 630 * 50vw);
        bottom: calc(-155 / 630 * 50vw);
        right: calc(-70 / 630 * 50vw);
    }
    @media (max-width: 1024px) {
        width: calc(324 / 512 * 50vw);
        bottom: calc(-90 / 512 * 50vw);
        right: calc(45 / 512 * 50vw);
    }
    @media (max-width: 768px) {
        width: calc(340 / 768 * 100vw);
        bottom: calc(-30 / 768 * 100vw);
        right: calc(90 / 768 * 100vw);
    }
    @media (max-width: 576px) {
        bottom: calc(-120 / 768 * 100vw);
        right: calc(60 / 768 * 100vw);
    }
`
export const TimerCloud3 = styled.img`
    position: absolute;
    width: 340px;
    height: auto;
    transform: rotate(1.219deg);
    bottom: -195px;
    right: 190px;
    z-index: 1;

    @media (max-width: 1260px) {
        transform: scale(1) rotate(1.219deg);
        width: calc(340 / 630 * 50vw);
        bottom: calc(-190 / 630 * 50vw);
        right: calc(190 / 630 * 50vw);
    }
    @media (max-width: 1024px) {
        width: calc(241 / 630 * 50vw);
        bottom: calc(-50 / 512 * 50vw);
        right: calc(220 / 512 * 50vw);
    }
    @media (max-width: 768px) {
        transform: scale(1) rotate(0deg);
        width: calc(380 / 768 * 100vw);
        bottom: calc(-80 / 768 * 100vw);
        right: calc(190 / 768 * 100vw);
    }
    @media (max-width: 576px) {
        bottom: calc(-180 / 768 * 100vw);
        right: calc(230 / 768 * 100vw);
    }
`
export const TimerCloud4 = styled.img`
    display: none;
    position: absolute;
    height: auto;
    z-index: 1;

    @media (max-width: 768px) {
        display: none;
        /* display: block;
        width: calc(340 / 768 * 100vw);
        bottom: calc(-260 / 768 * 100vw);
        left: 0; */
    }

    @media (max-width: 576px) {
        display: block;
        width: calc(340 / 768 * 100vw);
        bottom: calc(-430 / 768 * 100vw);
        left: 0;
    }
`
export const TimerCloud5 = styled.img`
    display: none;
    position: absolute;
    height: auto;
    z-index: 1;

    @media (max-width: 768px) {
        display: none;
        /* display: block;
        transform: rotateY(180deg);
        width: calc(240 / 768 * 100vw);
        bottom: calc(-300 / 768 * 100vw);
        right: 0; */
    }

    @media (max-width: 576px) {
        display: block;
        transform: rotateY(180deg);
        width: calc(240 / 768 * 100vw);
        right: 0;
        bottom: calc(-300 / 768 * 100vw);
    }
`
export const TimerDiv = styled.div`
    position: relative;
    display: inline-block;
    padding: 18px 0px 23px;
    min-width: 630px;
    border-radius: 68.5px;
    border: 2px solid rgba(255, 255, 255, 0.8);
    background: linear-gradient(90deg, #57cbfc -45.02%, #247ccd 124.64%);
    box-shadow: 0px 4px 49.5px 0px rgba(0, 0, 0, 0.2);

    @media (max-width: 1024px) {
        min-width: 457px;
        padding: 18px 0px;
    }

    @media (max-width: 890px) {
        min-width: 288px;
        padding: 8px 0px;
    }

    @media (max-width: 700px) {
        display: none;
    }
`
export const TimerHeader = styled.div`
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;

    @media (max-width: 1024px) {
        font-size: 20px;
    }

    @media (max-width: 890px) {
        font-size: 14px;
    }
`

export const TimerContent = styled.div`
    display: flex;
    align-items: flex-end;
    column-gap: 15px;
    margin-left: 120px;

    @media (max-width: 1024px) {
        margin-left: 100px;
    }

    @media (max-width: 890px) {
        margin-left: 40px;
    }
`
export const TimerContentDay = styled.span`
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    @media (max-width: 1024px) {
        font-size: 39px;
    }

    @media (max-width: 890px) {
        font-size: 32px;
    }
`
export const TimerContentTime = styled.span`
    color: #fff;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;

    @media (max-width: 1024px) {
        font-size: 32px;
    }

    @media (max-width: 890px) {
        font-size: 24px;
    }
`

export const StarEffect1 = styled.img`
    position: absolute;
    bottom: -160px;
    width: 79px;

    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 430px) {
        width: 16px;
        height: 16px;
        transform: rotate(14.569deg);
        height: -300px;
        right: 80px;
    }
`
export const StarEffect2 = styled.img`
    position: absolute;
    bottom: -195px;
    right: 5px;
    width: 62px;

    @media (max-width: 1024px) {
        display: none;
    }
`
