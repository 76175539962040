import styled from 'styled-components'

export const PeopleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;
    padding-bottom: 177px;
`
export const PeopleHeaderWrapper = styled.div`
    width: 30%;
    margin: auto auto 40px auto;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -10px 0px 71.5px -10px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);
`

export const PeopleCardWrapper = styled.div`
    padding-top: 50px;
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    box-shadow: -10px 30px 71.5px 10px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);
`

export const PeopleHeader = styled.h3`
    color: #415162;
    text-align: center;
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 1024px) {
        font-size: 32px;
    }
    @media (max-width: 576px) {
        margin-bottom: 56px;
        font-size: 20px;
    }
`
export const PeopleImages = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 50%;
    align-items: flex-start;
    gap: 33px;

    > div {
        padding-right: 16px;
    }

    @media (max-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        & > div:nth-child(1) {
            grid-area: img1;
        }
        & > div:nth-child(2) {
            grid-area: img2;
        }
        & > div:nth-child(3) {
            grid-area: img3;
        }
        grid-template-areas:
            'img1 img1 img2 img2'
            '. img3 img3 .';
    }

    @media (max-width: 730px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            'img1'
            'img2'
            'img3';
    }
`

export const MoreEmployeesDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 60px;

    @media (max-width: 768px) {
        margin-top: 48px;
    }
`

export const MoreEmployeesButton = styled.button`
    padding: 20px 48px;
    border-radius: 48px;
    background: linear-gradient(90deg, #ff89b4 0%, #e064ff 100%);
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    /* text-transform: uppercase; */
    border: none;

    @media (max-width: 1024px) {
        width: 100%;
        font-size: 20px;
        padding: 20px 24px;
    }
`
export const StarEffect1 = styled.img`
    position: absolute;
    top: 445px;
    left: 0;

    @media (max-width: 1024px) {
        top: unset;
        bottom: 270px;
        left: 180px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`
export const StarEffect2 = styled.img`
    position: absolute;
    width: 40px;
    top: 130px;
    right: 0;

    @media (max-width: 1024px) {
        display: none;
    }

    @media (max-width: 768px) {
        display: block;
        width: calc(29px + (100vw - 430px) * 0.1);
        top: 40%;
        right: 20px;
    }
    @media (max-width: 430px) {
        display: block;
        width: calc(29px + (100vw - 430px) * 0.1);
        top: 48%;
        right: 30px;
    }
`
export const StarEffect3 = styled.img`
    position: absolute;
    top: 20px;
    left: 260px;
    z-index: -1;

    @media (max-width: 1024px) {
        top: -20px;
        left: 160px;
    }

    @media (max-width: 768px) {
        width: calc(29px + (100vw - 430px) * 0.1);
        top: 60px;
        left: 30px;
    }
`
export const LinearGradient1 = styled.div`
    position: absolute;
    top: 445px;
    left: -200px;
    border-radius: 300px;
    opacity: 0.3;
    background: linear-gradient(180deg, #00ffc2 40%, #413dff 60%);
    width: 560px;
    height: 600px;
    filter: blur(180px);
    z-index: -2;

    @media (max-width: 1024px) {
        display: none;
    }
`
export const LinearGradient2 = styled.div`
    position: absolute;
    top: 400px;
    right: -190px;
    border-radius: 218px;
    opacity: 0.6;
    background: linear-gradient(180deg, #ff00c7 100%, #ff3da6 84%);
    width: 160px;
    height: 160px;
    filter: blur(150px);
    z-index: -2;

    @media (max-width: 1024px) {
        display: none;
    }
`
export const CloudEffect1 = styled.img`
    width: 356px;
    height: auto;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    transform: translate(-45%, -40%);

    @media (max-width: 1024px) {
        transform: translate(5%, 30%);
    }

    @media (max-width: 768px) {
        width: calc(180px + (100vw - 430px) * 0.3);
        transform: translate(0%, 0%);
        top: unset;
        bottom: -20px;
        left: -20px;
    }
`
export const CloudEffect2 = styled.img`
    display: none;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: -1;
    transform: translate(35%, -100%);

    @media (max-width: 1024px) {
        display: block;
    }

    @media (max-width: 768px) {
        width: calc(150px + (100vw - 430px) * 0.3);
        transform: translate(0%, 0%) rotateY(-180deg);
        bottom: 100px;
        right: -30px;
    }
`
