import { useState, useEffect } from 'react'
import constents from '../../constants/layout.json'
import { useNavigate, useLocation } from 'react-router-dom'
import {
    HeaderContainer,
    HeaderWrapper,
    Navbar,
    NavItem,
    ItemWrapper,
    PurchaseButton,
    MobileNavbar,
    MobileNavItem,
    MobileMenu,
} from './styled'
import { Logo, FlexRightedDiv } from '../common'
import { HamburgerImage } from '../../constants/images'

const Header = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [navbarOpen, setNavbarOpen] = useState(false)
    const [topPosition, setTopPosition] = useState(true)

    const handleMobileNavbar = (navLink) => {
        navigate(navLink.path, {
            state: { id: navLink.id },
        })
        setNavbarOpen(false)
    }

    const handleScroll = () => {
        const position = window.scrollY
        setTopPosition(position === 0)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <HeaderContainer position={topPosition ? 'top' : ''}>
            <HeaderWrapper>
                <Navbar>
                    <Logo
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/b78731b4f1a5c7539fb4ab635beb0c2d161fb702/componentImg/footerlogo/logo.svg"
                        alt="logo"
                        onClick={() => {
                            navigate('/')
                            window.scrollTo({ top: 0, behavior: 'smooth' })
                            setNavbarOpen(false)
                        }}
                    />
                    <ItemWrapper>
                        {constents.navLinks.map((navLink) => (
                            <NavItem
                                key={navLink.content}
                                background={
                                    navLink.id === location.state?.id
                                        ? '#ffffff'
                                        : 'unset'
                                }
                                onClick={() => {
                                    navigate(navLink.path, {
                                        state: { id: navLink.id },
                                    })
                                }}>
                                {navLink.content}
                            </NavItem>
                        ))}
                    </ItemWrapper>
                    <FlexRightedDiv>
                        <PurchaseButton>Connect Wallet</PurchaseButton>
                        <MobileNavbar>
                            <div onClick={() => setNavbarOpen((prev) => !prev)}>
                                <img src={HamburgerImage} alt="menu" />
                            </div>
                            {navbarOpen && (
                                <MobileMenu>
                                    {constents.navLinks.map((navLink) => (
                                        <MobileNavItem
                                            key={navLink.content}
                                            onClick={() => {
                                                handleMobileNavbar(navLink)
                                            }}>
                                            {navLink.content}
                                        </MobileNavItem>
                                    ))}
                                </MobileMenu>
                            )}
                        </MobileNavbar>
                    </FlexRightedDiv>
                </Navbar>
            </HeaderWrapper>
        </HeaderContainer>
    )
}

export default Header
