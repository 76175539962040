import React from 'react'

import { useRoutes } from 'react-router-dom'

import Home from '../pages/Home'
import Team from '../pages/Team'
import Litepaper from '../pages/Litepaper'
import { Cookies, Disclaimer, Terms, Privacy } from '../pages/Polices'

const AppRoutes = () => {
    const commonRoutes = [
        { path: '/', element: <Home /> },
        { path: '/team', element: <Team /> },
        { path: '/terms', element: <Terms /> },
        { path: '/disclaimer', element: <Disclaimer /> },
        { path: '/cookies', element: <Cookies /> },
        { path: '/privacy', element: <Privacy /> },
        { path: '/litepaper', element: <Litepaper /> },
    ]

    const element = useRoutes([...commonRoutes])

    return <div>{element}</div>
}

export default AppRoutes
