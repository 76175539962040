import React from 'react'
import SocialLinks from '../../Common/SocialLinks'
import { headerImg } from '../../../constants/images'
import { Container } from '../../common'
import {
    IntroContent,
    IntroTitleWrapper,
    IntroTitleGradient,
    IntroGradient1,
    IntroGradient2,
    IntroGradient3,
    IntroGradient4,
    IntroGradient5,
    IntroGradient6,
    IntroTitle1,
    IntroTitle2,
    IntroMainTitle,
    IntroParagraph,
    IntroImageWrapper,
    IntroStarEffect1,
    IntroStarEffect2,
    IntroStarEffect3,
    IntroStarEffect4,
    TimerCloud1,
    TimerCloud2,
    TimerCloud3,
    TimerCloud4,
    TimerCloud5,
    StarEffect1,
    StarEffect2,
} from './styled'

const Intro = () => {
    return (
        <Container>
            <IntroContent>
                <IntroTitleWrapper>
                    <IntroGradient1 />
                    <IntroGradient2 />
                    <IntroGradient3 />
                    <IntroGradient4 />
                    <IntroTitleGradient />
                    <IntroTitle1>Bringing the</IntroTitle1>
                    <IntroMainTitle>Future</IntroMainTitle>
                    <IntroTitle2>to now!</IntroTitle2>
                    <IntroParagraph>
                        We are going to be the largest, most trusted and stable
                        ecosystem in the world
                    </IntroParagraph>
                    <SocialLinks />
                </IntroTitleWrapper>
                <IntroImageWrapper img={headerImg}>
                    <IntroStarEffect1
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star.png"
                        alt="star-effect"
                    />
                    <IntroStarEffect2
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star.png"
                        alt="star-effect"
                    />
                    <IntroStarEffect3
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star.png"
                        alt="star-effect"
                    />
                    <IntroStarEffect4
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star2.png"
                        alt="star-effect"
                    />
                    <TimerCloud1
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud.png"
                        alt="cloud"
                    />
                    <TimerCloud2
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud.png"
                        alt="cloud"
                    />
                    <TimerCloud3
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud.png"
                        alt="cloud"
                    />
                    <TimerCloud4
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud.png"
                        alt="cloud"
                    />
                    <TimerCloud5
                        src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud.png"
                        alt="cloud"
                    />
                </IntroImageWrapper>
                <IntroGradient5 />
                <IntroGradient6 />

                <StarEffect1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star2.png"
                    alt="starEffectNoColor"
                />
                <StarEffect2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="starEffectNoDeg"
                />
            </IntroContent>
        </Container>
    )
}

export default Intro
