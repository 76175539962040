import { useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PeopleCard from '../../Team/PeopleCard'
import { executiveTeamImages } from '../../../constants/variables'
import {
    PeopleWrapper,
    PeopleImages,
    PeopleHeader,
    MoreEmployeesDiv,
    MoreEmployeesButton,
    StarEffect1,
    StarEffect2,
    StarEffect3,
    LinearGradient1,
    LinearGradient2,
    CloudEffect1,
    CloudEffect2,
} from './styled'

import { Container } from '../../common'

const People = ({ title, showMore, selectedRoute }) => {
    const containerRef = useRef(null)
    const navigate = useNavigate()

    useEffect(() => {
        if (selectedRoute === 'team') {
            window.scrollTo({
                top:
                    containerRef.current.getBoundingClientRect().top +
                    window.scrollY -
                    100,
                behavior: 'smooth',
            })
        }
    }, [selectedRoute])

    return (
        <Container>
            <PeopleWrapper>
                <CloudEffect1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="cloudImg"
                />
                <CloudEffect2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="cloudImg"
                />
                <PeopleHeader ref={containerRef}>{title}</PeopleHeader>
                <PeopleImages>
                    {executiveTeamImages.map((image) => (
                        <PeopleCard key={image.name} image={image} />
                    ))}
                </PeopleImages>
                {showMore && (
                    <MoreEmployeesDiv>
                        <MoreEmployeesButton onClick={() => navigate('/team')}>
                            Meet More Key People
                        </MoreEmployeesButton>
                    </MoreEmployeesDiv>
                )}
                <StarEffect1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="stareffect"
                />
                <StarEffect2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="stareffect"
                />
                <StarEffect3
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="stareffect"
                />
                <LinearGradient1 />
                <LinearGradient2 />
            </PeopleWrapper>
        </Container>
    )
}

export default People
