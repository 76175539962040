export const socialIcons = [
    {
        content: 'twitter',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/twitter.svg',
        link: 'https://twitter.com/obinexofficial',
    },
    {
        content: 'linkedin',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/linkedin.svg',
        link: 'https://www.linkedin.com/company/obinex/',
    },
    {
        content: 'telegram',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/telegram.svg',
        link: 'https://t.me/ObinexEnglish',
    },
    {
        content: 'discord',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/discord.svg',
        link: 'https://discord.gg/obix',
    },
    {
        content: 'medium',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/medium.svg',
        link: 'https://medium.com/@obinex',
    },
    {
        content: 'instagram',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/instagram.svg',
        link: 'https://www.instagram.com/obinexofficial/ ',
    },
]
export const footerSocialIcons = [
    {
        content: 'twitter',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/f84cbd46d83331012e997a9a2f9e71cdbfe89a58/footericons/twitter.svg',
        link: 'https://twitter.com/obinexofficial',
    },
    {
        content: 'linkedin',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/f84cbd46d83331012e997a9a2f9e71cdbfe89a58/footericons/linkedin.svg',
        link: 'https://www.linkedin.com/company/obinex/',
    },
    {
        content: 'telegram',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/f84cbd46d83331012e997a9a2f9e71cdbfe89a58/footericons/telegram.svg',
        link: 'https://t.me/ObinexEnglish',
    },
    {
        content: 'discord',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/f84cbd46d83331012e997a9a2f9e71cdbfe89a58/footericons/discord.svg',
        link: 'https://discord.gg/obix',
    },
    {
        content: 'medium',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/social/medium.svg',
        link: 'https://medium.com/@obinex',
    },
    {
        content: 'instagram',
        icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/f84cbd46d83331012e997a9a2f9e71cdbfe89a58/footericons/Instagram.svg',
        link: 'https://www.instagram.com/obinexofficial/ ',
    },
]

export const executiveTeamImages = [
    {
        position: 'CEO | Founder',
        name: 'Alejandro Grande',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/alejandro.png',
        link: 'https://www.linkedin.com/in/alejandro-obinex/',
    },
    {
        position: 'CIO | Co-Founder',
        name: 'Miguel Castaño',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/miguel.png',
        link: 'https://www.linkedin.com/in/mch310199/',
    },
    {
        position: 'CLO',
        name: 'Jose Antonio López',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/jose.png',
        link: 'https://www.linkedin.com/in/jose-antonio-lopez-30678a9/',
    },
    // {
    //     position: 'Corporate Counsel',
    //     name: 'Alberto Rodríguez',
    //     path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/alberto.png',
    //     link: 'https://www.linkedin.com/in/alberto-rodr%C3%ADguez-sevilla-92b211a8/',
    // },
]

export const executiveTeamImages1 = [
    {
        position: 'CEO | Founder',
        name: 'Alejandro Grande',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/alejandro.png',
        link: 'https://www.linkedin.com/in/alejandro-obinex/',
    },
    {
        position: 'CIO | Co-Founder',
        name: 'Miguel Castaño',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/miguel.png',
        link: 'https://www.linkedin.com/in/mch310199/',
    },
    {
        position: 'CLO',
        name: 'Jose Antonio López',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/jose.png',
        link: 'https://www.linkedin.com/in/jose-antonio-lopez-30678a9/',
    },
    {
        position: 'Corporate Counsel',
        name: 'Alberto Rodríguez',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/alberto.png',
        link: 'https://www.linkedin.com/in/alberto-rodr%C3%ADguez-sevilla-92b211a8/',
    },
]

export const restTeamImages = [
    {
        position: 'HR Director',
        name: 'Melissa Ribaira',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/melissa.png',
        link: 'https://www.linkedin.com/in/melissaribaira/',
    },
    {
        position: 'Compliance Manager',
        name: 'Filipa Cepêda',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/filipa.png',
        link: 'https://www.linkedin.com/in/filipacepeda',
    },
    {
        position: 'Social Media Manager',
        name: 'María Yarza',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/maria.png',
        link: 'https://www.linkedin.com/in/mariayarza/ ',
    },
    {
        position: 'Lead Community Manager',
        name: 'Al Rizqi',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/al.png',
        link: 'https://www.linkedin.com/in/al-rizqi/',
    },
    {
        position: 'Community Manager',
        name: 'Matthew Hinton',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/matthew.png',
        link: 'https://www.linkedin.com/in/matthewhintonb/',
    },
    {
        position: 'Community Manager',
        name: 'Stephanie Aquino',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/stephanie.png',
        link: 'https://www.linkedin.com/in/stephaniebaquino/',
    },
    {
        position: 'Partnerships Manager',
        name: 'Ivan Zykov',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/Ivan.png',
        link: 'https://www.linkedin.com/in/ivan-zykov-29058b268/',
    },
    {
        position: 'Telegram Specialist',
        name: 'David Arushanyan',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/david.png',
        link: 'https://www.linkedin.com/in/david-arushanyan-284a5823b/',
    },
    {
        position: 'Lead Social Media Speaker',
        name: 'Ayu Suryosukumo',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/ayu.png',
        link: 'https://www.linkedin.com/in/arwyn-levin/',
    },
    {
        position: 'Recruiter',
        name: 'Manisha Priyadarshini',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/manisha.png',
        link: 'https://www.linkedin.com/in/manisha-05p/',
    },
    {
        position: 'UX/UI Designer',
        name: 'Ruthra Moorthy',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/ruthra.png',
        link: 'https://www.linkedin.com/in/ruthra-moorthy-5a34a687/',
    },
    {
        position: 'Front End Developer',
        name: 'Janko Janicijevic',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/janko.png',
        link: 'https://www.linkedin.com/in/serviusapolum/',
    },
    {
        position: 'Recruiter',
        name: 'Rizky Pratama',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/rizky.png',
        link: 'https://www.linkedin.com/in/rizkypr/',
    },
    {
        position: 'Graphic Designer',
        name: 'Wisdom David',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/wisdom.png',
        link: 'https://www.linkedin.com/in/wisdom-david-8a447a184/',
    },
    {
        position: 'Graphic Designer',
        name: 'Dede Farhullail',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/dede.png',
        link: 'https://www.linkedin.com/in/dedefarhullail/',
    },
    {
        position: 'Social Media Speaker',
        name: 'Ann Imoagene',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/ann.png',
        link: 'https://www.linkedin.com/in/ann-imoagene-378038266/',
    },
    {
        position: 'Legal Intern',
        name: 'Dominika Miklewska',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/New%20folder/dominika.png',
        link: 'https://www.linkedin.com/in/dominika-miklewska-7365a8273',
    },
]

export const logoImages = [
    {
        content: 'ois',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/OIS.png',
        link: 'https://www.ois.dev/',
    },
    {
        content: 'neo',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/Neocoder.png',
        link: 'https://neocoder.com/',
    },
    {
        content: 'erc20',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/ERC20.png',
        link: 'https://www.linkedin.com/company/erc20-academy-consulting-investment/',
    },
    {
        content: 'rarity',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/mixed_rarity.png',
        link: 'https://mixedrarity.com/',
    },
    {
        content: 'wayops',
        path: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/wayops.png',
        link: 'https://wayops.eu/',
    },
]

export const roadMapData1 = [
    {
        date: 'Q1 2024',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Referral Platform Launch',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/cube2.png',
                content: 'Private Equity Rounds Starts',
                alt: 'cube',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'Second Strategic Partners Round',
                alt: 'wix',
            },
        ],
        zIndex: 1,
        arrows: {
            '1420~9999': ['right'],
            '940~1420': ['right'],
            '0~940': ['up'],
        },
    },
    {
        date: 'Q2 2024',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Liquidity Launch',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'Vesting Starts',
                alt: 'blockchain',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'CEX Development Starts',
                alt: 'wix',
            },
        ],
        zIndex: 2,
        arrows: {
            '1420~9999': ['right'],
            '940~1420': ['right', 'down'],
            '0~940': ['up'],
        },
    },
    {
        date: 'Q3 2024',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Regulatory Process Starts',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'South America & Africa Markets',
                alt: 'blockchain',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'Blockchain Development Starts',
                alt: 'wix',
            },
        ],
        zIndex: 3,
        arrows: {
            '1420~9999': ['right'],
            '0~940': ['up'],
        },

        order: { '940~1420': 1 },
    },
    {
        date: 'Q4 2024',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Trading Launches on BSC',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'Staking Pools',
                alt: 'blockchain',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'Mobile App Development',
                alt: 'wix',
            },
        ],
        zIndex: 4,
        arrows: {
            '1420~9999': ['right'],
            '940~1420': ['left'],
            '0~940': ['up'],
        },
    },
]

export const roadMapData2 = [
    {
        date: 'Q1 2025',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Improved P2P Service Launch',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'DEX Implementation',
                alt: 'blockchain',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'Mobile App Launch',
                alt: 'wix',
            },
        ],
        zIndex: 1,
        arrows: {
            '1420~9999': ['left'],
            '940~1420': ['right'],
            '0~940': ['up'],
        },
    },
    {
        date: 'Q2 2025',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Custody Service Development',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'New Market Expansion',
                alt: 'blockchain',
            },
            // {
            //     icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
            //     content: 'New Market Expansion',
            //     alt: 'wix',
            // },
        ],
        zIndex: 2,
        arrows: {
            '1420~9999': ['left'],
            '940~1420': ['right', 'down'],
            '0~940': ['up'],
        },
    },
    {
        date: 'Q3 2025',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Blockchain Testnet Launch Start',
                alt: 'referral',
            },
            // {
            //     icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
            //     content: 'Bridges Development Start',
            //     alt: 'blockchain',
            // },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'Bridges Development Start',
                alt: 'wix',
            },
        ],
        zIndex: 3,
        arrows: {
            '1420~9999': ['left'],
            '0~940': ['up'],
        },
        order: { '940~1420': -1 },
    },
    {
        date: 'Q4 2025',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Blockchain Mainnet Launch',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'Predictive AI implementation',
                alt: 'blockchain',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'e-Commerce Platform Ddevelopment',
                alt: 'wix',
            },
        ],
        zIndex: 4,
        arrows: {
            '1420~9999': ['left'],
            '940~1420': ['left'],
            '0~940': ['up'],
        },
    },
].reverse()

export const partnersData = [
    {
        img: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/wayops.png',
        content:
            "WayOps, a leading technology consultancy, specializes in digital, data-driven, and cognitive transformation for businesses. WayOps stands out for its expertise in Cloud, Big Data, AI, IoT, and DevOps. With a comprehensive portfolio that includes consulting, custom solutions, and professional services, WayOps is powered by certified professionals embodying the company's CLEAR values: Trust, Leadership, Excellence, Adaptability, and Resolution. WayOps’ goal is to shift client perspectives, showcasing technology not just as a tool but as a key driver for innovative business models in the Cloud, Big Data, and AI era.",
    },
    {
        img: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/OIS.png',
        content:
            'OIS is a leading blockchain development company in the web3 industry. Their stack includes Solidity, React, NextJS, and Rust. They provide end-to-end blockchain solutions, using cutting-edge tools and frameworks to build secure, scalable, and interoperable blockchain systems. OIS empowers businesses of all sizes to leverage the full potential of blockchain technology.',
    },
    {
        img: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/ERC20.png',
        content:
            'ERC20 Consulting delivers expert guidance and customized solutions, offering clients access to tools employed by major corporations. Their expertise empowers clients to make informed decisions, optimizing performance. Its international network of collaborators provides tax, financial, labor, and legal solutions, including those within the Blockchain ecosystem. ERC20 Consulting is trusted for clarity and excellence in decision-making.',
    },
    {
        img: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/logos/Neocoder.png',
        content:
            'Neocoder is a dynamic marketing agency with a passion for blockchain and Web3 technologies, harnessing 12 years of market experience. With a strategic focus on LATAM and global outreach, their team of specialists offers integrated and tailored marketing solutions to grow blockchain communities. Neocoder provides a spectrum of services from strategy to influencer marketing, and bespoke community management, ensuring its clients not only enter but lead the Web3 narrative.',
    },
]

export const foundingTeamData = [
    {
        name: 'Alejandro Grande',
        position: 'Chief Executive Officer (CEO) & Founder',
        photo: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/alejandro.png',
        content:
            "With more than four years of cryptocurrency industry experience, our Founder has a deep understanding of blockchain technology and the cryptocurrency market. As an integral member of a crypto project, he successfully managed projects and people for both companies and customers operating on the blockchain. Alejandro's innovative ideas come to life at OBINEXTM, a platform that challenges the status quo by focusing on the needs of users and investors.",
    },
    {
        name: 'Miguel Castaño Hernandez',
        position: 'Chief Information Officer (CIO) & Co-Founder',
        photo: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/miguel.png',
        content:
            'Miguel Castaño Hernández, CIO and Co-Founder of OBINEX, is an experienced professional expert in digital transformation. Technical Systems Engineer from the Polytechnic University of Madrid (UPM) and Master in Business Consulting specialising in Big Data and Data Sciences from the Universidad Pontificia Comillas ICAI-ICADE; Miguel perfectly aligns business strategy with technology.\n Finally, he has extensive experience in defining IT strategies, developing information systems and leading profitable digital transformations. and a long experience in leading profitable digital transformation processes, Miguel is key to drive OBINEX.',
    },
    {
        name: 'Jose Antonio López',
        position: 'Chief Legal Officer (CLO)',
        photo: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/peoples/jose.png',
        content:
            'Jose Antonio Lopez Martinez brings over 28 years of expertise to the role of Chief Legal Officer at OBINEXTM. As a seasoned legal professional and Chartered IP Agent, Jose is a leading figure in commercial, civil, criminal, and intellectual property law.  With a Law degree from the University of Alicante and a LLM on IP/IT Law, his extensive career includes representing and advising on specific cases of renowned entities such as Deutsche Telekom AG, Volkswagen AG, and Unilever. With a mastery of multiple languages, Jose has not only practiced law but also contributed to academia as an educator. Jose continues his legacy of excellence  at OBINEXTM in navigating the intricacies of law, finance, and technology.',
    },
]

export const roadmapData = [
    {
        date: '2024',
        arrows: {
            '1620~9999': 'right',
            '950~1620': 'right',
            '0~950': 'down-center',
        },
    },
    {
        date: 'Q1 2024',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Referral Platform Launch',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/cube2.png',
                content: 'Private Equity Rounds Starts',
                alt: 'cube',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'Second Strategic Partners Round',
                alt: 'wix',
            },
        ],
        arrows: {
            '1620~9999': 'right',
            '950~1620': 'right',
            '0~950': 'down-center',
        },
    },
    {
        date: 'Q2 2024',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Liquidity Launch',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'Vesting Starts',
                alt: 'blockchain',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'CEX Development Starts',
                alt: 'wix',
            },
        ],
        arrows: {
            '1620~9999': 'right',
            '950~1620': 'down-right',
            '0~950': 'down-center',
        },
    },
    {
        date: 'Q3 2024',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Regulatory Process Starts',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'South America & Africa Markets',
                alt: 'blockchain',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'Blockchain Development Starts',
                alt: 'wix',
            },
        ],
        arrows: {
            '1620~9999': 'down-right',
            '950~1620': 'left',
            '0~950': 'down-center',
        },
    },
    {
        date: 'Q4 2024',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Trading Launches on BSC',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'Staking Pools',
                alt: 'blockchain',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'Mobile App Development',
                alt: 'wix',
            },
        ],
        arrows: {
            '1620~9999': 'left',
            '950~1620': 'down',
            '0~950': 'down-center',
        },
    },
    {
        date: '2025',
        arrows: {
            '1620~9999': 'left',
            '950~1620': 'right',
            '0~950': 'down-center',
        },
    },
    {
        date: 'Q1 2025',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Improved P2P Service Launch',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'DEX Implementation',
                alt: 'blockchain',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'Mobile App Launch',
                alt: 'wix',
            },
        ],
        arrows: {
            '1620~9999': 'left',
            '950~1620': 'right',
            '0~950': 'down-center',
        },
    },
    {
        date: 'Q2 2025',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Custody Service Development',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'New Market Expansion',
                alt: 'blockchain',
            },
        ],
        arrows: {
            '1620~9999': 'down-left',
            '950~1620': 'down-right',
            '0~950': 'down-center',
        },
    },
    {
        date: 'Q3 2025',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Blockchain Testnet Launch Start',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'Bridges Development Start',
                alt: 'wix',
            },
        ],
        arrows: {
            '1620~9999': 'right',
            '950~1620': 'left',
            '0~950': 'down-center',
        },
    },
    {
        date: 'Q4 2025',
        rows: [
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/referral2.png',
                content: 'Blockchain Mainnet Launch',
                alt: 'referral',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/blockchain2.png',
                content: 'Predictive AI implementation',
                alt: 'blockchain',
            },
            {
                icon: 'https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/icons/wix2.png',
                content: 'e-Commerce Platform Ddevelopment',
                alt: 'wix',
            },
        ],
    },
]
