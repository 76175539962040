import styled from 'styled-components'

export const MainBlocksWrapper = styled.div`
    padding-top: 50px;
    margin: auto;
    width: 100%;
`
export const MainBlocksColumnDiv = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    column-gap: 50px;
    padding-bottom: 51px;
    @media (max-width: 1320px) {
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 430px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 31px;
    }
`
export const MainBlocksHeader = styled.h1`
    color: var(--titlesdd, #415162);
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 47.3px */
    margin-top: unset;
    margin-bottom: 51px;
    @media (max-width: 430px) {
        text-align: center;
        /* margin-bottom: unset; */
    }
`
export const MainBlocksTitle = styled.h2`
    color: var(--TITLE, #2f2f30);
    /* H2 */
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 46.4px */
    margin-top: unset;
    @media (max-width: 430px) {
        text-align: center;
        margin-bottom: 46px;
    }
`
export const MainBlocksTitle1 = styled.h2`
    color: var(--TITLE, #2f2f30);
    /* H2 */
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 46.4px */
    margin-top: 40px;
    margin-bottom: 10px;
    @media (max-width: 430px) {
        text-align: center;
    }
`

export const MainBlocksContent = styled.div`
    color: var(--TITLE, #2f2f30);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    @media (max-width: 430px) {
        text-align: center;
    }
`
export const MainBlocksContent1 = styled.div`
    color: var(--TITLE, #2f2f30);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-right: -21px;
    @media (max-width: 430px) {
        width: 100%;
        text-align: center;
    }
`
export const MainBlocksContent4 = styled.div`
    color: var(--TITLE, #2f2f30);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-right: -5px;
    @media (max-width: 430px) {
        width: 100%;
        padding-right: 16px;
        text-align: center;
    }
`

export const MainBlocksUl = styled.ul`
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    @media (max-width: 430px) {
        padding-left: 20px;
        align-items: center;
        padding-bottom: 0px;
    }
`

export const MainBlocksDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`
export const MainBlocksDiv1 = styled.div`
    display: flex;
    flex-direction: column;
    padding: 120px 0px 120px 0px;
    width: 51%;
    @media (max-width: 1320px) {
        width: 100%;
        padding: 0px 13px 0px 13px;
    }
    @media (max-width: 1024px) {
        width: 100%;
        padding: 0px 13px 0px 13px;
    }
    @media (max-width: 430px) {
        width: 100%;
        padding: unset;
    }
`
export const MainBlocksDiv2 = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 0px 100px 7px;
    width: 51%;
    @media (max-width: 1320px) {
        padding-bottom: 0px;
    }
    @media (max-width: 430px) {
        width: 100%;
        padding: 0px 13px 0px 13px;
    }
`
export const MainBlocksDiv3 = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 43px;
    padding-bottom: 43px;
    width: 51%;
    @media (max-width: 1320px) {
        width: 100%;
        padding-right: 15px;
    }
    @media (max-width: 430px) {
        width: 100%;
        padding-right: 16px;
    }
`
export const MainBlocksDiv5 = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: unset;
    padding-bottom: 60px;
    width: 51%;
    @media (max-width: 1320px) {
        width: 100%;
        padding-right: 15px;
    }
    @media (max-width: 430px) {
        width: 100%;
        padding-bottom: unset;
        padding-right: 15px;
    }
`
export const MainBlocksDiv7 = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 26px;
    padding-bottom: 43px;
    width: 51%;
    @media (max-width: 1320px) {
        width: 100%;
        padding-right: 15px;
    }
    @media (max-width: 430px) {
        width: 100%;
        padding-right: 15px;
    }
`

export const MainBlocksDiv4 = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: unset;
    padding-bottom: 60px;
    width: 56%;
    @media (max-width: 1320px) {
        width: 100%;
    }
    @media (max-width: 430px) {
        width: 100%;
    }
`
export const MainBlocksDiv6 = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 43px;
    padding-bottom: 43px;
    width: 51%;
    @media (max-width: 430px) {
        width: 100%;
        padding-right: 15px;
    }
`
export const TileWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-left: 40px;
    gap: 29px;
    @media (max-width: 1320px) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-top: 30px;
        padding-left: unset;
        justify-content: center;
    }
    @media (max-width: 560px) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        padding: 69px 22.5px 46px 8.5px;
    }
    @media (max-width: 430px) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        padding: 69px 22.5px 46px 8.5px;
    }
    @media (max-width: 400px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
`
export const TileDiv1 = styled.div`
    display: flex;
    width: 224px;
    height: 218px;
    padding: 97px 64px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 40px;
    border: 0.7px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);
    @media (max-width: 560px) {
        width: 164px;
        height: 160px;
        padding: 56px 39px;
    }
    @media (max-width: 430px) {
        width: 164px;
        height: 160px;
        padding: 56px 39px;
    }
`
export const TileDiv2 = styled.div`
    display: flex;
    width: 224px;
    height: 218px;
    padding: 97px 64px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 40px;
    border: 0.7px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);
    @media (max-width: 560px) {
        width: 164px;
        height: 160px;
        padding: 56px 39px;
    }
    @media (max-width: 430px) {
        width: 164px;
        height: 160px;
        padding: 56px 39px;
    }
`
export const TileDiv3 = styled.div`
    display: flex;
    width: 224px;
    height: 218px;
    padding: 97px 64px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 40px;
    border: 0.7px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);
    @media (max-width: 560px) {
        width: 164px;
        height: 160px;
        padding: 56px 39px;
    }
    @media (max-width: 430px) {
        width: 164px;
        height: 160px;
        padding: 56px 39px;
    }
`
export const TileDiv4 = styled.div`
    display: flex;
    width: 224px;
    height: 218px;
    padding: 97px 64px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 40px;
    border: 0.7px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12.5px);
    @media (max-width: 560px) {
        width: 164px;
        height: 160px;
        padding: 56px 39px;
    }
    @media (max-width: 430px) {
        width: 164px;
        height: 160px;
        padding: 56px 39px;
    }
`
export const Tile1 = styled.h3`
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
    background: var(
        --obinex-gradient-1,
        linear-gradient(180deg, #ff61f9 0%, #ff00f5 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
export const Tile2 = styled.h3`
    text-align: center;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
    background: var(
        --Gradient-Blue,
        linear-gradient(180deg, #2395ff 0%, rgba(37, 151, 255, 0.68) 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
export const Tile3 = styled.h3`
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
    background: linear-gradient(
        180deg,
        #fff500 0%,
        rgba(173, 158, 22, 0.39) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
export const Tile4 = styled.h3`
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
    background: var(
        --Gradient-green,
        linear-gradient(180deg, #00ff47 0%, rgba(26, 255, 90, 0.40) 100%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

export const CloudEffect1 = styled.img`
    position: absolute;
    width: 400px;
    height: auto;
    right: -480px;
    top: -10px;
    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const CloudEffect2 = styled.img`
    position: absolute;
    width: 400px;
    height: auto;
    left: -500px;
    top: 420px;
    @media (max-width: 1024px) {
        display: none;
    }
    @media (max-width: 430px) {
        display: none;
    }
`
export const CloudEffect3 = styled.img`
    position: absolute;
    transform: scalex(-1);
    width: 502px;
    height: auto;
    right: -530px;
    top: 150px;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        width: 173px;
        height: auto;
        right: -8px;
        top: -245px;
        z-index: -1;
    }
`
export const CloudEffect4 = styled.img`
    display: none;
    @media (max-width: 430px) {
        display: block;
        position: absolute;
        width: 109px;
        height: 114px;
        top: 150px;
        left: 20px;
        z-index: -1;
    }
`
