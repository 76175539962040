import styled from 'styled-components'

export const LogoWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2.75fr 3.57fr 2.68fr 1.2fr;
    padding-bottom: 147px;
    column-gap: 98px;
    align-items: center;

    @media (max-width: 1260px) {
        padding-left: 20px;
        padding-right: 20px;
        gap: 58px;
    }
    @media (max-width: 1024px) {
        width: 100%;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: 0.42fr 0.1fr 1fr 1fr 1fr 1.4fr;
        gap: 28px;
        padding: 0 33px 50px;

        & > a {
            text-align: center;
        }

        & > a:nth-child(1) {
            grid-area: logo1;
        }
        & > a:nth-child(2) {
            grid-area: logo2;
        }
        & > a:nth-child(3) {
            grid-area: logo3;
        }
        & > a:nth-child(4) {
            grid-area: logo4;
        }
        & > a:nth-child(5) {
            grid-area: logo5;
        }
        grid-template-areas:
            'logo1 logo1 logo2 logo2 logo3 logo3'
            '. logo4 logo4 logo4 logo5 .';
    }
    @media (max-width: 420px) {
        width: 100%;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: 0.42fr 0.1fr 1fr 1fr 1fr 1.4fr;
        gap: 10px;
        padding: 0 33px 50px;

        & > a {
            text-align: center;
        }

        & > a:nth-child(1) {
            grid-area: logo1;
        }
        & > a:nth-child(2) {
            grid-area: logo2;
        }
        & > a:nth-child(3) {
            grid-area: logo3;
        }
        & > a:nth-child(4) {
            grid-area: logo4;
        }
        & > a:nth-child(5) {
            grid-area: logo5;
        }
        grid-template-areas:
            'logo1 logo1 logo2 logo2 logo3 logo3'
            '. logo4 logo4 logo4 logo5 .';
    }
`

export const LogoImage = styled.img`
    max-width: 100%;
`
export const Logoline = styled.div`
    background: #c3c3c3;
    width: 80%;
    height: 1px;
    margin: auto auto 72px auto;
    @media (max-width: 1024px) {
        width: 50%;
    }
    @media (max-width: 430px) {
        width: 60%;
        margin: auto auto 32px auto;
    }
`
