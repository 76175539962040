import { useRef, useState, useEffect } from 'react'
import { Container } from '../../common'
import { roadMapData1, roadMapData2 } from '../../../constants/variables'

import {
    RoadmapTitle,
    RoadmapWrapper,
    Cards,
    CardGrid1,
    CardGrid2,
    CloudEffect1,
    CloudEffect2,
    CloudEffect3,
    CloudEffect4,
} from './styled'
import YearCard from './YearCard'
import ContentCard from './ContentCard'

const Roadmap = ({ selectedRoute }) => {
    const containerRef = useRef(null)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        if (selectedRoute === 'roadmap') {
            window.scrollTo({
                top:
                    containerRef.current.getBoundingClientRect().top +
                    window.scrollY -
                    100,
                behavior: 'smooth',
            })
        }
    }, [selectedRoute])

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <Container>
            <RoadmapWrapper>
                <RoadmapTitle ref={containerRef}>Roadmap</RoadmapTitle>
                <Cards>
                    <CardGrid1>
                        <YearCard year={2024} />
                        {roadMapData1.map((mapData) => (
                            <ContentCard
                                key={mapData.date}
                                content={mapData}
                                screenWidth={screenWidth}
                            />
                        ))}
                    </CardGrid1>
                    <CardGrid2>
                        {roadMapData2.map((mapData) => (
                            <ContentCard
                                key={mapData.date}
                                content={mapData}
                                screenWidth={screenWidth}
                            />
                        ))}
                        <YearCard year={2025} />
                    </CardGrid2>
                </Cards>
                <CloudEffect1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="cloudImg"
                />
                <CloudEffect2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="cloudImg"
                />
                <CloudEffect3
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="cloudImg"
                />
                <CloudEffect4
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="cloudImg"
                />
            </RoadmapWrapper>
        </Container>
    )
}

export default Roadmap
