import { useRef, useEffect } from 'react'
import { Container } from '../../common'
import {
    VaulesHeader,
    ValuesWrapper,
    ValuesContent,
    SecureDiv,
    TransparentDiv,
    TailoredDiv,
    ValuesTitle,
    ValuesDescription,
    TransparentWrapper,
    TailoredWrapper,
    SecureWrapper,
    SecureImg,
    TailoredImg,
    TransparentImg,
    CloudEffect1,
    CloudEffect2,
    StarEffect1,
    StarEffect2,
} from './styled'

const Values = ({ selectedRoute }) => {
    const containerRef = useRef(null)

    useEffect(() => {
        if (selectedRoute === 'about-us') {
            window.scrollTo({
                top:
                    containerRef.current.getBoundingClientRect().top +
                    window.scrollY -
                    100,
                behavior: 'smooth',
            })
        }
    }, [selectedRoute])
    return (
        <Container>
            <ValuesWrapper>
                <CloudEffect1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="cloudEffect"
                />
                <CloudEffect2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="cloudEffect"
                />
                <StarEffect1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="starEffectNoDeg"
                />
                <StarEffect2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="starEffectNoDeg"
                />

                <VaulesHeader ref={containerRef}>Why Obinex?</VaulesHeader>
                <ValuesContent>
                    <SecureDiv>
                        <SecureWrapper>
                            <ValuesTitle>Secure</ValuesTitle>
                            <ValuesDescription>
                                AI enhances transaction security by spotting
                                suspicious patterns, boosting user trust.
                            </ValuesDescription>
                        </SecureWrapper>
                        <SecureImg
                            src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/value/SecureImg.png"
                            alt="Secure"
                        />
                    </SecureDiv>
                    <TransparentDiv>
                        <TransparentWrapper>
                            <ValuesTitle>Transparent</ValuesTitle>
                            <ValuesDescription>
                                Have the opportunity to pay less fees with our
                                new transaction volume fee reduction system.
                            </ValuesDescription>
                        </TransparentWrapper>
                        <TransparentImg
                            src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/value/TransparentImg.png"
                            alt="Transparent"
                        />
                    </TransparentDiv>
                    <TailoredDiv>
                        <TailoredWrapper>
                            <ValuesTitle>Tailored</ValuesTitle>
                            <ValuesDescription>
                                Are you experiencing any issue?Our dedicated
                                expert support team is here to prioritize your
                                peace of mind.
                            </ValuesDescription>
                        </TailoredWrapper>
                        <TailoredImg
                            src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/value/TailoredImg.png"
                            alt="Tailored"
                        />
                    </TailoredDiv>
                </ValuesContent>
            </ValuesWrapper>
        </Container>
    )
}

export default Values
