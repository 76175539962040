import {
    FoundingTeamHeader,
    FoundingTeamItemWarpper,
    FoundingTeamItem,
    FoundingTeamPersonalWrapper,
    FoundingTeamPhoto,
    FoundingTeamPersonalName,
    FoundingTeamPersonalPosition,
    FoundingTeamContent,
} from './styled'
import { foundingTeamData } from '../../../constants/variables'

const widths = ['184px', '165px', '208px']
const mobileWidths = ['184px', '200px', '208px']
const paddingLefts = ['35px', '42px', '35px']
const paddingRights = ['31px', '38px', '34px']

const FooundingTeam = () => {
    return (
        <>
            <FoundingTeamHeader>Founding Team</FoundingTeamHeader>
            <FoundingTeamItemWarpper>
                {foundingTeamData.map((item, index) => (
                    <FoundingTeamItem
                        key={index}
                        paddingLeft={paddingLefts[index]}
                        paddingRight={paddingRights[index]}>
                        <FoundingTeamPersonalWrapper
                            width={index === 2 ? '281px' : '290px'}>
                            <FoundingTeamPhoto src={item.photo} />
                            <FoundingTeamPersonalName
                                lineHeight={index === 2 ? '100%' : '160%'}
                                width={widths[index]}>
                                {item.name}
                            </FoundingTeamPersonalName>
                            <FoundingTeamPersonalPosition
                                width={widths[index]}
                                mobileWidth={mobileWidths[index]}>
                                {item.position}
                            </FoundingTeamPersonalPosition>
                        </FoundingTeamPersonalWrapper>
                        <FoundingTeamContent>
                            {item.content}
                        </FoundingTeamContent>
                    </FoundingTeamItem>
                ))}
            </FoundingTeamItemWarpper>
        </>
    )
}

export default FooundingTeam
