import React from 'react'
import {
    Wrapper,
    Content,
    ContentSpan,
    Header,
    TitleSpan,
    ContentUl,
    LinkSpan,
    LinearGradient1,
    LinearGradient2,
    LinearGradient3,
    LinearGradient4,
    LinearGradient5,
    LinearGradient6,
    PolicyWrapper,
} from './styled'
import { Container } from '../../components/common'

const Terms = () => {
    return (
        <Container>
            <PolicyWrapper>
                <LinearGradient1 />
                <LinearGradient2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/teamImg/image%2020.png"
                    alt="linearImg"
                />
                <LinearGradient3 />
                <LinearGradient4 />
                <LinearGradient5 />
                <LinearGradient6 />
                <Wrapper>
                    <Header>Terms of Use</Header>
                    <Content>
                        <ContentSpan>
                            <b>Effective Date:</b> January, 2024
                        </ContentSpan>
                        <ContentSpan>
                            <b>Last Updated:</b> January, 2024
                        </ContentSpan>

                        <ContentSpan>
                            Welcome to Obinex™! Our terms of use apply to all
                            visitors of and all visits to our website and
                            constitute an agreement between you and Obinex™
                            (“the agreement)”. Additionally, our website
                            policies apply. By accessing, utilizing, or engaging
                            with our website and its features in any manner, you
                            expressly acknowledge that you have thoroughly read,
                            comprehended, and agreed to be bound by the terms
                            and conditions contained within the Agreement. If
                            you do not agree with any part of these terms,
                            please refrain from using our website. Your
                            continued use of our website constitutes ongoing
                            acceptance of these terms. In certain circumstances,
                            additional or alternative terms may apply, such
                            specific terms will be duly communicated as
                            relevant.
                        </ContentSpan>

                        <TitleSpan>1.About Obinex ™</TitleSpan>

                        <ContentSpan>
                            The information and materials provided in our
                            website is solely for informational purposes.
                            Information and materials contained or described
                            should neither be considered investment, legal, tax
                            or other advice nor a recommendation in relation to
                            holding, purchasing or selling securities or digital
                            assets of any kind, and do not take into account the
                            users’ particular investment objectives, financial
                            situation or other needs. No investment or other
                            decisions should be made based on the information
                            and materials contained on this website. While we
                            present this information in good faith with the
                            intent of it being compliant with current applicable
                            laws, it should not be considered as investment
                            advice or a recommendation for any specific action.
                            Obinex™ is the trade name for EMPERSTUS GLOBAL PVT
                            LIMITED, a private limited company incorporated and
                            registered in the United Kingdom. The company
                            registration number is 15449808. The registered
                            office of the company is situated at: 45 Kent House,
                            Maidstone, United Kingdom, ME15 6LA.
                        </ContentSpan>

                        <TitleSpan>2. Access and Restrictions</TitleSpan>

                        <ContentSpan>
                            Obinex™ reserves the right to limit the availability
                            of its sites and services thereof, to certain
                            geographical locations. The use of our Services may
                            be prohibited or restricted in certain jurisdictions
                            ("Restricted Locations"). It is your responsibility
                            to ensure that your access to and use of Obinex’s
                            Services is in compliance with laws applicable in
                            your jurisdiction. Access to our website and
                            associated services may be subject to specific legal
                            requirements, such as age restrictions or other
                            criteria. You agree to comply with all such
                            applicable legal requirements and acknowledge that
                            access to our Services may be limited based on these
                            requirements. Obinex™ reserves the right to impose
                            additional eligibility criteria for the access or
                            continued access to certain services or features
                            within these services. You may be required to
                            provide ongoing evidence of your compliance with
                            these criteria. Obinex™ reserves the right to modify
                            these criteria or impose new ones as necessary to
                            comply with legal requirements or to meet other
                            operational needs.
                        </ContentSpan>

                        <LinkSpan>Links to other third-party Websites</LinkSpan>

                        <ContentSpan>
                            Our website may contain links to third-party
                            websites or services that are not owned or
                            controlled by Obinex™, such as links to news
                            stories, court websites, or other third parties’
                            websites and/or platforms. Obinex™ has no control
                            over, and assumes no responsibility for the content,
                            accuracy, privacy policies, or practices of any
                            third-party websites or services so linked. Obinex™
                            strongly urges you to read the terms and conditions
                            and privacy policies of any third-party website or
                            services you visit.
                        </ContentSpan>

                        <TitleSpan>3.Your obligations and commitment</TitleSpan>

                        <ContentSpan>
                            By accessing our website and using its services, you
                            acknowledge and agree to the following:
                        </ContentSpan>

                        <ContentUl>
                            <li>
                                You must be of legal age to form binding
                                contracts (at least 18 years old in the UK or
                                the equivalent age in your jurisdiction).
                            </li>
                            <li>
                                If you're registering for a legal entity, you
                                confirm the entity's legal establishment and
                                your authorization to legally bind it.
                            </li>
                            <li>
                                You have not been previously suspended or
                                removed from our Sites.
                            </li>
                            <li>
                                You do not reside in, nor have significant
                                connections to, any jurisdictions or countries
                                restricted or embargoed by the UK.
                            </li>
                            <li>
                                You agree to the collection and use of your
                                personal data and cookies in accordance with our
                                Privacy Policy. 
                            </li>
                        </ContentUl>

                        <ContentSpan>
                            By accessing our website and/or using our services,
                            you agree not to:
                        </ContentSpan>

                        <ContentUl>
                            <li>
                                Act contrary to local, state, federal, or
                                international laws.
                            </li>
                            <li>
                                Use the information provided for commercial
                                purpose without our prior written consent.
                            </li>
                            <li>
                                Copy, modify, transmit, create any derivative
                                works from, make use of, or reproduce in any way
                                any copyrighted materials, images, trademarks,
                                trade names, service marks, or other
                                intellectual property, content or proprietary
                                information accessible through the Sites without
                                our prior written consent.
                            </li>
                            <li>
                                Express or imply that any statements you make
                                are endorsed by Obinex™. 
                            </li>
                            <li>
                                Act in any manner that could interfere, disrupt
                                or negatively affect OBINEX’ network 
                            </li>
                            <li>
                                Upload viruses or other malicious code or
                                otherwise compromise the security of the Sites.
                            </li>
                            <li>
                                Forge headers or manipulate identifiers to
                                disguise the origin of any information
                                transmitted to or through the Sites.
                            </li>
                            <li>
                                “Frame” or “mirror” any part of the Sites
                                without our prior written consent.
                            </li>
                            <li>
                                Use meta tags or code or other devices
                                containing any reference to Obinex™ (or any
                                trademark, trade name, service mark, logo or
                                slogan of Obinex™) to direct any person to any
                                other website for any purpose.
                            </li>
                            <li>
                                Modify, adapt, sublicense, translate, sell,
                                reverse engineer, decipher, decompile or
                                otherwise disassemble any portion of the Sites,
                                or cause others to do so.
                            </li>
                            <li>
                                Use or develop any third-party application that
                                interacts with the Sites without our prior
                                written consent.
                            </li>
                            <li>
                                Probe, scan, or test the vulnerability of our
                                Sites or any system or network.
                            </li>
                            <li>
                                Encourage or promote any activity that violates
                                the Agreement.
                            </li>
                        </ContentUl>

                        <ContentSpan>
                            These provisions are without prejudice to any other
                            dispositions established in the agreement, or
                            applicable law. OBINEX™ reserves the right to
                            investigate and take any available action in
                            response to any unauthorized use of the Sites. This
                            includes, but is not limited to, termination of your
                            account and the initiation of legal proceedings.
                        </ContentSpan>

                        <TitleSpan>4.Intellectual Property Rights</TitleSpan>

                        <ContentSpan>
                            By using our Sites, you acknowledge that all
                            materials presented on the Sites, including but not
                            limited to, designs, text, graphics, sounds, images,
                            software, and the overall "look and feel" – as well
                            as their selection and arrangement (collectively
                            referred to as “Materials”), are the exclusive
                            property of Obinex™. These Materials are protected
                            by copyright and other intellectual property laws,
                            both inside and outside the UK.  Trademarks, service
                            marks, logos, trade dress, and trade names displayed
                            on the Sites, including but not limited to those
                            registered (collectively, “Marks”), are the sole and
                            exclusive property of Obinex™. This extends to all
                            page headers, custom graphics, and custom icons,
                            which are also recognized as Marks of Obinex™.  All
                            rights not explicitly granted in these Terms of Use
                            are reserved by Obinex™. Any use of the Materials
                            and Marks, except as expressly authorized herein, is
                            strictly prohibited and may violate copyright,
                            trademark, and other intellectual property laws. 
                            Any other copyrights, trademarks, product names,
                            company names, logos, and intellectual property
                            featured on the Sites are the property of their
                            respective owners, with all rights reserved.
                            References to any third parties or their
                            intellectual property on our Sites do not imply any
                            affiliation, endorsement, or recommendation of
                            Obinex™ by such parties, nor do they imply an
                            endorsement or recommendation of these third parties
                            by Obinex™.
                        </ContentSpan>

                        <TitleSpan>
                            5.Disclaimer of Warranties and Limitation of
                            Liability
                        </TitleSpan>

                        <ContentSpan>
                            The information should be used for general guidance
                            and is not a substitute for professional advice.
                            Users should verify all information independently,
                            seek their own professional advice, and exercise due
                            diligence in making financial decisions. Obinex™ is
                            not accountable for the consequences of any actions
                            taken based on the information provided on its
                            platform or any associated communications. Subject
                            to the applicable law, Obinex™ makes the following
                            disclaimers of warranties:
                        </ContentSpan>

                        <ContentSpan>
                            Obinex™ disclaims any and all responsibility or
                            liability for the accuracy, content, completeness,
                            legality, reliability, or operability or
                            availability of information or material displayed in
                            results provided BY THE WEBSITE.
                        </ContentSpan>

                        <ContentSpan>
                            Obinex™ disclaims any responsibility or liability
                            for the deletion, failure to store, misdelivery, or
                            untimely delivery of any information or material
                            made available.
                        </ContentSpan>

                        <ContentSpan>
                            Obinex™ disclaims any responsibility or liability
                            for any harm resulting from downloading or accessing
                            information or material on the Internet through the
                            Sites. Obinex™ provides the Sites on an “as is” and
                            “as available” basis with no warranties whatsoever.
                            Obinex expressly disclaims to the fullest extent
                            permitted by law all express, implied, and statutory
                            warranties, including, without limitation, the
                            warranty of merchantability, fitness for a
                            particular purpose, and non-infringement of
                            proprietary rights. Obinex™ further disclaims any
                            warranties regarding the security, reliability,
                            timeliness, and performance of the Sites. Obinex™
                            further disclaims any warranties relating to any
                            information obtained through the Sites, any links
                            provided by the Sites, as well as any information
                            received through any of the links provided in the
                            Sites.
                        </ContentSpan>

                        <ContentSpan>
                            Obinex™ shall not be liable for any damages, direct
                            or indirect, arising from the use of its sites,
                            including but not limited to data loss, lost
                            profits, or business interruption. This applies to
                            damages resulting from the use of or inability to
                            use Obinex's services, as well as information
                            obtained through the sites or external links.
                        </ContentSpan>

                        <ContentSpan>
                            Any claims for damages will be limited to the
                            maximum extent permitted by law, with total
                            liability capped at one hundred dollars ($100),
                            regardless of the cause of action, whether in
                            contract, tort, or otherwise.
                        </ContentSpan>

                        <TitleSpan>6.Representation and Risk waver</TitleSpan>
                        <ContentSpan>
                            Obinex™ makes no representation regarding the
                            accuracy, completeness, or timeliness of the
                            information provided, which is subject to change at
                            any time without prior notice. Any reliance on the
                            material provided is at the sole risk of the user.
                            Before making any investment decisions, users should
                            seek independent professional advice to consider
                            their specific investment objectives, financial
                            situation, and particular needs. Obinex™ assumes no
                            liability for actions taken by any third party based
                            on the information provided. By accessing and using
                            the information on Obinex's website, users agree
                            that they do so at their own discretion and risk and
                            that it is their responsibility to ensure that any
                            investment or other decisions are made with due
                            consideration.
                        </ContentSpan>
                        <TitleSpan>7.Indemnification</TitleSpan>
                        <ContentSpan>
                            You agree to defend, indemnify, and hold harmless
                            our website, its owners, affiliates, subsidiaries,
                            directors, officers, employees, agents, partners,
                            and licensors (collectively, the 'Indemnified
                            Parties') from and against any and all claims,
                            actions, demands, liabilities, losses, damages,
                            costs, and expenses, including, but not limited to
                            legal fees, arising out of or related to your use of
                            this website, your violation of these Terms of Use,
                            or your violation of any rights of a third party.
                            This indemnification will survive the termination or
                            expiration of these Terms of Use and your use of the
                            website. You also specifically agree to indemnify
                            for any loss, damages, or costs, including legal
                            fees, resulting from your use of software robots,
                            spiders, crawlers, or similar data gathering and
                            extraction tools, or any other action you take that
                            imposes an unreasonable burden or load on our
                            infrastructure. Furthermore, you agree to indemnify
                            the Indemnified Parties against any claims or
                            damages resulting from content you submit, post,
                            transmit, or make available through this website,
                            your unauthorized use of the material obtained from
                            the website, or any violation by you of our Privacy
                            Policy.  This indemnification clause shall cover all
                            liabilities, including, but not limited to
                            negligence, wrongful conduct, and breach of
                            contract, on your part. It shall also extend to any
                            action brought against the Indemnified Parties
                            arising out of your illegal or harmful activities or
                            contributions to the website. We reserve the right,
                            at your expense, to assume the exclusive defense and
                            control of any matter otherwise subject to
                            indemnification by you, and in such case, you agree
                            to cooperate with our defense of such claim.
                        </ContentSpan>

                        <TitleSpan>8.Force Majeur</TitleSpan>

                        <ContentSpan>
                            Obinex™ shall also not be liable for any delays or
                            failures in performance resulting from events or
                            circumstances beyond its reasonable control. This
                            includes, but is not limited to, natural disasters,
                            technological failures including Internet, computer,
                            telecommunications, or other equipment malfunctions,
                            power outages, labor disputes, civil unrest,
                            shortages of labor or materials, unforeseen
                            catastrophic events, war, including cyber-attacks
                            and events attributed to state or quasi-state
                            actors, governmental actions, judicial orders, or
                            third-party non-performance. This also extends to
                            environmental conditions affecting heat, light, or
                            air conditioning systems. In the event of such
                            unforeseen catastrophic events. Obinex's obligations
                            under the agreement will be suspended for the
                            duration of the event and will resume as soon as
                            reasonably possible after the event has ceased.
                        </ContentSpan>

                        <TitleSpan>
                            9. Dispute Resolution and Governing Law
                        </TitleSpan>

                        <ContentSpan>
                            Our terms of use are governed and construed in
                            accordance with the laws of OBINEX™ domicile,
                            located in London, England, UK. Any disputes arising
                            under or in connection with the Agreement shall be
                            resolved applying the British law and specifically
                            by the competent London Courts and Tribunals.
                        </ContentSpan>

                        <TitleSpan>
                            10. Questions, complaints and adjustments to
                            cookie's settings
                        </TitleSpan>

                        <ContentSpan>
                            If you have any questions now or during your visit,
                            please contact us through the general information
                            email (LINK TO GENERAL EMAIL HERE). If you have
                            doubts, concerns or complaints related to data
                            protection, please refer to the specific section of
                            the legal notice here (LINK TO DATA PROTECTION
                            HERE). You can also submit your request or
                            complaints through our Data Protection Officer
                            &nbsp;
                            <LinkSpan
                                href="http://www.dpo@obinex.com"
                                target="_blank">
                                (dpo@obinex.com)
                            </LinkSpan>
                        </ContentSpan>
                        <ContentSpan>
                            You can adjust your cookie's settings at any time
                            from our cookie setting center.
                        </ContentSpan>

                        <TitleSpan>11.Privacy and cookies policiy</TitleSpan>

                        <ContentSpan>
                            By agreeing to the Agreement, you also confirm that
                            you have read and understand our Privacy Policy,
                            available here, and cookies policy.
                        </ContentSpan>

                        <TitleSpan>12.Changes to the terms</TitleSpan>

                        <ContentSpan>
                            Our website and these terms of use are subject to
                            change at any time, and without notice – unless
                            specifically stated otherwise, and any change
                            supersedes all the previous versions/s. Access to
                            and use of the website may be restricted or
                            terminated. Continued access to this website
                            following any modification in these Terms of Use
                            will constitute your acceptance of the Terms of Use
                            as modified.
                        </ContentSpan>

                        <TitleSpan>13. Contact Us</TitleSpan>

                        <ContentSpan>
                            Should you have any questions about these terms of
                            use, please contact us of use, please contact us at
                            &nbsp;
                            <LinkSpan
                                href="http://www.support@obinex.com"
                                target="_blank">
                                support@obinex.com
                            </LinkSpan>
                        </ContentSpan>
                        <br />
                        <TitleSpan>
                            EMPERSTUS GLOBAL PVT. LIMITED (register number
                            15449808),
                            <br /> 45 Kent House, Maidstone, United Kingdom,
                            ME15 6LA.
                        </TitleSpan>
                    </Content>
                </Wrapper>
            </PolicyWrapper>
        </Container>
    )
}

export default Terms
