import React from 'react'

import { executiveTeamImages, restTeamImages } from '../../constants/variables'
import PeopleCard from '../../components/Team/PeopleCard'
import {
    TeamWrapper,
    TeamHeader,
    TeamImageWrapper,
    // ExecutiveTeam,
    // RestTeam,
    LinearGradient1,
    LinearGradient2,
    LinearGradient3,
    LinearGradient4,
    LinearGradient5,
    LinearGradient6,
    CloudEffect1,
    CloudEffect2,
    CloudEffect3,
    CloudEffect4,
    CloudEffect5,
    StarEffectImg1,
    StarEffectImg2,
    StarEffectImg3,
    StarEffectImg4,
    StarEffectImg5,
    // StarEffectImg6,
    // StarEffectImg7,
} from './styled'
import { Container } from '../../components/common'

const Team = () => {
    return (
        <Container>
            <TeamWrapper>
                <LinearGradient1 />
                <LinearGradient2 />
                <LinearGradient3 />
                <LinearGradient4 />
                <LinearGradient5 />
                <LinearGradient6 />
                <CloudEffect1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="CloudImg"
                />
                <CloudEffect2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="CloudImg"
                />
                <CloudEffect3
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="CloudImg"
                />
                <CloudEffect4
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="CloudImg"
                />
                <CloudEffect5
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/cloud1.png"
                    alt="CloudImg"
                />
                <StarEffectImg1
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star2.png"
                    alt="starImg"
                />
                <StarEffectImg2
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star2.png"
                    alt="starImg"
                />
                <StarEffectImg3
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="starImg"
                />
                <StarEffectImg4
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star2.png"
                    alt="starImg"
                />
                <StarEffectImg5
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="starImg"
                />
                {/* <StarEffectImg6
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="starImg"
                /> */}
                {/* <StarEffectImg7
                    src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/effect/star1.png"
                    alt="starImg"
                /> */}

                <TeamHeader>The Team Behind Obinex</TeamHeader>
                <TeamImageWrapper>
                    {[...executiveTeamImages, ...restTeamImages].map(
                        (image) => (
                            <PeopleCard key={image.name} image={image} />
                        )
                    )}
                </TeamImageWrapper>
            </TeamWrapper>
        </Container>
    )
}

export default Team
