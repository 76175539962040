import styled from 'styled-components'

export const NewsletterWrapper = styled.div`
    position: relative;
    display: flex;
    padding-left: 44px;
    padding-right: 44px;
    justify-content: flex-end;
    padding-bottom: 52px;
    column-gap: 71px;

    @media (max-width: 1166px) {
        padding-left: 30px;
        padding-right: 30px;
        justify-content: space-between;
        column-gap: unset;
    }

    @media (max-width: 1024px) {
        width: 100%;
        max-width: 468px;
        padding-left: unset;
        padding-right: unset;
        margin: auto;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 576px) {
        max-width: 333px;
    }
    @media (max-width: 350px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`
export const Newsletters = styled.div`
    max-width: 464px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const StarEffect1 = styled.img`
    position: absolute;
    left: -60px;
    top: 72px;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const StarEffect2 = styled.img`
    position: absolute;
    bottom: 40px;
    right: 390px;
    @media (max-width: 1024px) {
        width: 29px;
        top: 43%;
        right: 90px;
        bottom: unset;
    }
    @media (max-width: 576px) {
        width: 29px;
        top: 35%;
        bottom: unset;
        right: 20px;
    }
`

export const SubscribeWrapper = styled.div`
    text-align: center;
    width: 100%;
`
export const NewsletterHeader = styled.h2`
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 28px;
    background: linear-gradient(90deg, #ff61f9 0.07%, #ff00f5 86.08%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 28px;
        text-align: center;
        margin-top: 38px;
        margin-bottom: 24px;
    }
    @media (max-width: 576px) {
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }
`
export const StyledInput = styled.input`
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 16px;
    border-radius: 30px;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.25);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &::placeholder {
        width: 80%;
        height: 40px;
    }
    @media (max-width: 431px) {
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 14px;
    }
`
export const SubscribeButton = styled.button`
    width: 385px;
    height: 40px;
    border-radius: 80px;
    border: 1px solid #fff;
    background: linear-gradient(99deg, #e587e7 -8.37%, #5c6cf6 77.41%);
    backdrop-filter: blur(2px);
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */

    @media (max-width: 576px) {
        width: 85%;
        height: 29px;
    }
`
export const SubscribeCheckbox = styled.div`
    margin-top: 7px;
    display: flex;
    justify-content: center;

    @media (max-width: 576px) {
        width: 85%;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
    }
`
export const CheckboxWrapper = styled.span`
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.25);
`
export const PrivacyPolicyText = styled.p`
    color: #4388dd;
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    text-decoration-line: underline;

    @media (max-width: 576px) {
        display: inline;
    }

    @media (max-width: 430px) {
        font-size: 11px;
    }
`
export const EmailImg = styled.div`
    width: 100%;
    max-width: 543px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;

    & img {
        width: 100%;
    }

    @media (max-width: 1024px) {
        max-width: 422px;
        width: 100%;
    }
`
