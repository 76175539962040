import styled from 'styled-components'

export const PartnerHeader = styled.h1`
    color: var(--titlesdd, #415162);
    text-align: center;
    font-family: Inter;
    font-size: 43px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin-top: 51px;
    margin-bottom: 51px;

    @media (max-width: 430px) {
        color: var(--titles, #0f172a);
        font-family: Gotham;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 22px */
        margin-bottom: 40px;
    }
`
export const PartnerItemWarpper = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: 35px;
    margin-bottom: 282px;

    @media (max-width: 430px) {
        margin-bottom: 92px;
    }

    div:nth-child(1) > img {
        width: auto;
        height: 139px;

        @media (max-width: 430px) {
            height: 109px;
        }
    }
    div:nth-child(2) > img {
        width: auto;
        height: 94px;

        @media (max-width: 430px) {
            height: 78px;
        }
    }
    div:nth-child(3) > img {
        width: auto;
        height: 76px;

        @media (max-width: 500px) {
            max-width: 100%;
            height: auto;
        }

        @media (max-width: 430px) {
            height: 63px;
        }

        @media (max-width: 380px) {
            height: auto;
        }
    }
    div:nth-child(4) > img {
        width: auto;
        height: 71px;

        @media (max-width: 430px) {
            height: 57px;
        }

        @media (max-width: 380px) {
            max-width: 100%;
            height: auto;
        }
    }

    div:nth-child(3) {
        padding-bottom: 71px;

        @media (max-width: 950px) {
            padding-bottom: 35px;
        }
    }

    div:nth-child(4) {
        padding-bottom: 71px;

        @media (max-width: 950px) {
            padding-bottom: 35px;
        }
    }

    div:nth-child(4) > p {
        margin-right: -1px;
    }
`
export const PartnerItem = styled.div`
    width: calc((100% - 70px) / 2);
    flex-shrink: 0;
    margin-right: 35px;
    text-align: center;
    padding: 35px;
    border-radius: 40px;
    border: 0.7px solid rgba(255, 255, 255, 0.4);
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(12.5px);

    @media (max-width: 950px) {
        width: 100%;
        margin-right: unset;
    }

    @media (max-width: 430px) {
        padding: 35px 23.5px;
        width: calc(100% - 53px);
        margin: auto;
    }
`
export const PartnerLogo = styled.img`
    margin-bottom: 31px;
`
export const PartnerContent = styled.p`
    text-align: left;
    color: var(--TITLE, #2f2f30);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin: unset;

    @media (max-width: 430px) {
        text-align: center;
    }
`
