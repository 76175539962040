import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ItemCenteredColum, FooterLogo, Container } from '../common'
import SocialLinks from './SocialLinks'
import FooterLinks from '../Footer/FooterLinks'
import constants from '../../constants/layout.json'
import {
    FooterDiv,
    LogoSpan,
    SocialSpan,
    LogoWrapper,
    FooterLogoLetter,
    FooterLinksHeader,
    FooterLinkWrapper,
    FooterLinksWrapper,
    FooterLine,
} from './styled'
const Footer = () => {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <ItemCenteredColum>
            <Container>
                <FooterLine
                    background={
                        location.pathname === '/litepaper'
                            ? '#B0B0B0'
                            : '#ffffff'
                    }
                />
                <FooterDiv>
                    <LogoWrapper>
                        <LogoSpan onClick={() => navigate('/')}>
                            <FooterLogo src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/b78731b4f1a5c7539fb4ab635beb0c2d161fb702/componentImg/footerlogo/logo.svg" />
                            <FooterLogoLetter src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/footerlogo/logoLetter.png" />
                        </LogoSpan>
                        <SocialSpan>
                            <SocialLinks />
                            <span>Join the future of finance</span>
                        </SocialSpan>
                    </LogoWrapper>
                    <FooterLinkWrapper>
                        <FooterLinksWrapper>
                            <FooterLinksHeader>Company</FooterLinksHeader>
                            <FooterLinks links={constants.footerLinks1} />
                        </FooterLinksWrapper>

                        <FooterLinksWrapper>
                            <FooterLinksHeader>Legal</FooterLinksHeader>
                            <FooterLinks links={constants.footerLinks2} />
                        </FooterLinksWrapper>
                    </FooterLinkWrapper>
                </FooterDiv>
            </Container>
        </ItemCenteredColum>
    )
}
export default Footer
