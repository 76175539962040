import { Container } from '../../components/common'

import {
    Wrapper,
    Header,
    Content,
    ContentSpan,
    TitleSpan,
    LinearGradient1,
    LinearGradient2,
    LinearGradient3,
} from './styled'
const Disclaimer = () => {
    return (
        <Container>
            <LinearGradient1 />
            <LinearGradient2
                src="https://raw.githubusercontent.com/Obinex-Ecosystem/ObinexImages/main/componentImg/teamImg/image%2020.png"
                alt="linearImg"
            />
            <LinearGradient3 />

            <Wrapper>
                <Header>Disclaimer</Header>
                <Content>
                    <ContentSpan>
                        EMPERSTUS GLOBAL PVT. LIMITED, along with its affiliates
                        ("the Company"), provides this presentation solely for
                        informational purposes. This document is neither a
                        formal offer to sell nor a solicitation to buy any
                        securities, options, futures, or other derivatives in
                        any jurisdiction, including digital assets like
                        cryptocurrencies, which are subject to several risks
                        including but not limited to high volatility and
                        regulatory uncertainty. As far as the Company knows, the
                        contents of this presentation comply with securities
                        laws and are not intended as investment recommendations.
                        You should bear in mind also that regulations that may
                        apply vary promptly and constantly in many Countries.
                        Please keep yourself duly informed and take your own
                        decisions responsibly. 
                    </ContentSpan>
                    <ContentSpan>
                        Additionally, the information herein does not constitute
                        tax, legal, or investment advice tailored to any
                        specific investment goals or financial situations. While
                        we believe the information to be accurate and reliable,
                        no explicit or implicit guarantee of its accuracy is
                        provided. OBINEX, including its agents, advisors,
                        directors, officers, employees, and shareholders, will
                        not be held liable for any inaccuracies or missing
                        information in this presentation, nor for any losses
                        incurred from NFT, digital tokens or cryptocurrency
                        investments.
                    </ContentSpan>
                    <ContentSpan>
                        The Company reserves the right to amend the information
                        in this document, either wholly or partially, at any
                        time, without the obligation to update or inform the
                        recipients of such changes. This presentation is
                        designed for individuals evaluating the Company and
                        supersedes any prior presentation or conversation
                        regarding the Company. Any information, representation,
                        or statement not contained in this document should not
                        be relied upon for any purpose.
                    </ContentSpan>
                    <ContentSpan>
                        Neither the Company nor its representatives will bear
                        any liability arising from the use of this presentation
                        or the omission of any information herein. Moreover, the
                        Company is not obliged to address any third-parties
                        expectations or statements concerning the subjects
                        discussed.
                    </ContentSpan>
                    <br />
                    <TitleSpan>
                        EMPERSTUS GLOBAL PVT. LIMITED (register number
                        15449808),
                        <br /> 45 Kent House, Maidstone, United Kingdom, ME15
                        6LA.
                    </TitleSpan>
                </Content>
            </Wrapper>
        </Container>
    )
}

export default Disclaimer
